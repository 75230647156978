import React, { FC, useEffect, useState } from "react"
import styles from "../../resources/styles/Th.module.css"
import { IDevice } from "../../models/AdminPanel"
import { Alert, Card, Button, Typography, Chip } from "@material-tailwind/react"
import API from "../../API/API"
import { IUserInfo } from "../../models/Response"
import { DBToDateText } from "../../utils/DBToDateText"
import moment from "moment"
import { WifiIcon, ArrowPathIcon, UserCircleIcon, DevicePhoneMobileIcon, ShieldCheckIcon } from "@heroicons/react/24/solid"
import toast from "react-hot-toast"

interface Props {
    devices: IDevice[],
    onClickDevice: (seri_no: string) => void
}

const UserHomePage: FC<Props> = (props) => {
    const { devices } = props
    const [width, setWidth] = useState(350);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [userInfo, setUserInfo] = useState<IUserInfo>();
    const [localDevices, setLocalDevices] = useState<IDevice[]>(devices);

    const calculateWidth = () => {
        const widthList = []
        for (let index = 0; index < localDevices.length; index++) {
            const itemWidth = document.getElementById("table" + index)?.offsetWidth;
            widthList.push(itemWidth ? itemWidth : 0);
        }
        return Math.max(...widthList);
    };

    useEffect(() => {
        if (localDevices) {
            setTimeout(() => {
                setWidth(calculateWidth())
            }, 200);
        }
    }, [localDevices])

    const refreshData = async () => {
        setIsRefreshing(true);
        try {
            // Kullanıcı bilgilerini güncelle
            const userResponse = await API.getInstance().GetUserInfo();
            setUserInfo(userResponse.data.data);

            // Cihaz bilgilerini güncelle
            const deviceResponse = await API.getInstance().GetUserDevices();
            setLocalDevices(deviceResponse.data.data);

            toast.success("Bilgiler başarıyla güncellendi");
        } catch (error) {
            toast.error("Bilgiler güncellenirken bir hata oluştu");
        } finally {
            setIsRefreshing(false);
        }
    };

    useEffect(() => {
        refreshData();
    }, []);

    useEffect(() => {
        setLocalDevices(devices);
    }, [devices]);

    return (
        <div className="min-h-screen bg-gray-50">
            <div className="container mx-auto px-4 py-8">
                {/* Header Section */}
                <div className="flex flex-col md:flex-row justify-between items-center mb-8">
                    <div className="flex items-center gap-3 mb-4 md:mb-0">
                        <UserCircleIcon className="h-8 w-8 text-blue-500" />
                        <Typography 
                            variant="h3" 
                            className="text-blue-gray-800"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            Kontrol Paneli
                        </Typography>
                    </div>
                    <Button
                        placeholder=""
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                        className="flex items-center gap-2 px-4 py-2"
                        onClick={refreshData}
                        disabled={isRefreshing}
                        color="blue"
                        size="sm"
                    >
                        <ArrowPathIcon className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
                        {isRefreshing ? 'Yenileniyor...' : 'Yenile'}
                    </Button>
                </div>

                {/* User Info Section */}
                {userInfo && (
                    <div className="mb-10">
                        <div className="flex items-center gap-2 mb-6">
                            <div className="flex-1 h-px bg-blue-gray-100"></div>
                            <Typography 
                                variant="h4" 
                                color="blue-gray" 
                                className="font-medium flex items-center gap-2"
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                            >
                                <UserCircleIcon className="h-6 w-6" />
                                Kullanıcı Bilgileri
                            </Typography>
                            <div className="flex-1 h-px bg-blue-gray-100"></div>
                        </div>
                        <Card 
                            className="overflow-hidden bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            <div className="p-6">
                                <div className="flex flex-wrap gap-8 items-center justify-center">
                                    {/* Email Info */}
                                    <div className="flex items-center gap-4 bg-blue-50 rounded-lg p-4 min-w-[280px]">
                                        <div className="p-3 bg-blue-500 rounded-lg">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                            </svg>
                                        </div>
                                        <div>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                E-Posta Adresi
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                color="blue-gray"
                                                className="font-semibold"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                {userInfo.email}
                                            </Typography>
                                        </div>
                                    </div>

                                    {/* Role Info */}
                                    <div className="flex items-center gap-4 bg-green-50 rounded-lg p-4 min-w-[280px]">
                                        <div className="p-3 bg-green-500 rounded-lg">
                                            <ShieldCheckIcon className="h-6 w-6 text-white" />
                                        </div>
                                        <div>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Kullanıcı Rolü
                                            </Typography>
                                            <Chip
                                                value={userInfo.role}
                                                color="green"
                                                variant="ghost"
                                                size="sm"
                                                className="mt-1"
                                            />
                                        </div>
                                    </div>

                                    {/* Expiry Date Info */}
                                    <div className="flex items-center gap-4 bg-orange-50 rounded-lg p-4 min-w-[280px]">
                                        <div className="p-3 bg-orange-500 rounded-lg">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <div>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Üyelik Bitiş Tarihi
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                color="blue-gray"
                                                className="font-semibold"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                {DBToDateText(userInfo.expired_date ? userInfo.expired_date : "")}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                )}

                {/* Devices Section */}
                <div className="mb-8">
                    <div className="flex items-center gap-2 mb-6">
                        <div className="flex-1 h-px bg-blue-gray-100"></div>
                        <Typography 
                            variant="h4" 
                            color="blue-gray" 
                            className="font-medium flex items-center gap-2"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            <DevicePhoneMobileIcon className="h-6 w-6" />
                    Cihazlarım
                        </Typography>
                        <div className="flex-1 h-px bg-blue-gray-100"></div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {localDevices.map((item, index) => {
                    const status = moment(item.updated_date).isAfter(moment().subtract(item.veri_gonderme_suresi + 600, "second"))
                    return (
                                <Card
                                    key={index}
                            onClick={() => props.onClickDevice(item.device_seri_no)}
                                    className="overflow-hidden bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 cursor-pointer transform hover:-translate-y-1"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <div className="p-6">
                                        <div className="flex flex-col gap-4">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap-2">
                                                    <WifiIcon className={`h-5 w-5 ${status ? 'text-green-500' : 'text-red-500'}`} />
                                                    <span className={`text-sm font-medium ${status ? 'text-green-500' : 'text-red-500'}`}>
                                                        {status ? 'Aktif' : 'Pasif'}
                                                    </span>
                                                </div>
                                                <div className="text-xs text-blue-gray-500 font-medium">
                                                    Son Veri: {moment(item.updated_date).format('DD.MM.YYYY HH:mm:ss')}
                                                </div>
                                            </div>
                                            
                                            <div className="space-y-3 pt-2 border-t border-blue-gray-50">
                                                <div className="flex justify-between items-center">
                                                    <span className="text-sm font-medium text-blue-gray-600">Seri No</span>
                                                    <span className="text-sm font-medium text-blue-gray-900">{item.device_seri_no}</span>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <span className="text-sm font-medium text-blue-gray-600">Cihaz İsmi</span>
                                                    <span className="text-sm font-medium text-blue-gray-900">{item.device_name}</span>
                                                </div>
                                                <div className="flex justify-between items-center">
                                                    <span className="text-sm font-medium text-blue-gray-600">Veri Gönderme Süresi</span>
                                                    <span className="text-sm font-medium text-blue-gray-900">{item.veri_gonderme_suresi} sn</span>
                                                </div>
                                            </div>
                                        </div>
                        </div>
                                </Card>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserHomePage;