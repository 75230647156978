import { Button, Input, Select, Option, Typography, Progress } from "@material-tailwind/react"
import React, { FC, useRef, useState } from "react"
import { IDevice, IDeviceError, IUsers } from "../../../../models/AdminPanel";
import API from "../../../../API/API";
import toast from "react-hot-toast";
import { EmailControl } from "../../../../utils/EmailControl";
import moment from "moment";
import { RandomPasswordGenerate } from "../../../../utils/RandomPasswordGenerate";
import { DBToInputText } from "../../../../utils/DBToInputText ";
import { IRole } from "../../../../models/User";

interface Props {
    selectedUser: IUsers
    onUpdated: () => void
    onCancel: () => void
}

const UserUpdateModal: FC<any> = (props: Props) => {
    const [selectedUserId, setSelectedUserId] = useState("")
    const [selectedUser, setSelectedUser] = useState<IUsers>(props.selectedUser)
    const [error, setError] = useState(
        { role: false, email: false, password: false, name: false, surname: false, expired_date: false }
    );

    const UserUpdate = () => {
        if (
            selectedUser?.password.trim() === "" ||
            selectedUser?.name.trim() === "" ||
            selectedUser?.surname.trim() === "" ||
            !EmailControl(selectedUser?.email) ||
            selectedUser?.role?.trim() === "" ||
            selectedUser?.expired_date.trim() === ""
        ) {
            setError(
                {
                    password: selectedUser?.password.trim() === "",
                    name: selectedUser?.name.trim() === "",
                    surname: selectedUser?.surname.trim() === "",
                    email: !EmailControl(selectedUser?.email),
                    role: selectedUser?.role?.trim() === "",
                    expired_date: selectedUser?.expired_date.trim() === "",
                })
        } else {
            let tempUser = selectedUser!;
            tempUser.expired_date = moment(tempUser.expired_date, "YYYY-MM-DD").format("YYYY-MM-DD") + " 23:59:59"
            API.getInstance().UpdateUser(tempUser)
                .then((response) => {
                    toast.success(response.data.message);
                    props.onUpdated();
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                })
        }
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full max-w-2xl mx-auto my-6 px-4">
                    <div className="bg-white rounded-2xl shadow-2xl relative flex flex-col w-full outline-none">
                        {/* Header */}
                        <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-8 rounded-t-2xl relative overflow-hidden">
                            <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-sm"></div>
                            <div className="relative">
                                <div className="w-16 h-16 bg-white/10 rounded-xl flex items-center justify-center mb-4">
                                    <i className="fas fa-user-edit text-white text-3xl"></i>
                                </div>
                                <Typography
                                    variant="h4"
                                    color="white"
                                    className="font-bold"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    Kullanıcı Güncelleme
                                </Typography>
                                <Typography
                                    variant="small"
                                    className="text-blue-100 mt-2"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    Kullanıcı bilgilerini güncellemek için formu doldurun
                                </Typography>
                            </div>
                        </div>

                        {/* Content */}
                        <div className="p-8">
                            <div className="space-y-6">
                                {/* Email */}
                                <div>
                                    <Input
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        type="email"
                                        error={error.email}
                                        value={selectedUser.email}
                                        crossOrigin="anonymous"
                                        onChange={(event) => {
                                            setSelectedUser({ ...selectedUser, email: event.target.value })
                                            setError({ ...error, email: !EmailControl(event.target.value) })
                                        }}
                                        label="E-posta"
                                        className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                        labelProps={{
                                            className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                        }}
                                        icon={<i className="fas fa-envelope text-blue-gray-500/80"></i>}
                                    />
                                    {error.email && (
                                        <Typography
                                            variant="small"
                                            color="red"
                                            className="flex items-center gap-1 mt-2"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            <i className="fas fa-exclamation-circle"></i>
                                            Lütfen geçerli bir e-posta adresi giriniz.
                                        </Typography>
                                    )}
                                </div>

                                {/* Password */}
                                <div className="flex gap-4">
                                    <div className="flex-1">
                                        <Input
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            error={error.password}
                                            value={selectedUser.password}
                                            crossOrigin="anonymous"
                                            onChange={(event) => {
                                                setSelectedUser({ ...selectedUser, password: event.target.value })
                                                setError({ ...error, password: event.target.value.trim() === "" })
                                            }}
                                            label="Şifre"
                                            className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            icon={<i className="fas fa-key text-blue-gray-500/80"></i>}
                                        />
                                    </div>
                                    <Button
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        onClick={() => {
                                            setSelectedUser({ ...selectedUser, password: RandomPasswordGenerate() })
                                            setError({ ...error, password: false })
                                        }}
                                        className="flex items-center gap-2 px-4"
                                        color="blue"
                                        variant="text"
                                    >
                                        <i className="fas fa-dice"></i>
                                        Üret
                                    </Button>
                                </div>

                                {/* Name */}
                                <div>
                                    <Input
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        error={error.name}
                                        crossOrigin="anonymous"
                                        value={selectedUser.name}
                                        onChange={(event) => {
                                            setSelectedUser({ ...selectedUser, name: event.target.value })
                                            setError({ ...error, name: event.target.value.trim() === "" })
                                        }}
                                        label="Ad"
                                        className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                        labelProps={{
                                            className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                        }}
                                        icon={<i className="fas fa-user text-blue-gray-500/80"></i>}
                                    />
                                </div>

                                {/* Surname */}
                                <div>
                                    <Input
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        error={error.surname}
                                        crossOrigin="anonymous"
                                        value={selectedUser.surname}
                                        onChange={(event) => {
                                            setSelectedUser({ ...selectedUser, surname: event.target.value })
                                            setError({ ...error, surname: event.target.value.trim() === "" })
                                        }}
                                        label="Soyad"
                                        className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                        labelProps={{
                                            className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                        }}
                                        icon={<i className="fas fa-user text-blue-gray-500/80"></i>}
                                    />
                                </div>

                                {/* Role */}
                                <div>
                                    <Select
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        error={error.role}
                                        value={selectedUser.role}
                                        onChange={(item) => {
                                            setSelectedUser({ ...selectedUser, role: item?.toString() as IRole })
                                            setError({ ...error, role: item?.toString().trim() === "" })
                                        }}
                                        label="Rol seçiniz"
                                        className="!border !border-blue-gray-100 rounded-lg"
                                        labelProps={{
                                            className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                        }}
                                        menuProps={{ className: "p-1" }}
                                    >
                                        <Option value="USER" className="p-2">USER</Option>
                                        <Option value="DEMO USER" className="p-2">DEMO USER</Option>
                                    </Select>
                                </div>

                                {/* Expiry Date */}
                                <div>
                                    <Input
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        type="date"
                                        crossOrigin="anonymous"
                                        error={error.expired_date}
                                        value={DBToInputText(selectedUser.expired_date)}
                                        onChange={(event) => {
                                            setSelectedUser({ ...selectedUser, expired_date: event.target.value })
                                            setError({ ...error, expired_date: event.target.value.trim() === "" })
                                        }}
                                        label="Üyelik Sona Erme Tarihi"
                                        min={moment().format("YYYY-MM-DD")}
                                        className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                        labelProps={{
                                            className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                        }}
                                        icon={<i className="fas fa-calendar text-blue-gray-500/80"></i>}
                                    />
                                </div>

                                {/* Buttons */}
                                <div className="flex justify-end gap-4 pt-4">
                                    <Button
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        variant="text"
                                        color="red"
                                        onClick={props.onCancel}
                                        className="flex items-center gap-2"
                                    >
                                        <i className="fas fa-times"></i>
                                        İptal
                                    </Button>
                                    <Button
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        color="green"
                                        onClick={UserUpdate}
                                        className="flex items-center gap-2 bg-gradient-to-r from-green-500 to-green-600"
                                    >
                                        <i className="fas fa-check"></i>
                                        Güncelle
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black/50 backdrop-blur-sm"></div>
        </>
    );
}

export default UserUpdateModal;