import { Button, Input, Select, Option, Typography, Progress } from "@material-tailwind/react"
import React, { FC, useRef, useState } from "react"
import { IDevice, IDeviceError } from "../../../../models/AdminPanel";
import API from "../../../../API/API";
import toast from "react-hot-toast";
import { RandomPasswordGenerate } from "../../../../utils/RandomPasswordGenerate";
import axios from "axios";
import { API_URL } from "../../../../common/AppSettings";
import { IPhotoUploadResponse } from "../../../../models/Response";
interface Props {
    selectedDevice: IDevice
    onUpdated: () => void
    onCancel: () => void
}
const DevicesUpdateModal: FC<any> = (props: Props) => {
    const [selectedDevice, setSelectedDevice] = useState<IDevice>(props.selectedDevice)
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const fileUploadRef = useRef<any>();
    const [progressVal, setProgressVal] = useState(100);
    const [file, setFile] = useState();
    const [error, setError] = useState<IDeviceError>({ gyro_esik: false, veri_gonderme_suresi: false, o_sayisi: false, device_seri_no: false, device_name: false, device_password: false, coefficient_x: false, coefficient_y: false, coefficient_z: false });
    const DeviceUpdate = () => {
        if (selectedDevice?.device_seri_no.trim() === "" ||
            selectedDevice?.device_name.trim() === "" ||
            selectedDevice?.device_password.trim() === "" ||
            (!selectedDevice?.coefficient_x_auto && selectedDevice?.coefficient_x === undefined) ||
            (!selectedDevice?.coefficient_y_auto && selectedDevice?.coefficient_y === undefined) ||
            (!selectedDevice?.coefficient_z_auto && selectedDevice?.coefficient_z === undefined)

        ) {
            setError({
                ...error,
                device_seri_no: selectedDevice?.device_seri_no.trim() === "",
                device_name: selectedDevice?.device_name.trim() === "",
                device_password: selectedDevice?.device_password.trim() === "",
                coefficient_x: (!selectedDevice?.coefficient_x_auto && selectedDevice?.coefficient_x === undefined),
                coefficient_y: (!selectedDevice?.coefficient_y_auto && selectedDevice?.coefficient_y === undefined),
                coefficient_z: (!selectedDevice?.coefficient_z_auto && selectedDevice?.coefficient_z === undefined)
            })
        }
        else {
            setError({ ...error, device_seri_no: false, device_name: false, device_password: false, coefficient_x: false, coefficient_y: false, coefficient_z: false })
            if (selectedDevice) {
                API.getInstance().UpdateDevice(selectedDevice)
                    .then((response) => {
                        toast.success(response.data.message);
                        props.onUpdated();
                    })
                    .catch((error) => {
                        toast.error(error.response.data.message);
                    })

            }
        }
    }
    const UpdateOrCreateDeviceFile = async () => {
        setButtonDisabled(true);
        const formData = new FormData();
        formData.append("pdf", file!);
        const URL = API_URL + "/CreateDeviceFileUpload.php";
        axios.post<IPhotoUploadResponse>(URL, formData, {
            headers: {
                'Accept-Language': 'tr',
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            , onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setProgressVal(percent);
            }
        }).then((response) => {
            if (selectedDevice) {
                toast.success(response.data.message)
                setProgressVal(100);
                setSelectedDevice({ ...selectedDevice, device_pdf_url: response.data.url })
            }
            else {
                toast.error("Cihaz seçilmemiştir.");
            }
        }).catch((response) => {
            if (response?.response?.data?.message) {
                toast.error(response?.response?.data?.message)
            }
            else {
                toast.error("Dosya yüklenirken hata oluştu.");
            }
            setProgressVal(100);
        }).finally(() => {
            setButtonDisabled(false);
        })
    }
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full max-w-2xl mx-auto my-6 px-4">
                    <div className="bg-white rounded-2xl shadow-2xl relative flex flex-col w-full outline-none">
                        {/* Header */}
                        <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-6 rounded-t-2xl">
                            <Typography
                                variant="h4"
                                color="white"
                                className="font-bold flex items-center gap-3"
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                            >
                                <i className="fas fa-edit"></i>
                                Cihaz Güncelleme
                            </Typography>
                        </div>

                        {/* Content */}
                        <div className="p-8 overflow-y-auto max-h-[calc(100vh-200px)]">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {/* Left Column */}
                                <div className="space-y-6">
                                    <div>
                                        <Input
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            error={error.device_seri_no}
                                            value={selectedDevice.device_seri_no}
                                            crossOrigin="anonymous"
                                            onChange={(event) => {
                                                setSelectedDevice({ ...selectedDevice, device_seri_no: event.target.value })
                                                setError({ ...error, device_seri_no: event.target.value.trim() === "" })
                                            }}
                                            label="Seri No"
                                            className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            icon={<i className="fas fa-barcode text-blue-gray-500/80"></i>}
                                        />
                                    </div>

                                    <div>
                                        <Input
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            error={error.device_name}
                                            value={selectedDevice.device_name}
                                            crossOrigin="anonymous"
                                            onChange={(event) => {
                                                setSelectedDevice({ ...selectedDevice, device_name: event.target.value })
                                                setError({ ...error, device_name: event.target.value.trim() === "" })
                                            }}
                                            label="Cihaz Adı"
                                            className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            icon={<i className="fas fa-microchip text-blue-gray-500/80"></i>}
                                        />
                                    </div>

                                    <div className="flex gap-4">
                                        <div className="flex-1">
                                            <Input
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                                error={error.device_password}
                                                value={selectedDevice.device_password}
                                                crossOrigin="anonymous"
                                                onChange={(event) => {
                                                    setSelectedDevice({ ...selectedDevice, device_password: event.target.value })
                                                    setError({ ...error, device_password: event.target.value.trim() === "" })
                                                }}
                                                label="Cihaz Şifre"
                                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                                labelProps={{
                                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                                }}
                                                icon={<i className="fas fa-key text-blue-gray-500/80"></i>}
                                            />
                                        </div>
                                        <Button
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            onClick={() => {
                                                setSelectedDevice({ ...selectedDevice, device_password: RandomPasswordGenerate() })
                                                setError({ ...error, device_password: false })
                                            }}
                                            className="flex items-center gap-2 px-4"
                                            color="blue"
                                            variant="text"
                                        >
                                            <i className="fas fa-dice"></i>
                                            Üret
                                        </Button>
                                    </div>

                                    <div>
                                        <Select
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            value={selectedDevice.is_statik == "1" ? "Statik" : "Dinamik"}
                                            onChange={(item) => {
                                                if (item && item.toString() === "Statik") {
                                                    setSelectedDevice({ ...selectedDevice, is_statik: "1" })
                                                } else {
                                                    setSelectedDevice({ ...selectedDevice, is_statik: "0" })
                                                }
                                            }}
                                            label="Cihaz Türü"
                                            className="!border !border-blue-gray-100 rounded-lg"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            menuProps={{ className: "p-1" }}
                                        >
                                            <Option key={"device_static"} value={"Statik"} className="p-2">Statik</Option>
                                            <Option key={"device_dynamic"} value={"Dinamik"} className="p-2">Dinamik</Option>
                                        </Select>
                                    </div>
                                </div>

                                {/* Right Column */}
                                <div className="space-y-6">
                                    {/* Coefficient X */}
                                    <div>
                                        <Select
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            value={selectedDevice.coefficient_x_auto ? "Otomatik" : "Manuel"}
                                            onChange={(item) => {
                                                if (item && item.toString() === "Otomatik") {
                                                    setSelectedDevice({ ...selectedDevice, coefficient_x_auto: true, coefficient_x: undefined })
                                                } else {
                                                    setSelectedDevice({ ...selectedDevice, coefficient_x_auto: false })
                                                }
                                            }}
                                            label="Roll Katsayı"
                                            className="!border !border-blue-gray-100 rounded-lg"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            menuProps={{ className: "p-1" }}
                                        >
                                            <Option key={"defX0"} value={"Manuel"} className="p-2">Manuel</Option>
                                            <Option key={"defX1"} value={"Otomatik"} className="p-2">Otomatik</Option>
                                        </Select>
                                        {!selectedDevice.coefficient_x_auto && (
                                            <Input
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                                error={!selectedDevice.coefficient_x}
                                                type={"number"}
                                                value={selectedDevice.coefficient_x}
                                                crossOrigin="anonymous"
                                                onChange={(event) => {
                                                    setSelectedDevice({ ...selectedDevice, coefficient_x: (event.target.value) })
                                                }}
                                                label="Roll Katsayı Giriniz"
                                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg mt-2"
                                                labelProps={{
                                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                                }}
                                            />
                                        )}
                                    </div>

                                    {/* Coefficient Y */}
                                    <div>
                                        <Select
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            value={selectedDevice.coefficient_y_auto ? "Otomatik" : "Manuel"}
                                            onChange={(item) => {
                                                if (item && item.toString() === "Otomatik") {
                                                    setSelectedDevice({ ...selectedDevice, coefficient_y_auto: true, coefficient_y: undefined })
                                                } else {
                                                    setSelectedDevice({ ...selectedDevice, coefficient_y_auto: false })
                                                }
                                            }}
                                            label="Pitch Katsayı"
                                            className="!border !border-blue-gray-100 rounded-lg"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            menuProps={{ className: "p-1" }}
                                        >
                                            <Option key={"defY0"} value={"Manuel"} className="p-2">Manuel</Option>
                                            <Option key={"defY1"} value={"Otomatik"} className="p-2">Otomatik</Option>
                                        </Select>
                                        {!selectedDevice.coefficient_y_auto && (
                                            <Input
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                                error={!selectedDevice.coefficient_y}
                                                type={"number"}
                                                value={selectedDevice.coefficient_y}
                                                crossOrigin="anonymous"
                                                onChange={(event) => {
                                                    setSelectedDevice({ ...selectedDevice, coefficient_y: (event.target.value) })
                                                }}
                                                label="Pitch Katsayı Giriniz"
                                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg mt-2"
                                                labelProps={{
                                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                                }}
                                            />
                                        )}
                                    </div>

                                    {/* Coefficient Z */}
                                    <div>
                                        <Select
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            value={selectedDevice.coefficient_z_auto ? "Otomatik" : "Manuel"}
                                            onChange={(item) => {
                                                if (item && item.toString() === "Otomatik") {
                                                    setSelectedDevice({ ...selectedDevice, coefficient_z_auto: true, coefficient_z: undefined })
                                                } else {
                                                    setSelectedDevice({ ...selectedDevice, coefficient_z_auto: false })
                                                }
                                            }}
                                            label="Yaw Katsayı"
                                            className="!border !border-blue-gray-100 rounded-lg"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            menuProps={{ className: "p-1" }}
                                        >
                                            <Option key={"defZ0"} value={"Manuel"} className="p-2">Manuel</Option>
                                            <Option key={"defZ1"} value={"Otomatik"} className="p-2">Otomatik</Option>
                                        </Select>
                                        {!selectedDevice.coefficient_z_auto && (
                                            <Input
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                                error={!selectedDevice.coefficient_z}
                                                type={"number"}
                                                value={selectedDevice.coefficient_z}
                                                crossOrigin="anonymous"
                                                onChange={(event) => {
                                                    setSelectedDevice({ ...selectedDevice, coefficient_z: (event.target.value) })
                                                }}
                                                label="Yaw Katsayı Giriniz"
                                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg mt-2"
                                                labelProps={{
                                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* PDF Section */}
                            <div className="mt-8 p-6 bg-blue-gray-50/30 rounded-xl">
                                <Typography
                                    variant="h6"
                                    color="blue-gray"
                                    className="mb-4 flex items-center gap-2"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-file-pdf text-blue-500"></i>
                                    PDF Dökümanı
                                </Typography>

                                {selectedDevice.device_pdf_url === "" ? (
                                    <div className="space-y-4">
                                        <div className="flex items-center gap-4">
                                            <input
                                                ref={fileUploadRef}
                                                type="file"
                                                placeholder="Pdf yükle"
                                                name="pdf"
                                                accept="application/pdf"
                                                className="flex-1 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-medium file:bg-blue-500 file:text-white hover:file:bg-blue-600 text-gray-600"
                                                onChange={(e: any) => {
                                                    let files = e.target.files[0];
                                                    setFile(files);
                                                }}
                                            />
                                            <Button
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                                disabled={buttonDisabled}
                                                onClick={() => UpdateOrCreateDeviceFile()}
                                                className="flex items-center gap-2"
                                            >
                                                <i className="fas fa-upload"></i>
                                                Yükle
                                            </Button>
                                        </div>

                                        {progressVal !== 100 && (
                                            <Progress
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                                value={progressVal}
                                                color="blue"
                                                className="h-1"
                                            />
                                        )}

                                        <Typography
                                            variant="small"
                                            color="red"
                                            className="flex items-center gap-2"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            <i className="fas fa-exclamation-circle"></i>
                                            PDF dosyası eklenmemiştir.
                                        </Typography>
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-3">
                                            <i className="fas fa-check-circle text-green-500 text-xl"></i>
                                            <Typography
                                                variant="paragraph"
                                                color="green"
                                                className="font-medium"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                PDF dosyası yüklenmiştir
                                            </Typography>
                                        </div>
                                        <Button
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            variant="text"
                                            color="red"
                                            onClick={() => setSelectedDevice({ ...selectedDevice, device_pdf_url: "" })}
                                            className="flex items-center gap-2"
                                        >
                                            <i className="fas fa-trash"></i>
                                            Sil
                                        </Button>
                                    </div>
                                )}

                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="mt-4 flex items-center gap-2 italic"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-info-circle text-blue-500"></i>
                                    PDF dosyasında yapılan değişiklikler "Güncelle" butonuna basılmadan kaydedilmez.
                                </Typography>
                            </div>

                            {/* Footer */}
                            <div className="flex items-center justify-end gap-4 mt-8">
                                <Button
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    variant="text"
                                    color="red"
                                    onClick={props.onCancel}
                                    className="flex items-center gap-2"
                                >
                                    <i className="fas fa-times"></i>
                                    İptal
                                </Button>
                                <Button
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    color="green"
                                    onClick={DeviceUpdate}
                                    className="flex items-center gap-2 bg-gradient-to-r from-green-500 to-green-600"
                                >
                                    <i className="fas fa-check"></i>
                                    Güncelle
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black/50 backdrop-blur-sm"></div>
        </>
    )
}
export default DevicesUpdateModal;