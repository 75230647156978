import { Button, Input, Select, Option, Typography, Card } from "@material-tailwind/react";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { IUsers } from "../../../models/AdminPanel";
import { EmailControl } from "../../../utils/EmailControl";
import { RandomPasswordGenerate } from "../../../utils/RandomPasswordGenerate";
import { IRole } from "../../../models/User";

interface Props {
    onAdded: () => void
}

const UsersAdd = (props: Props) => {
    const [user, setUser] = useState<IUsers>(
        { user_id: "", role: undefined, password: "", name: "", surname: "", email: "", expired_date: "", created_date: "" }
    );

    const [error, setError] = useState(
        { role: false, email: false, password: false, name: false, surname: false, expired_date: false }
    );

    const [buttonDisabled, setButtonDisabled] = useState(false);

    const AddUser = () => {
        if (
            user.password.trim() === "" ||
            user.name.trim() === "" ||
            user.surname.trim() === "" ||
            !EmailControl(user.email) ||
            user?.role?.trim() === "" ||
            user.expired_date.trim() === ""
        ) {
            setError(
                {
                    password: user.password.trim() === "",
                    name: user.name.trim() === "",
                    surname: user.surname.trim() === "",
                    email: !EmailControl(user.email),
                    role: user?.role?.trim() === "",
                    expired_date: user.expired_date.trim() === "",
                })
        } else {
            setButtonDisabled(true);
            let tempUser = user;
            tempUser.expired_date = moment(tempUser.expired_date, "YYYY-MM-DD").format("YYYY-MM-DD") + " 23:59:59"
            API.getInstance().CreateUser(tempUser)
                .then((response) => {
                    toast.success(response.data.message);
                    props.onAdded();
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                })
                .finally(() => {
                    setButtonDisabled(false);
                })
        }
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <Card className="w-full max-w-2xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {/* Header */}
                <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-8 relative overflow-hidden">
                    <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-sm"></div>
                    <div className="relative">
                        <div className="w-16 h-16 bg-white/10 rounded-xl flex items-center justify-center mb-4">
                            <i className="fas fa-user-plus text-white text-3xl"></i>
                        </div>
                        <Typography
                            variant="h4"
                            color="white"
                            className="font-bold"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            Yeni Kullanıcı Ekle
                        </Typography>
                        <Typography
                            variant="small"
                            className="text-blue-100 mt-2"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            Yeni bir kullanıcı eklemek için aşağıdaki formu doldurun
                        </Typography>
                    </div>
                </div>

                {/* Content */}
                <div className="p-8">
                    <div className="space-y-6">
                        {/* Email */}
                        <div>
                            <Input
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                type="email"
                                error={error.email}
                                value={user.email}
                                crossOrigin="anonymous"
                                onChange={(event) => {
                                    setUser({ ...user, email: event.target.value })
                                    setError({ ...error, email: !EmailControl(event.target.value) })
                                }}
                                label="E-posta"
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-envelope text-blue-gray-500/80"></i>}
                            />
                            {error.email && (
                                <Typography
                                    variant="small"
                                    color="red"
                                    className="flex items-center gap-1 mt-2"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-exclamation-circle"></i>
                                    Lütfen geçerli bir e-posta adresi giriniz.
                                </Typography>
                            )}
                        </div>

                        {/* Password */}
                        <div className="flex gap-4">
                            <div className="flex-1">
                                <Input
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    error={error.password}
                                    value={user.password}
                                    crossOrigin="anonymous"
                                    onChange={(event) => {
                                        setUser({ ...user, password: event.target.value })
                                        setError({ ...error, password: event.target.value.trim() === "" })
                                    }}
                                    label="Şifre"
                                    className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                    labelProps={{
                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                    }}
                                    icon={<i className="fas fa-key text-blue-gray-500/80"></i>}
                                />
                            </div>
                            <Button
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                onClick={() => {
                                    setUser({ ...user, password: RandomPasswordGenerate() })
                                    setError({ ...error, password: false })
                                }}
                                className="flex items-center gap-2 px-4"
                                color="blue"
                                variant="text"
                            >
                                <i className="fas fa-dice"></i>
                                Üret
                            </Button>
                        </div>

                        {/* Name */}
                        <div>
                            <Input
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                error={error.name}
                                crossOrigin="anonymous"
                                value={user.name}
                                onChange={(event) => {
                                    setUser({ ...user, name: event.target.value })
                                    setError({ ...error, name: event.target.value.trim() === "" })
                                }}
                                label="Ad"
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-user text-blue-gray-500/80"></i>}
                            />
                        </div>

                        {/* Surname */}
                        <div>
                            <Input
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                error={error.surname}
                                crossOrigin="anonymous"
                                value={user.surname}
                                onChange={(event) => {
                                    setUser({ ...user, surname: event.target.value })
                                    setError({ ...error, surname: event.target.value.trim() === "" })
                                }}
                                label="Soyad"
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-user text-blue-gray-500/80"></i>}
                            />
                        </div>

                        {/* Role */}
                        <div>
                            <Select
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                error={error.role}
                                value={user.role}
                                onChange={(item) => {
                                    setUser({ ...user, role: item?.toString() as IRole })
                                    setError({ ...error, role: item?.toString().trim() === "" })
                                }}
                                label="Rol seçiniz"
                                className="!border !border-blue-gray-100 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                menuProps={{ className: "p-1" }}
                            >
                                <Option value="USER" className="p-2">USER</Option>
                                <Option value="DEMO USER" className="p-2">DEMO USER</Option>
                            </Select>
                        </div>

                        {/* Expiry Date */}
                        <div>
                            <Input
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                type="date"
                                crossOrigin="anonymous"
                                error={error.expired_date}
                                value={user.expired_date}
                                onChange={(event) => {
                                    setUser({ ...user, expired_date: event.target.value })
                                    setError({ ...error, expired_date: event.target.value.trim() === "" })
                                }}
                                label="Üyelik Sona Erme Tarihi"
                                min={moment().format("YYYY-MM-DD")}
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-calendar text-blue-gray-500/80"></i>}
                            />
                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-center pt-4">
                            <Button
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                onClick={AddUser}
                                disabled={buttonDisabled}
                                className="flex items-center gap-2 px-6 bg-gradient-to-r from-green-500 to-green-600 shadow-lg hover:shadow-green-500/30 transition-all duration-300"
                                size="lg"
                            >
                                <i className="fas fa-user-plus text-lg"></i>
                                Kullanıcı Oluştur
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default UsersAdd;