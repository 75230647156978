import { Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { IconButton, Typography } from "@material-tailwind/react";
import { IPageItem, IPages } from "../models/AdminPanel";
import SideBarItem from "./SidebarItem";
import SideBarItemCollapse from "./SidebarItemCollapse";

interface Props {
    title: string
    isOpen: boolean
    onClick: () => void
    pages: IPages[],
    currentPage: IPageItem
    setCurrentPage: (page: IPageItem) => void
}

export function AdminSidebar(props: Props) {
    const { isOpen, onClick, pages } = props;
    return (
        <>
            <aside
                className={`${isOpen ? "translate-x-0" : "-translate-x-80"} fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl transition-transform duration-300 xl:translate-x-0 bg-white border border-blue-gray-50 shadow-xl`}
            >
                <div className="relative border-b">
                    <Link to="/" className="flex items-center gap-4 py-6 px-8">
                        <Typography 
                            placeholder="" 
                            variant="h5" 
                            color="blue-gray"
                            className="font-bold"
                            onPointerEnterCapture={() => {}} 
                            onPointerLeaveCapture={() => {}}
                        >
                            {props.title}
                        </Typography>
                    </Link>
                    <IconButton
                        variant="text"
                        color="blue-gray"
                        size="md"
                        ripple={false}
                        className="absolute right-3 top-3 grid rounded-br-none rounded-tl-none xl:hidden hover:bg-blue-gray-50"
                        onClick={onClick}
                        placeholder=""
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                    >
                        <XMarkIcon strokeWidth={2.5} className="h-5 w-5" />
                    </IconButton>
                </div>
                <div className="p-4">
                    <ul className="flex flex-col gap-2">
                        {pages.map((item) => (
                            <div key={item.index} className="hover:bg-blue-gray-50/50 rounded-lg transition-colors duration-200">
                                {item.subNav ? (
                                    <SideBarItemCollapse
                                        item={item}
                                        currentPage={props.currentPage}
                                        setCurrentPage={props.setCurrentPage}
                                    />
                                ) : (
                                    <SideBarItem
                                        item={item}
                                        currentPage={props.currentPage}
                                        setCurrentPage={props.setCurrentPage}
                                    />
                                )}
                            </div>
                        ))}
                    </ul>
                </div>
            </aside>
            {/* Overlay for mobile */}
            {isOpen && (
                <div 
                    className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm xl:hidden z-40"
                    onClick={onClick}
                />
            )}
        </>
    );
}

export default AdminSidebar;
