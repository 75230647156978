import { IPageItem } from "../models/AdminPanel";
import { Button, Typography } from "@material-tailwind/react";

interface Props {
    item: IPageItem
    setCurrentPage: (item: IPageItem) => void
    currentPage: IPageItem
    subNavItem?: IPageItem
}

const SideBarItem = (props: Props) => {
    const { item, subNavItem, currentPage } = props;
    const isActive = subNavItem ? 
        subNavItem.index === currentPage.index : 
        item.index === currentPage.index;

    return (
        <Button 
            placeholder="" 
            onPointerEnterCapture={() => {}} 
            onPointerLeaveCapture={() => {}}
            onClick={() => {
                subNavItem ? props.setCurrentPage(subNavItem) : props.setCurrentPage(item);
                subNavItem?.onClick ? subNavItem.onClick() : item.onClick && item.onClick();
            }}
            variant={isActive ? "filled" : "text"}
            color={isActive ? "blue" : "blue-gray"}
            className={`flex items-center gap-4 px-4 py-3 capitalize ${
                isActive ? 'shadow-md' : 'hover:bg-blue-gray-50'
            } transition-all duration-200`}
            fullWidth
        >
            <div className={`${isActive ? 'text-white' : 'text-blue-gray-500'}`}>
                {subNavItem ? subNavItem.icon : item.icon}
            </div>
            <Typography
                placeholder=""
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
                color={isActive ? "white" : "blue-gray"}
                className="font-medium capitalize"
            >
                {subNavItem ? subNavItem.name : item.name}
            </Typography>
        </Button>
    );
}

export default SideBarItem;