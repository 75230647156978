import { FC, useRef } from "react"
import { IPhotoUploadResponse, IUpdateDeviceUserInfo } from "../../../models/Response";
import { Alert, Button, Input, Progress, Select, Option } from "@material-tailwind/react";
import { useState } from "react";
import API from "../../../API/API";
import toast from "react-hot-toast";
import axios from "axios";
import { API_URL } from "../../../common/AppSettings";
import DeleteModal from "../../../components/DeleteModal";
import { Typography } from "@material-tailwind/react";

interface Props {
    onCancel: () => void
    device: IUpdateDeviceUserInfo
    onUpdated: () => void
}
const SettingsModalUser: FC<Props> = (props) => {
    const { onUpdated, onCancel } = props;
    const [device, setDevice] = useState<IUpdateDeviceUserInfo>(props.device);
    const [error, setError] = useState({
        device_name: false,
        device_height: false,
        veri_gonderme_suresi: false,
        o_sayisi: false,
        gyro_esik: false
    });

    const DeviceUpdate = async () => {
        if (device?.device_name.trim() === "" || Number.isNaN(device.device_height) || (device.veri_gonderme_suresi < 1 || device.veri_gonderme_suresi > 86400)) {
            setError({
                device_name: device?.device_name.trim() === "",
                device_height: Number.isNaN(device.device_height),
                veri_gonderme_suresi: (device.veri_gonderme_suresi < 1 || device.veri_gonderme_suresi > 86400),
                o_sayisi: device.o_sayisi < 1 || device.o_sayisi > 20,
                gyro_esik: device.gyro_esik < 0.001
            });
        } else {
            setError({
                device_name: false,
                device_height: false,
                veri_gonderme_suresi: false,
                o_sayisi: false,
                gyro_esik: false,
            });
            UpdateDevice();
        }
    }
    const UpdateDevice = () => {
        API.getInstance().UpdateDeviceUser(device)
            .then((response) => {
                toast.success(response.data.message);
                onUpdated();
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const [progressVal, setProgressVal] = useState(101);
    const fileUploadRef = useRef<any>();
    const AddPhoto = (photoUrl: string) => {
        setDevice({ ...device, device_photos: [...device.device_photos, photoUrl] })
    };
    const DelPhoto = () => {
        const newPhotos = [...device.device_photos];
        newPhotos.splice(deletePhotoIndex, 1);
        setDevice({ ...device, device_photos: newPhotos })
        setDeleteModal(false);
    };

    const UploadDevicePhoto = async (image: any) => {
        const formData = new FormData();
        formData.append("image", image);
        const URL = API_URL + "/UploadDevicePhoto.php";
        axios.post<IPhotoUploadResponse>(URL, formData, {
            headers: {
                'Accept-Language': 'tr',
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            , onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setProgressVal(percent);
            }
        }).then((response) => {
            AddPhoto(response.data.url);
            setProgressVal(101);
        }).catch((response) => {
            if (response?.response?.data?.message) {
                toast.error(response?.response?.data?.message)
            }
            else {
                toast.error("Fotoğraf yüklenirken hata oluştu.");
            }
            setProgressVal(101);
        })
    }

    const [deleteModal, setDeleteModal] = useState(false);
    const [deletePhotoIndex, setDeletePhotoIndex] = useState(0);

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full max-w-3xl mx-auto my-6 px-4">
                    <div className="bg-white rounded-2xl shadow-2xl relative flex flex-col w-full outline-none">
                        {/* Header */}
                        <div className="bg-gradient-to-r from-blue-500 to-blue-600 rounded-t-2xl p-6">
                            <Typography
                                variant="h4"
                                color="white"
                                className="font-bold flex items-center gap-3"
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                            >
                                <i className="fas fa-cog text-white/80"></i>
                                Cihaz Ayarları
                            </Typography>
                        </div>

                        {/* Content */}
                        <div className="p-8 overflow-y-auto max-h-[calc(100vh-200px)]">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {/* Left Column */}
                                <div className="space-y-6">
                                    {/* Serial Number */}
                                    <div>
                                        <Input
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            crossOrigin="anonymous"
                                            disabled
                                            value={"Seri No : " + device.device_seri_no}
                                            className="!border !border-blue-gray-50 bg-blue-gray-50/30 rounded-xl focus:!border-blue-500"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            containerProps={{
                                                className: "min-w-[100px]"
                                            }}
                                        />
                                    </div>

                                    {/* Device Name */}
                                    <div>
                                        <Input
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            crossOrigin="anonymous"
                                            error={error.device_name}
                                            value={device.device_name}
                                            onChange={(event) => {
                                                setDevice({ ...device, device_name: event.target.value })
                                                setError({ ...error, device_name: event.target.value.trim() === "" })
                                            }}
                                            label="Cihaz Adı"
                                            className="!border !border-blue-gray-100 rounded-xl focus:!border-blue-500"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            containerProps={{
                                                className: "min-w-[100px]"
                                            }}
                                        />
                                    </div>

                                    {/* Device Description */}
                                    <div>
                                        <Input
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            crossOrigin="anonymous"
                                            value={device.device_description}
                                            onChange={(event) => {
                                                setDevice({ ...device, device_description: event.target.value })
                                            }}
                                            label="Cihaz Açıklaması"
                                            className="!border !border-blue-gray-100 rounded-xl focus:!border-blue-500 h-24"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            containerProps={{
                                                className: "min-w-[100px]"
                                            }}
                                        />
                                    </div>

                                    {/* Device Height */}
                                    <div>
                                        <Input
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            crossOrigin="anonymous"
                                            type="number"
                                            value={device.device_height}
                                            error={error.device_height}
                                            onChange={(event) => {
                                                setDevice({ ...device, device_height: parseInt((event.target.value)) })
                                                setError({ ...error, device_height: Number.isNaN(parseInt((event.target.value))) })
                                            }}
                                            label="Cihaz Yüksekliği (mm)"
                                            className="!border !border-blue-gray-100 rounded-xl focus:!border-blue-500"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            containerProps={{
                                                className: "min-w-[100px]"
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* Right Column */}
                                <div className="space-y-6">
                                    {/* Device Type */}
                                    <div>
                                        <Select
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            value={device.is_statik == "1" ? "Statik" : "Dinamik"}
                                            onChange={(item) => {
                                                if (item && item.toString() === "Statik") {
                                                    setDevice({ ...device, is_statik: "1" })
                                                } else {
                                                    setDevice({ ...device, is_statik: "0" })
                                                }
                                            }}
                                            label="Cihaz Türü"
                                            className="!border !border-blue-gray-100 rounded-xl"
                                            labelProps={{
                                                className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                            }}
                                            menuProps={{ className: "p-1" }}
                                        >
                                            <Option key={"device_static"} value={"Statik"} className="p-2">Statik</Option>
                                            <Option key={"device_dynamic"} value={"Dinamik"} className="p-2">Dinamik</Option>
                                        </Select>
                                    </div>

                                    {/* Data Sending Time - Static */}
                                    {device.is_statik == "1" && (
                                        <div>
                                            <Input
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                                crossOrigin="anonymous"
                                                error={error.veri_gonderme_suresi}
                                                min={600}
                                                max={86400}
                                                step={1}
                                                type="number"
                                                value={device.veri_gonderme_suresi}
                                                onChange={(event) => {
                                                    setDevice({ ...device, veri_gonderme_suresi: parseInt((event.target.value)) })
                                                    if (event.target.value == "") {
                                                        setError({ ...error, veri_gonderme_suresi: true })
                                                    } else {
                                                        setError({ ...error, veri_gonderme_suresi: (parseInt((event.target.value)) < 600 || parseInt((event.target.value)) > 86400) })
                                                    }
                                                }}
                                                label="Veri Gönderme Süresi (sn)"
                                                className="!border !border-blue-gray-100 rounded-xl focus:!border-blue-500"
                                                labelProps={{
                                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                                }}
                                                containerProps={{
                                                    className: "min-w-[100px]"
                                                }}
                                            />
                                            <Typography
                                                variant="small"
                                                className="mt-0.5 text-[11px] text-blue-gray-400 font-medium ml-1"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Min: 600 - Max: 86400
                                            </Typography>
                                        </div>
                                    )}

                                    {/* Data Sending Time - Dynamic */}
                                    {device.is_statik == "0" && (
                                        <div>
                                            <Input
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                                crossOrigin="anonymous"
                                                error={error.veri_gonderme_suresi}
                                                min={1}
                                                max={60}
                                                step={1}
                                                type="number"
                                                value={device.veri_gonderme_suresi}
                                                onChange={(event) => {
                                                    setDevice({ ...device, veri_gonderme_suresi: parseInt((event.target.value)) })
                                                    if (event.target.value == "") {
                                                        setError({ ...error, veri_gonderme_suresi: true })
                                                    } else {
                                                        setError({ ...error, veri_gonderme_suresi: (parseInt((event.target.value)) < 1 || parseInt((event.target.value)) > 60) })
                                                    }
                                                }}
                                                label="Veri Gönderme Süresi (sn)"
                                                className="!border !border-blue-gray-100 rounded-xl focus:!border-blue-500"
                                                labelProps={{
                                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                                }}
                                                containerProps={{
                                                    className: "min-w-[100px]"
                                                }}
                                            />
                                            <Typography
                                                variant="small"
                                                className="mt-0.5 text-[11px] text-blue-gray-400 font-medium ml-1"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Min: 1sn - Max: 60sn
                                            </Typography>
                                        </div>
                                    )}

                                    {/* Other Dynamic Fields */}
                                    {device.is_statik == "0" && (
                                        <>
                                            <div>
                                                <Input
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                    error={error.o_sayisi}
                                                    crossOrigin="anonymous"
                                                    min={1}
                                                    max={20}
                                                    type="number"
                                                    value={device.o_sayisi}
                                                    onChange={(event) => {
                                                        setDevice({ ...device, o_sayisi: parseInt((event.target.value)) })
                                                        if (event.target.value == "") {
                                                            setError({ ...error, o_sayisi: true })
                                                        } else {
                                                            setError({ ...error, o_sayisi: parseInt((event.target.value)) < 1 || parseInt(event.target.value) > 20 });
                                                        }
                                                    }}
                                                    label="Frekans Hz"
                                                    className="!border !border-blue-gray-100 rounded-xl focus:!border-blue-500"
                                                    labelProps={{
                                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                                    }}
                                                    containerProps={{
                                                        className: "min-w-[100px]"
                                                    }}
                                                />
                                                <Typography
                                                    variant="small"
                                                    className="mt-0.5 text-[11px] text-blue-gray-400 font-medium ml-1"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    Min: 1hz - Max: 20hz
                                                </Typography>
                                            </div>

                                            <div>
                                                <Input
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                    error={error.gyro_esik}
                                                    crossOrigin="anonymous"
                                                    min={0.001}
                                                    max={2.000}
                                                    onWheel={(e) => e.preventDefault()}
                                                    type="number"
                                                    value={device.gyro_esik}
                                                    step={0.001}
                                                    onChange={(event) => {
                                                        setDevice({ ...device, gyro_esik: parseFloat((event.target.value)) })
                                                        if (event.target.value == "") {
                                                            setError({ ...error, gyro_esik: true })
                                                        } else {
                                                            setError({ ...error, gyro_esik: parseFloat((event.target.value)) < 0.001 || parseFloat(event.target.value) > 2.000 })
                                                        }
                                                    }}
                                                    label="İvme(g) Eşik Değeri"
                                                    className="!border !border-blue-gray-100 rounded-xl focus:!border-blue-500"
                                                    labelProps={{
                                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                                    }}
                                                    containerProps={{
                                                        className: "min-w-[100px]"
                                                    }}
                                                />
                                                <Typography
                                                    variant="small"
                                                    className="mt-0.5 text-[11px] text-blue-gray-400 font-medium ml-1"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    Min: 0.001g - Max: 2.000g
                                                </Typography>
                                            </div>
                                        </>
                                    )}

                                    {/* Static Fields */}
                                    {device.is_statik == "1" && (
                                        <>
                                            <div>
                                                <Input
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                    error={error.gyro_esik}
                                                    crossOrigin="anonymous"
                                                    min={1}
                                                    onWheel={(e) => e.preventDefault()}
                                                    type="number"
                                                    value={device.gyro_esik}
                                                    step={1}
                                                    onChange={(event) => {
                                                        setDevice({ ...device, gyro_esik: parseInt((event.target.value)) })
                                                        if (event.target.value == "") {
                                                            setError({ ...error, gyro_esik: true })
                                                        } else {
                                                            setError({ ...error, gyro_esik: parseInt((event.target.value)) < 1 })
                                                        }
                                                    }}
                                                    label="İnd. Sapma Eşik Değeri (mm)"
                                                    className="!border !border-blue-gray-100 rounded-xl focus:!border-blue-500"
                                                    labelProps={{
                                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                                    }}
                                                    containerProps={{
                                                        className: "min-w-[100px]"
                                                    }}
                                                />
                                                <Typography
                                                    variant="small"
                                                    className="mt-0.5 text-[11px] text-blue-gray-400 font-medium ml-1"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    Min: 1
                                                </Typography>
                                            </div>

                                            <div>
                                                <Select
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                    value={device.dynamic_type}
                                                    onChange={(item) => {
                                                        if (item && item.toString() === "none") {
                                                            setDevice({ ...device, dynamic_type: "none" });
                                                        } else if (item && item.toString() === "day") {
                                                            setDevice({ ...device, dynamic_type: "day" });
                                                        } else {
                                                            setDevice({ ...device, dynamic_type: "hour" });
                                                        }
                                                    }}
                                                    label="Veri Ortalama"
                                                    className="!border !border-blue-gray-100 rounded-xl"
                                                    labelProps={{
                                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                                    }}
                                                    menuProps={{ className: "p-1" }}
                                                >
                                                    <Option key={"ortType1"} value={"none"} className="p-2">Yok</Option>
                                                    <Option key={"ortType2"} value={"hour"} className="p-2">Saatlik</Option>
                                                    <Option key={"ortType3"} value={"day"} className="p-2">Günlük</Option>
                                                </Select>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            {/* Photos Section */}
                            <div className="mt-8">
                                <div className="flex items-center justify-between mb-4">
                                    <Typography
                                        variant="h6"
                                        color="blue-gray"
                                        className="font-bold flex items-center gap-2"
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        <i className="fas fa-camera text-blue-500"></i>
                                        {device.device_photos.length === 0 ? 
                                            "Cihaza ait fotoğraf bulunmamaktadır." : 
                                            "Cihaz Fotoğrafları"}
                                    </Typography>
                                    {device.device_photos.length < 5 && (
                                        <Button
                                            variant="text"
                                            disabled={progressVal !== 101}
                                            onClick={() => fileUploadRef.current.click()}
                                            className="flex items-center gap-2 text-blue-500"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            <i className="fas fa-plus"></i>
                                            Fotoğraf Ekle
                                        </Button>
                                    )}
                                </div>
                                
                                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                                    {device.device_photos.map((item, index) => (
                                        <div key={index} className="relative group">
                                            <a href={item} target="_blank" className="block">
                                                <img
                                                    className="h-24 w-full object-cover rounded-xl border border-blue-gray-100 transition-all duration-200 group-hover:border-blue-500 group-hover:shadow-lg"
                                                    src={item}
                                                    alt={`Device photo ${index + 1}`}
                                                />
                                            </a>
                                            <button
                                                onClick={() => {
                                                    setDeleteModal(true);
                                                    setDeletePhotoIndex(index);
                                                }}
                                                className="absolute -top-2 -right-2 p-2 bg-white rounded-full shadow-lg hover:bg-red-50 transition-colors"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="red" className="w-4 h-4">
                                                    <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                        </div>
                                    ))}
                                </div>

                                {/* Upload Progress */}
                                {progressVal !== 101 && (
                                    <div className="mt-4">
                                        <div className="flex items-center gap-2 mb-2">
                                            <i className="fas fa-upload text-blue-500"></i>
                                            <Typography
                                                variant="small"
                                                className="font-medium text-blue-gray-600"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Fotoğraf Yükleniyor...
                                            </Typography>
                                        </div>
                                        <Progress
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            value={progressVal - 3}
                                            color="blue"
                                            className="h-1"
                                        />
                                    </div>
                                )}

                                <input
                                    ref={fileUploadRef}
                                    type="file"
                                    className="hidden"
                                    accept="image/png, image/jpeg, image/jpg, image/gif"
                                    onChange={(e: any) => {
                                        UploadDevicePhoto(e.target.files[0]);
                                    }}
                                />
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="flex items-center justify-end gap-3 p-6 border-t border-blue-gray-50">
                            <Button
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                variant="text"
                                color="red"
                                onClick={onCancel}
                                className="flex items-center gap-2"
                            >
                                <i className="fas fa-times"></i>
                                İptal
                            </Button>
                            <Button
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                color="green"
                                disabled={progressVal != 101}
                                onClick={DeviceUpdate}
                                className="flex items-center gap-2 bg-gradient-to-r from-green-500 to-green-600"
                            >
                                <i className="fas fa-check"></i>
                                Güncelle
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black/50 backdrop-blur-sm"></div>
            {deleteModal &&
                <DeleteModal
                    title={"Fotoğraf Silinsin Mi"}
                    description={"Seçmiş olduğunuz fotoğraf silinsin mi? Güncelle butonuna tıklamadığınız sürece güncellenmez."}
                    onCancel={() => setDeleteModal(false)}
                    onOk={() => DelPhoto()}
                />
            }
        </>)
}
export default SettingsModalUser;

