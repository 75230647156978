import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { IUsers } from "../../../models/AdminPanel";
import { DBToDateText } from "../../../utils/DBToDateText";
import Loading from "../../../components/Loading";
import { Button, Card, Typography, Chip } from "@material-tailwind/react";
import DeleteModal from "../../../components/DeleteModal";
import UserUpdateModal from "./components/UserUpdateModal";

const Users = () => {
    const [users, setUsers] = useState<IUsers[]>()
    const [loading, setLoading] = useState(false);
    const [deletedId, setDeletedId] = useState("");
    const [updatedUser, setUpdatedUser] = useState<IUsers>();
    const GetUsers = () => {
        setLoading(true);
        API.getInstance().GetUsers()
            .then((response) => {
                setUsers(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => setLoading(false))
    };

    useEffect(() => {
        GetUsers();
    }, [])

    const DeleteUser = () => {
        API.getInstance().DeleteUser(deletedId)
            .then((response) => {
                toast.success(response.data.message);
                GetUsers();
                setDeletedId("");
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };

    return (
        <>
            {loading ? (
                <div className="flex items-center justify-center min-h-[60vh]">
                    <Loading />
                </div>
            ) : (
                <div className="container mx-auto px-4 py-8">
                    <Card className="w-full bg-white rounded-xl shadow-lg overflow-hidden" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                        {/* Header */}
                        <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-8 relative overflow-hidden">
                            <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-sm"></div>
                            <div className="relative">
                                <div className="w-16 h-16 bg-white/10 rounded-xl flex items-center justify-center mb-4">
                                    <i className="fas fa-users text-white text-3xl"></i>
                                </div>
                                <Typography
                                    variant="h4"
                                    color="white"
                                    className="font-bold"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    Kullanıcı Listesi
                                </Typography>
                                <Typography
                                    variant="small"
                                    className="text-blue-100 mt-2"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    Sistemde kayıtlı tüm kullanıcıları görüntüleyin ve yönetin
                                </Typography>
                            </div>
                        </div>

                        {/* Table */}
                        <div className="overflow-x-auto">
                            <table className="w-full min-w-max table-auto text-left">
                        <thead>
                                    <tr className="bg-blue-gray-50">
                                        <th className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                ID
                                            </Typography>
                                        </th>
                                        <th className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Rol
                                            </Typography>
                                        </th>
                                        <th className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Email
                                            </Typography>
                                        </th>
                                        <th className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Şifre
                                            </Typography>
                                        </th>
                                        <th className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Ad
                                            </Typography>
                                        </th>
                                        <th className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Soyad
                                            </Typography>
                                        </th>
                                        <th className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Bitiş Tarihi
                                            </Typography>
                                        </th>
                                        <th className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                Oluşturma Tarihi
                                            </Typography>
                                        </th>
                                        <th className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                İşlemler
                                            </Typography>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users?.map((item, index) => {
                                        const isLast = index === users.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                        
                                        return (
                                            <tr key={index} className="hover:bg-blue-gray-50/50 transition-colors">
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                        placeholder=""
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                    >
                                                        {item.user_id}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Chip
                                                        value={item.role}
                                                        color={item.role === "ADMIN" ? "blue" : "green"}
                                                        variant="gradient"
                                                        size="sm"
                                                        className="font-medium"
                                                    />
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                        placeholder=""
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                    >
                                                        {item.email}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                        placeholder=""
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                    >
                                                        {item.password}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                        placeholder=""
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                        placeholder=""
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                    >
                                                        {item.surname}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                        placeholder=""
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                    >
                                                        {item.role === "ADMIN" ? (
                                                            <span className="text-blue-gray-300">YOK</span>
                                                        ) : (
                                                            DBToDateText(item.expired_date)
                                                        )}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                        placeholder=""
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                    >
                                                        {DBToDateText(item.created_date)}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex items-center gap-2">
                                                        <Button
                                                            size="sm"
                                                            variant="text"
                                                            color="green"
                                                            className="flex items-center gap-2 hover:bg-green-50"
                                                            onClick={() => setUpdatedUser(item)}
                                                            placeholder=""
                                                            onPointerEnterCapture={() => {}}
                                                            onPointerLeaveCapture={() => {}}
                                                        >
                                                            <i className="fas fa-edit text-sm"></i>
                                                            Güncelle
                                                        </Button>
                                                        <Button
                                                            size="sm"
                                                            variant="text"
                                                            color="red"
                                                            className="flex items-center gap-2 hover:bg-red-50"
                                                            onClick={() => setDeletedId(item.user_id)}
                                                            placeholder=""
                                                            onPointerEnterCapture={() => {}}
                                                            onPointerLeaveCapture={() => {}}
                                                        >
                                                            <i className="fas fa-trash text-sm"></i>
                                                            Sil
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                        </tbody>
                    </table>
                        </div>
                    </Card>
                </div>
            )}

            {deletedId !== "" && (
                <DeleteModal
                    title="Kullanıcıyı Sil"
                    description="Kullanıcıyı silmek ve tanımlı cihazları kaldırmak istiyor musunuz?"
                    onCancel={() => setDeletedId("")}
                    onOk={() => DeleteUser()}
                />
            )}

            {updatedUser && (
                <UserUpdateModal
                    onUpdated={() => {
                        setUpdatedUser(undefined);
                        GetUsers();
                    }}
                    onCancel={() => setUpdatedUser(undefined)}
                    selectedUser={updatedUser}
                />
            )}
        </>
    );
}

export default Users;