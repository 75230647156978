import { FC, useState, useRef } from "react"
import { IUserDeviceData } from "../../../models/Response";
import { Line as LineCharJS } from 'react-chartjs-2';
import { Chart as ChartJS } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import CHART_COLORS from "../../../constants/ChartColors";
import zoomPlugin from 'chartjs-plugin-zoom';
import Chart from 'chart.js/auto';
import moment from "moment";
import 'chartjs-plugin-annotation';

// Register the zoom plugin
Chart.register(zoomPlugin);
Chart.register(annotationPlugin);

interface Props {
  data: IUserDeviceData[];
  statik: "0" | "1"
}


interface ChartRefType {
  resetZoom: () => void;
}

const AccChartJS: FC<Props> = ({ data, statik }) => {
  const [chartData] = useState<IUserDeviceData[]>(data);
  const chartRef = useRef<ChartRefType | null>(null); // Specify the type of chartRef
  const chartRef2 = useRef<ChartRefType | null>(null); // Specify the type of chartRef



  const resetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };
  const resetZoom2 = () => {
    if (chartRef2 && chartRef2.current) {
      chartRef2.current.resetZoom();
    }
  };
  let datasests = [];
  if (chartData[0].temp) {
    datasests.push(
      {
        label: 'Sıcaklık (°C)',
        data: chartData.map((item) => item.temp),
        borderColor: CHART_COLORS.red,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y1',
        hidden: true,
      }
    )
  }
  if (chartData[0].acc_a) {
    datasests.push(
      {
        label: 'İvme A(g)',
        data: chartData.map((item) => item.acc_a),
        borderColor: CHART_COLORS.purple,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: false,
      }
    )
  }
  if (chartData[0].acc_x) {
    datasests.push(
      {
        label: 'İvme X(g)',
        data: chartData.map((item) => item.acc_x),
        borderColor: CHART_COLORS.blue,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  if (chartData[0].acc_y) {
    datasests.push(
      {
        label: 'İvme Y(g)',
        data: chartData.map((item) => item.acc_y),
        borderColor: CHART_COLORS.green,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  if (chartData[0].acc_z) {
    datasests.push(
      {
        label: 'İvme Z(g)',
        data: chartData.map((item) => item.acc_z),
        borderColor: CHART_COLORS.yellow,
        borderWidth: 2,
        pointRadius: 0,
        yAxisID: 'y',
        hidden: true,
      }
    )
  }
  return (
    <div className="space-y-8 p-4">
      {(chartData.length > 0 && (chartData[0].temp || chartData[0].acc_a || chartData[0].acc_x || chartData[0].acc_y || chartData[0].acc_z)) && (
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-blue-gray-800">
              İvme ve Sıcaklık Grafiği
            </h3>
            <button 
              onClick={resetZoom}
              className="flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-gray-50/50 hover:bg-blue-gray-50 text-blue-gray-700 transition-colors duration-200"
            >
              <i className="fas fa-search-minus text-sm"></i>
              <span className="text-sm font-medium">Zoom Sıfırla</span>
            </button>
          </div>
          <div className="h-[50vh] w-full">
            <LineCharJS
              ref={(ref) => {
                if (ref) {
                  chartRef.current = ref;
                }
              }}
              options={
                {
                  responsive: true,
                  maintainAspectRatio: false,
                  interaction: {
                    mode: 'index',
                    intersect: false,
                  },
                  plugins: {
                    title: {
                      display: false,
                      text: 'İvme Grafiği'
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          var label = context.dataset.label || '';
                          if (label) {
                            label += ': ';
                          }
                          if (context.parsed.y !== null) {
                            if (context.dataset.yAxisID === 'y') {
                              label += Number(context.parsed.y).toFixed(5);
                            } else if (context.dataset.yAxisID === 'y1') {
                              label += Number(context.parsed.y).toFixed(2);
                            }
                          }
                          return label;
                        }
                      }
                    },
                    zoom: {
                      pan: {
                        enabled: true,
                        mode: 'xy',
                      },
                      zoom: {
                        mode: 'xy',
                        wheel: { enabled: true },
                        pinch: { enabled: true },
                      }
                    },
                  },
                  scales: {
                    x: {
                      display: true,
                      title: {display: true,text: 'Zaman'},
                      ticks: {
                        callback: function (value, index, ticks) {
                          const date = this.getLabelForValue(value as number)
                          if (statik == "1") {
                            return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
                          } else {
                            return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss");
                          }
                        }
                      }
                    },
                    y: {
                      type: "linear",
                      display: true,
                      position: 'left',
                      title: {display: true,text: 'İvme (g)'},
                    },
                    y1: {
                      type: "linear",
                      display: chartData[0].temp ? true : false,
                      title: {display: true,text: 'Sıcaklık (°C)'},
                      position: 'right',
                      grid: {
                        drawOnChartArea: false
                      },
                    },
                  }
                }
              }
              data={{
                labels: chartData.map((item) => moment(item.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")),
                datasets: datasests
              }}
            />
          </div>
        </div>
      )}
      {(chartData.length > 0 && (chartData[0].acc_x || chartData[0].acc_y)) && (
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-blue-gray-800">
              İvme X-Y Dağılım Grafiği
            </h3>
            <button 
              onClick={resetZoom2}
              className="flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-gray-50/50 hover:bg-blue-gray-50 text-blue-gray-700 transition-colors duration-200"
            >
              <i className="fas fa-search-minus text-sm"></i>
              <span className="text-sm font-medium">Zoom Sıfırla</span>
            </button>
          </div>
          <div className="h-[50vh] w-full">
            <ChartJS
              type="scatter"
              ref={(ref) => {
                if (ref) {
                  chartRef2.current = ref;
                }
              }}
              data={{
                datasets: [
                  {
                    // İlk veri noktasını gösteren scatter grafiği
                    label: 'İlk Veri',
                    data: [{ x: chartData[0].acc_x, y: chartData[0].acc_y }],
                    backgroundColor: 'green',
                    borderColor: 'green',
                    pointRadius: 7,
                    hidden: false,
                  },
                  {
                    // Son veri noktasını gösteren scatter grafiği
                    label: 'Son Veri',
                    data: [{ x: chartData[chartData.length - 1].acc_x, y: chartData[chartData.length - 1].acc_y }],
                    backgroundColor: 'red',
                    borderColor: 'red',
                    pointRadius: 10,
                    hidden: false,
                  },
                  {
                    // Diğer tüm veri noktalarını gösteren scatter grafiği
                    label: 'Diğer Veriler',
                    data: chartData.slice(1, chartData.length - 1).map(item => ({ x: item.acc_x, y: item.acc_y })),
                    backgroundColor: 'blue',
                    borderColor: 'blue',
                    pointRadius: 5,
                    hidden: true,
                  }
                ]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    display: true,
                    title: {
                      display: true,
                      text: 'İvme X (g)'
                    }
                  },
                  y: {
                    display: true,
                    title: {
                      display: true,
                      text: 'İvme Y (g)'
                    }
                  }
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        var label = context.dataset.label || '';
                        if (label) {
                          label += ': ';
                        }
                        if (context.parsed.x !== null) {
                          label += `İvme X (g): ${Number(context.parsed.x).toFixed(5)}`;
                        }
                        if (context.parsed.y !== null) {
                          label += `,İvme Y (g): ${Number(context.parsed.y).toFixed(5)}`;
                        }
                        return label;
                      }
                    }
                  },
                  annotation: {
                    annotations: {
                      line1: {
                        type: 'line',
                        xMin: chartData[0].acc_x,
                        xMax: chartData[0].acc_x,
                        borderColor: 'green',
                        borderWidth: 2,
                      },
                      line2: {
                        type: 'line',
                        yMin: chartData[0].acc_y,
                        yMax: chartData[0].acc_y,
                        borderColor: 'green',
                        borderWidth: 2,
                      },
                      line3: {
                        type: 'line',
                        xMin: chartData[chartData.length - 1].acc_x,
                        xMax: chartData[chartData.length - 1].acc_x,
                        borderColor: 'red',
                        borderWidth: 2,
                      },
                      line4: {
                        type: 'line',
                        yMin: chartData[chartData.length - 1].acc_y,
                        yMax: chartData[chartData.length - 1].acc_y,
                        borderColor: 'red',
                        borderWidth: 2,
                      },
                    },
                  },
                  zoom: {
                    pan: {
                      enabled: true,
                      mode: 'xy',
                    },
                    zoom: {
                      mode: 'xy',
                      wheel: { enabled: true },
                      pinch: { enabled: true },
                    }
                  },
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default AccChartJS;
