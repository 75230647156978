import { ArrowPathIcon, ArrowRightOnRectangleIcon, BellIcon, CpuChipIcon, HomeIcon, IdentificationIcon, KeyIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar';
import { IDevice, IPageItem, IPages } from '../../models/AdminPanel';
import Profile from '../Profile/Profile';
import ProfileEdit from '../Profile/ProfileEdit';
import ProfilePasswordUpdate from '../Profile/ProfilePasswordUpdate';
import Header from '../../components/Header';
import Device from './Device/Device';
import API from '../../API/API';
import toast from 'react-hot-toast';
import UserHomePage from './UserHomePage';
import AboutPage from './AboutPage';
import moment from 'moment';

interface Props {
    onLogOut: () => void
}

const icon = {
    className: "w-5 h-5 text-inherit transition-colors",
};

const UserPanel = (props: Props) => {
    const [devices, setDevices] = useState<IDevice[]>([]);
    const [subNavs, setSubNavs] = useState<IPageItem[]>();
    // const onChangeCurrentPageTrigger = (index: number) => {
    //     onChangeCurrentPage(index);
    // }
    const pages: IPages[] = [
        {
            index: 1,
            icon: <HomeIcon {...icon} />,
            name: "Ana Sayfa",
            element: <UserHomePage
                devices={devices}
                onClickDevice={(seri_no: string) => {
                    const sub = subNavs?.filter((item) => item.name === seri_no)[0]
                    if (sub) {
                        setCurrentPage(sub);
                        localStorage.setItem("page", sub.index.toString());
                    }
                }}
            />,
            onClick: () => { },
        },
        {
            index: 2,
            icon: <CpuChipIcon {...icon} />,
            name: "Cihazlarım",
            subNav: subNavs
        },
        {
            index: 3,
            icon: <BellIcon {...icon} />,
            name: "Profilim",
            onClick: () => {
                setIsOpen(!isOpen);
            },
            subNav: [
                {
                    index: 31,
                    name: "Profilim",
                    icon: <UserCircleIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <Profile />
                },
                {
                    index: 32,
                    name: "Profili Güncelle",
                    icon: <ArrowPathIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <ProfileEdit onUpdated={() => {
                        pages[2].subNav && setCurrentPage(pages[2].subNav[0])
                    }} />
                },
                {
                    index: 33,
                    name: "Şifre Değiştir",
                    icon: <KeyIcon {...icon} />,
                    onClick: () => {
                        setIsOpen(!isOpen);
                    },
                    element: <ProfilePasswordUpdate onUpdated={() => {
                        pages[2].subNav && setCurrentPage(pages[2].subNav[0])
                    }} />
                },

            ]
        },
        {
            index: 4,
            icon: <IdentificationIcon {...icon} />,
            name: "İletişim",
            element: <AboutPage />
        },
        {
            index: 5,
            icon: <ArrowRightOnRectangleIcon {...icon} />,
            name: "Çıkış Yap",
            onClick: () => { props.onLogOut() },
        },
    ];

    useEffect(() => {
        API.getInstance().GetUserDevices()
            .then((response) => {
                setDevices(response.data.data)
                const tempSubNavs = response.data.data.map((item, index) => {
                    const status = moment(item.updated_date).isAfter(moment().subtract(item.veri_gonderme_suresi + 600, "second"))
                    const returnItem: IPageItem = {
                        name: item.device_seri_no,
                        icon: <CpuChipIcon {...icon} fill={status ? "#66bb6a" : "red"} />,
                        index: 200 + (index + 1),
                        element: <Device item={item} />,
                        onClick: () => {
                            setIsOpen(false);
                        },
                    }
                    return returnItem;
                })
                setSubNavs(tempSubNavs)
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }, [])

    const [currentPage, setCurrentPage] = useState<IPageItem>(pages[0].subNav ? pages[0].subNav[0] : pages[0]);

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (subNavs) {
            const page = localStorage.getItem("page");
            if (page && page.length < 4) {
                const index = Number.parseInt(page);
                pages.map((item) => {
                    if (item.index === index) {
                        setCurrentPage(item);
                    }
                    else if (item.subNav) {
                        item.subNav.map((item2) => {
                            if (item2.index === index) {
                                setCurrentPage(item2);
                            }
                            return null
                        })
                    }
                    return null
                })
            }
        }
    }, [subNavs])


    useEffect(() => {
        if (currentPage && subNavs) {
            localStorage.setItem("page", currentPage.index.toString());
        }
    }, [currentPage, subNavs])

    return (
        <div className="min-h-screen bg-gray-50">
            <div className="flex">
                <Sidebar
                    title="Kullanıcı Paneli"
                    setCurrentPage={setCurrentPage}
                    pages={pages}
                    currentPage={currentPage}
                    isOpen={isOpen}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }} />
                
                <div className="flex-1 min-h-screen">
                    <div className="p-4 lg:ml-80 transition-all duration-300">
                        <Header
                            isOpen={isOpen}
                            onSidebarClick={() => {
                                setIsOpen(!isOpen);
                            }}
                            currentPageName={currentPage.name}
                            currentPageIndex={currentPage.index}
                        />
                        
                        <div className="mt-6 bg-white rounded-xl shadow-sm p-6">
                            {currentPage.element}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserPanel;