import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { IConnections } from "../../../models/AdminPanel";
import { Button, Card, Typography, Tooltip } from "@material-tailwind/react";
import DeleteModal from "../../../components/DeleteModal";
import ConnectionUpdateModal from "./ConnectionUpdateModal";

// IConnectionsWithIndex interface'ini buraya da ekleyelim
interface IConnectionsWithIndex {
    id: string;
    name: string;
    type: string;
    temp: boolean;
    acc_x: boolean;
    acc_y: boolean;
    acc_z: boolean;
    gyro_x: boolean;
    gyro_y: boolean;
    gyro_z: boolean;
    mag_x: boolean;
    mag_y: boolean;
    mag_z: boolean;
    angle_x: boolean;
    angle_y: boolean;
    angle_z: boolean;
    refined_angle_x: boolean;
    refined_angle_y: boolean;
    refined_angle_z: boolean;
    deflection: boolean;
    refined_deflection: boolean;
    millis: boolean;
    battery_voltage: boolean;
    email: string;
    device_seri_no: string;
    [key: string]: any;
}

const Connections = () => {
    const [connections, setConnections] = useState<IConnectionsWithIndex[]>();

    const GetConnections = () => {
        API.getInstance().GetConnectionDeviceUser()
            .then((response) => {
                // API'den gelen veriyi IConnectionsWithIndex tipine dönüştür
                const formattedConnections = response.data.data.map((item: any) => ({
                    id: item.id || "",
                    name: item.name || "",
                    type: item.type || "",
                    temp: item.temp || false,
                    acc_x: item.acc_x || false,
                    acc_y: item.acc_y || false,
                    acc_z: item.acc_z || false,
                    gyro_x: item.gyro_x || false,
                    gyro_y: item.gyro_y || false,
                    gyro_z: item.gyro_z || false,
                    mag_x: item.mag_x || false,
                    mag_y: item.mag_y || false,
                    mag_z: item.mag_z || false,
                    angle_x: item.angle_x || false,
                    angle_y: item.angle_y || false,
                    angle_z: item.angle_z || false,
                    refined_angle_x: item.refined_angle_x || false,
                    refined_angle_y: item.refined_angle_y || false,
                    refined_angle_z: item.refined_angle_z || false,
                    deflection: item.deflection || false,
                    refined_deflection: item.refined_deflection || false,
                    millis: item.millis || false,
                    battery_voltage: item.battery_voltage || false,
                    email: item.email,
                    device_seri_no: item.device_seri_no
                }));
                setConnections(formattedConnections);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };

    useEffect(() => {
        GetConnections();
    }, []);

    const [updatedConnection, setUpdatedConnection] = useState<IConnectionsWithIndex | undefined>();
    const [deletedId, setDeletedId] = useState("");

    const DeleteConnection = () => {
        API.getInstance().DeleteConnectionDeviceUser(deletedId)
            .then((response) => {
                toast.success(response.data.message);
                GetConnections();
                setDeletedId("");
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };

    // Sütunları gruplandırma
    const columnGroups = [
        { title: "Temel Bilgiler", columns: ["ID", "E-mail", "Seri No"] },
        { title: "İvme Verileri", columns: ["Sıcaklık", "İvme X", "İvme Y", "İvme Z"] },
        { title: "Açısal Hız", columns: ["A.Hız X", "A.Hız Y", "A.Hız Z"] },
        { title: "Manyetik", columns: ["Mag X", "Mag Y", "Mag Z"] },
        { title: "Açı", columns: ["Roll", "Pitch", "Yaw"] },
        { title: "İndirgenmiş Açı", columns: ["İnd. Roll", "İnd. Pitch", "İnd. Yaw"] },
        { title: "Sapma", columns: ["Sapma", "İnd. Sapma"] },
        { title: "Diğer", columns: ["Vgs", "Batarya Volt"] },
        { title: "İşlemler", columns: ["Güncelle", "Sil"] }
    ];

    const renderStatusIcon = (value: boolean) => (
        <Tooltip content={value ? "Aktif" : "Pasif"}>
            {value ? 
                <CheckCircleIcon className="h-5 w-5 text-green-500" /> : 
                <XCircleIcon className="h-5 w-5 text-red-500" />
            }
        </Tooltip>
    );

    return (
        <div className="container mx-auto px-4 py-8">
            <Card className="w-full bg-white rounded-xl shadow-lg overflow-hidden" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {/* Header */}
                <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-8 relative overflow-hidden">
                    <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-sm"></div>
                    <div className="relative">
                        <div className="w-16 h-16 bg-white/10 rounded-xl flex items-center justify-center mb-4">
                            <i className="fas fa-link text-white text-3xl"></i>
                        </div>
                        <Typography
                            variant="h4"
                            color="white"
                            className="font-bold"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            Bağlantı Listesi
                        </Typography>
                        <Typography
                            variant="small"
                            className="text-blue-100 mt-2"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            Hangi kullanıcıların hangi cihazlara erişim olduğunu görebilirsiniz
                        </Typography>
                    </div>
                </div>

                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="w-full min-w-max table-auto text-left">
                    <thead>
                            {/* Ana Gruplar */}
                            <tr>
                                {columnGroups.map((group, index) => (
                                    <th 
                                        key={index} 
                                        colSpan={group.columns.length}
                                        className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-bold leading-none opacity-70"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {group.title}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                            {/* Alt Başlıklar */}
                            <tr>
                                {columnGroups.flatMap(group => 
                                    group.columns.map(column => (
                                        <th key={column} className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-medium leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                {column}
                                            </Typography>
                                        </th>
                                    ))
                                )}
                        </tr>
                    </thead>
                    <tbody>
                        {connections?.map((item, index) => {
                                const isLast = index === connections.length - 1;
                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                            return (
                                    <tr key={index} className="hover:bg-blue-gray-50/50 transition-colors">
                                        {/* Temel Bilgiler */}
                                        <td className={classes}>
                                            <Typography variant="small" color="blue-gray" className="font-normal" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                                {item.id}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="blue-gray" className="font-normal" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                                {item.email}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="blue-gray" className="font-normal" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                                {item.device_seri_no}
                                            </Typography>
                                        </td>
                                        
                                        {/* İvme Verileri */}
                                        <td className={classes}>{renderStatusIcon(item.temp!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.acc_x!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.acc_y!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.acc_z!)}</td>

                                        {/* Açısal Hız */}
                                        <td className={classes}>{renderStatusIcon(item.gyro_x!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.gyro_y!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.gyro_z!)}</td>

                                        {/* Manyetik */}
                                        <td className={classes}>{renderStatusIcon(item.mag_x!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.mag_y!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.mag_z!)}</td>

                                        {/* Açı */}
                                        <td className={classes}>{renderStatusIcon(item.angle_x!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.angle_y!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.angle_z!)}</td>

                                        {/* İndirgenmiş Açı */}
                                        <td className={classes}>{renderStatusIcon(item.refined_angle_x!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.refined_angle_y!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.refined_angle_z!)}</td>

                                        {/* Sapma */}
                                        <td className={classes}>{renderStatusIcon(item.deflection!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.refined_deflection!)}</td>

                                        {/* Diğer */}
                                        <td className={classes}>{renderStatusIcon(item.millis!)}</td>
                                        <td className={classes}>{renderStatusIcon(item.battery_voltage!)}</td>

                                        {/* İşlemler */}
                                        <td className={classes}>
                                            <Button
                                                size="sm"
                                                variant="text"
                                                color="green"
                                                className="flex items-center gap-2 hover:bg-green-50"
                                                onClick={() => {
                                                    const formattedItem: IConnectionsWithIndex = {
                                                        id: item.id || "",
                                                        name: item.name || "",
                                                        type: item.type || "",
                                                        temp: item.temp || false,
                                                        acc_x: item.acc_x || false,
                                                        acc_y: item.acc_y || false,
                                                        acc_z: item.acc_z || false,
                                                        gyro_x: item.gyro_x || false,
                                                        gyro_y: item.gyro_y || false,
                                                        gyro_z: item.gyro_z || false,
                                                        mag_x: item.mag_x || false,
                                                        mag_y: item.mag_y || false,
                                                        mag_z: item.mag_z || false,
                                                        angle_x: item.angle_x || false,
                                                        angle_y: item.angle_y || false,
                                                        angle_z: item.angle_z || false,
                                                        refined_angle_x: item.refined_angle_x || false,
                                                        refined_angle_y: item.refined_angle_y || false,
                                                        refined_angle_z: item.refined_angle_z || false,
                                                        deflection: item.deflection || false,
                                                        refined_deflection: item.refined_deflection || false,
                                                        millis: item.millis || false,
                                                        battery_voltage: item.battery_voltage || false,
                                                        email: item.email,
                                                        device_seri_no: item.device_seri_no
                                                    };
                                                    setUpdatedConnection(formattedItem);
                                                }}
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                <i className="fas fa-edit text-sm"></i>
                                                Güncelle
                                            </Button>
                                        </td>
                                        <td className={classes}>
                                            <Button
                                                size="sm"
                                                variant="text"
                                                color="red"
                                                className="flex items-center gap-2 hover:bg-red-50"
                                                onClick={() => setDeletedId(item.id!)}
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                <i className="fas fa-trash text-sm"></i>
                                                Sil
                                            </Button>
                                        </td>
                                </tr>
                                );
                        })}
                    </tbody>
                </table>
            </div>
            </Card>

            {deletedId !== "" && (
                <DeleteModal
                    title="Bağlantıyı Sil"
                    description="Bağlantıyı silmek istiyor musunuz?"
                    onCancel={() => setDeletedId("")}
                    onOk={DeleteConnection}
                />
            )}

            {updatedConnection && (
                <ConnectionUpdateModal
                    onUpdated={() => {
                        GetConnections();
                        setUpdatedConnection(undefined);
                    }}
                    onCancel={() => setUpdatedConnection(undefined)}
                    selectedConnection={updatedConnection}
                />
            )}
        </div>
    );
}

export default Connections;