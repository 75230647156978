import React, { FC, useState } from "react"
import { DBToDateText } from "../../../utils/DBToDateText"
import { IUserDeviceData } from "../../../models/Response";
import { Button, Card, Typography } from "@material-tailwind/react";
import Pagination from "../../../components/Pagination";
import { TablePagePerCount } from "../../../constants/Contants";

interface Props {
    deviceDatas: IUserDeviceData[]
    setDeletedDataId: (num: string) => void
}

const TableComponent: FC<Props> = (props) => {
    const { deviceDatas, setDeletedDataId } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const start = (currentPage - 1) * TablePagePerCount;
    const end = currentPage * TablePagePerCount;

    return (
        <Card className="relative w-full bg-white rounded-xl shadow-lg" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
            <div className="max-h-[600px] overflow-auto">
                <table className="w-full table-auto border-collapse bg-white text-left">
                    <thead className="sticky top-0 z-10">
                        <tr className="bg-blue-gray-50/50">
                            <th className="sticky left-0 z-20 bg-blue-gray-50/50 p-4 border-b border-blue-gray-100">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-bold leading-none opacity-70"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    İşlem
                                </Typography>
                            </th>
                            <th className="p-4 border-b border-blue-gray-100">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-bold leading-none opacity-70"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    Tarih
                                </Typography>
                            </th>
                            {deviceDatas[0].temp && (
                                <th className="p-4 border-b border-blue-gray-100">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-bold leading-none opacity-70"
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        Sıcaklık (°C)
                                    </Typography>
                                </th>
                            )}
                            {deviceDatas[0].acc_a && (
                                <th className="p-4 border-b border-blue-gray-100">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-bold leading-none opacity-70"
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        İvme A (g)
                                    </Typography>
                                </th>
                            )}
                            {deviceDatas[0].acc_x && (
                                <th className="p-4 border-b border-blue-gray-100">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-bold leading-none opacity-70"
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        İvme X (g)
                                    </Typography>
                                </th>
                            )}
                            {deviceDatas[0].acc_y && <th className="p-4 border-b border-blue-gray-100">İvme Y (g)</th>}
                            {deviceDatas[0].acc_z && <th className="p-4 border-b border-blue-gray-100">İvme Z (g)</th>}

                            {deviceDatas[0].angle_x && <th className="p-4 border-b border-blue-gray-100">Roll (°)</th>}
                            {deviceDatas[0].angle_y && <th className="p-4 border-b border-blue-gray-100">Pitch (°)</th>}
                            {deviceDatas[0].angle_z && <th className="p-4 border-b border-blue-gray-100">Yaw (°)</th>}
                            {deviceDatas[0].deflection !== undefined && <th className="p-4 border-b border-blue-gray-100">Sapma (mm)</th>}

                            {deviceDatas[0].refined_angle_x && <th className="p-4 border-b border-blue-gray-100">İnd. Roll (°)</th>}
                            {deviceDatas[0].refined_angle_y && <th className="p-4 border-b border-blue-gray-100">İnd. Pitch (°)</th>}
                            {deviceDatas[0].refined_angle_z && <th className="p-4 border-b border-blue-gray-100">İnd. Yaw (°)</th>}
                            {deviceDatas[0].refined_deflection !== undefined && <th className="p-4 border-b border-blue-gray-100">İnd.Sapma (mm)</th>}

                            {deviceDatas[0].battery_voltage && <th className="p-4 border-b border-blue-gray-100">Batarya Volt (V)</th>}
                            {deviceDatas[0].millis && <th className="p-4 border-b border-blue-gray-100">Veri Gönderim Süresi (s)</th>}
                            
                            {deviceDatas[0].gyro_x && <th className="p-4 border-b border-blue-gray-100">Açısal Hız X (°/s)</th>}
                            {deviceDatas[0].gyro_y && <th className="p-4 border-b border-blue-gray-100">Açısal Hız Y (°/s)</th>}
                            {deviceDatas[0].gyro_z && <th className="p-4 border-b border-blue-gray-100">Açısal Hız Z (°/s)</th>}
                            {deviceDatas[0].mag_x && <th className="p-4 border-b border-blue-gray-100">Manyetometre X (uT)</th>}
                            {deviceDatas[0].mag_y && <th className="p-4 border-b border-blue-gray-100">Manyetometre Y (uT)</th>}
                            {deviceDatas[0].mag_z && <th className="p-4 border-b border-blue-gray-100">Manyetometre Z (uT)</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {deviceDatas.slice(start, end).map((item, index) => (
                            <tr 
                                key={index}
                                className={`${
                                    index % 2 === 0 ? "bg-blue-gray-50/30" : ""
                                } hover:bg-blue-gray-50/60 transition-colors`}
                            >
                                <td className="sticky left-0 z-10 p-4 border-b border-blue-gray-50 bg-inherit">
                                    <Button
                                            size="sm"
                                            color="red"
                                        variant="text"
                                        className="flex items-center gap-2 px-4 py-2 rounded-lg hover:bg-red-50"
                                            onClick={() => setDeletedDataId(item.id)}
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        <i className="fas fa-trash text-sm"></i>
                                        <span className="font-medium text-sm">Sil</span>
                                    </Button>
                                </td>
                                <td className="p-4 border-b border-blue-gray-50">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        {DBToDateText(item.date)}
                                    </Typography>
                                </td>
                                {item.temp && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.temp}
                                        </Typography>
                                    </td>
                                )}
                                {item.acc_a && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.acc_a}
                                        </Typography>
                                    </td>
                                )}
                                {item.acc_x && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.acc_x}
                                        </Typography>
                                    </td>
                                )}
                                {item.acc_y && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.acc_y}
                                        </Typography>
                                    </td>
                                )}
                                {item.acc_z && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.acc_z}
                                        </Typography>
                                    </td>
                                )}
                                {item.angle_x && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.angle_x}
                                        </Typography>
                                    </td>
                                )}
                                {item.angle_y && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.angle_y}
                                        </Typography>
                                    </td>
                                )}
                                {item.angle_z && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.angle_z}
                                        </Typography>
                                    </td>
                                )}
                                {item.deflection !== undefined && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.deflection}
                                        </Typography>
                                    </td>
                                )}
                                {item.refined_angle_x && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.refined_angle_x}
                                        </Typography>
                                    </td>
                                )}
                                {item.refined_angle_y && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.refined_angle_y}
                                        </Typography>
                                    </td>
                                )}
                                {item.refined_angle_z && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.refined_angle_z}
                                        </Typography>
                                    </td>
                                )}
                                {item.refined_deflection !== undefined && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.refined_deflection}
                                        </Typography>
                                    </td>
                                )}
                                {item.battery_voltage && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.battery_voltage}
                                        </Typography>
                                    </td>
                                )}
                                {item.millis && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.millis}
                                        </Typography>
                                    </td>
                                )}
                                {item.gyro_x && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.gyro_x}
                                        </Typography>
                                    </td>
                                )}
                                {item.gyro_y && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.gyro_y}
                                        </Typography>
                                    </td>
                                )}
                                {item.gyro_z && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.gyro_z}
                                        </Typography>
                                    </td>
                                )}
                                {item.mag_x && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.mag_x}
                                        </Typography>
                                    </td>
                                )}
                                {item.mag_y && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.mag_y}
                                        </Typography>
                                    </td>
                                )}
                                {item.mag_z && (
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {item.mag_z}
                                        </Typography>
                                    </td>
                                )}
                                </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="sticky left-0 right-0 bottom-0 p-4 bg-white border-t border-blue-gray-50">
            <Pagination
                dataLength={deviceDatas.length}
                onPageChange={(page) => {
                    setCurrentPage(page);
                    }}
                />
            </div>
        </Card>
    );
}

export default TableComponent;
