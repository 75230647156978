import { Button, Radio, Typography, Card, Dialog, DialogHeader, DialogBody, DialogFooter, IconButton, Input, Select, Option } from "@material-tailwind/react"
import { FC, useState } from "react"
import toast from "react-hot-toast";
import { radioButtons } from "./Constant/ConnectionConstants";
import { IConnections } from "../../../models/AdminPanel";
import API from "../../../API/API";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";

// IConnections interface'ini extend et
interface IConnectionsWithIndex {
    id: string;
    temp: boolean;
    acc_x: boolean;
    acc_y: boolean;
    acc_z: boolean;
    gyro_x: boolean;
    gyro_y: boolean;
    gyro_z: boolean;
    mag_x: boolean;
    mag_y: boolean;
    mag_z: boolean;
    angle_x: boolean;
    angle_y: boolean;
    angle_z: boolean;
    refined_angle_x: boolean;
    refined_angle_y: boolean;
    refined_angle_z: boolean;
    deflection: boolean;
    refined_deflection: boolean;
    millis: boolean;
    battery_voltage: boolean;
    [key: string]: any;
}

interface Props {
    selectedConnection: IConnectionsWithIndex;
    onUpdated: () => void;
    onCancel: () => void;
}

const ConnectionUpdateModal: FC<Props> = (props: Props) => {
    const { selectedConnection } = props;
    const ConnectionUpdate = () => {
        const object: IConnectionsWithIndex = { 
            ...radioValues, 
            id: selectedConnection.id
        };
        API.getInstance().UpdateConnectionDeviceUser(object)
            .then((response) => {
                toast.success(response.data.message);
                props.onUpdated();
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const [radioValues, setRadioValues] = useState<IConnectionsWithIndex>({
        id: selectedConnection.id,
        temp: selectedConnection?.temp ?? false,
        acc_x: selectedConnection?.acc_x ?? false,
        acc_y: selectedConnection?.acc_y ?? false,
        acc_z: selectedConnection?.acc_z ?? false,
        gyro_x: selectedConnection?.gyro_x ?? false,
        gyro_y: selectedConnection?.gyro_y ?? false,
        gyro_z: selectedConnection?.gyro_z ?? false,
        mag_x: selectedConnection?.mag_x ?? false,
        mag_y: selectedConnection?.mag_y ?? false,
        mag_z: selectedConnection?.mag_z ?? false,
        angle_x: selectedConnection?.angle_x ?? false,
        angle_y: selectedConnection?.angle_y ?? false,
        angle_z: selectedConnection?.angle_z ?? false,
        refined_angle_x: selectedConnection?.refined_angle_x ?? false,
        refined_angle_y: selectedConnection?.refined_angle_y ?? false,
        refined_angle_z: selectedConnection?.refined_angle_z ?? false,
        deflection: selectedConnection?.deflection ?? false,
        refined_deflection: selectedConnection?.refined_deflection ?? false,
        millis: selectedConnection?.millis ?? false,
        battery_voltage: selectedConnection?.battery_voltage ?? false
    });

    // Bağlantı türleri için options
    const connectionTypes = [
        { value: "user", label: "Kullanıcı" },
        { value: "admin", label: "Yönetici" },
        { value: "viewer", label: "Görüntüleyici" }
    ];

    const dataGroups = [
        {
            title: "İvme Grubu",
            items: [
                { type: "acc_x", text: "İvme X" },
                { type: "acc_y", text: "İvme Y" },
                { type: "acc_z", text: "İvme Z" },
            ]
        },
        {
            title: "Eğim Grubu",
            items: [
                { type: "angle_x", text: "Roll" },
                { type: "angle_y", text: "Pitch" },
                { type: "angle_z", text: "Yaw" },
                { type: "deflection", text: "Sapma" },
            ]
        },
        {
            title: "İndirgenmiş Eğim Grubu",
            items: [
                { type: "refined_angle_x", text: "İndirgenmiş Roll" },
                { type: "refined_angle_y", text: "İndirgenmiş Pitch" },
                { type: "refined_angle_z", text: "İndirgenmiş Yaw" },
                { type: "refined_deflection", text: "İndirgenmiş Sapma" },
            ]
        },
        {
            title: "Gyro Grubu",
            items: [
                { type: "gyro_x", text: "Gyro X" },
                { type: "gyro_y", text: "Gyro Y" },
                { type: "gyro_z", text: "Gyro Z" },
            ]
        },
        {
            title: "Manyetik Grubu",
            items: [
                { type: "mag_x", text: "Mag X" },
                { type: "mag_y", text: "Mag Y" },
                { type: "mag_z", text: "Mag Z" },
            ]
        },
        {
            title: "Diğer Grup",
            items: [
                { type: "temp", text: "Sıcaklık" },
                { type: "millis", text: "Veri G. Süresi" },
                { type: "battery_voltage", text: "Batarya" },
            ]
        }
    ];

    return (
        <>
            <Dialog
                open={true}
                handler={() => {}}
                className="max-h-[90vh] overflow-y-auto p-2 min-w-[90%] md:min-w-[40rem]"
                placeholder=""
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
            >
                <DialogHeader 
                    placeholder="" 
                    className="justify-between"
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                >
                    <Typography 
                        placeholder="" 
                        variant="h5" 
                        color="blue-gray" 
                        className="select-none"
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                    >
                        Bağlantı Güncelle
                    </Typography>
                    <IconButton
                        variant="text"
                        color="blue-gray"
                        onClick={props.onCancel}
                        className="rounded-full"
                        placeholder=""
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                    >
                        <XMarkIcon className="w-6 h-6" />
                    </IconButton>
                </DialogHeader>
                <DialogBody 
                    placeholder="" 
                    className="overflow-y-auto px-2 md:px-4"
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                >
                    <div className="space-y-4">
                        {dataGroups.map((group, groupIndex) => (
                            <Card
                                key={groupIndex}
                                className="p-4 shadow-md hover:shadow-lg transition-shadow duration-300"
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                            >
                                <Typography
                                    variant="h6"
                                    color="blue-gray"
                                    className="mb-4 font-bold border-b pb-2"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    {group.title}
                                </Typography>
                                <div className="space-y-4">
                                    {group.items.map((item, itemIndex) => {
                                        const checked = radioValues[item.type];
                                        return (
                                            <div key={itemIndex} className="bg-blue-gray-50/30 p-3 rounded-lg">
                                                <Typography
                                                    variant="small"
                                                    className="font-medium mb-2 text-blue-gray-700"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    {item.text}
                                                </Typography>
                                                <div className="flex gap-4">
                                                    <Radio
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                        labelProps={{ className: "text-sm" }}
                                                        onChange={() => setRadioValues({ ...radioValues, [item.type]: true })}
                                                        name={`Radio-${item.type}`}
                                                        color="green"
                                                        label="Görebilir"
                                                        crossOrigin="anonymous"
                                                        defaultChecked={checked}
                                                    />
                                                    <Radio
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                        labelProps={{ className: "text-sm" }}
                                                        onChange={() => setRadioValues({ ...radioValues, [item.type]: false })}
                                                        name={`Radio-${item.type}`}
                                                        color="red"
                                                        label="Göremez"
                                                        crossOrigin="anonymous"
                                                        defaultChecked={!checked}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Card>
                        ))}
                    </div>
                </DialogBody>
                <DialogFooter 
                    placeholder="" 
                    className="justify-end gap-2 p-4"
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                >
                    <Button
                        variant="outlined"
                        color="red"
                        onClick={props.onCancel}
                        className="flex items-center gap-2"
                        placeholder=""
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                    >
                        <XMarkIcon className="w-4 h-4" /> İptal
                    </Button>
                    <Button
                        variant="gradient"
                        color="blue"
                        onClick={ConnectionUpdate}
                        className="flex items-center gap-2"
                        placeholder=""
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                    >
                        <CheckIcon className="w-4 h-4" /> Güncelle
                    </Button>
                </DialogFooter>
            </Dialog>
            <div className="fixed inset-0 z-40 bg-black/50 backdrop-blur-sm"></div>
        </>
    )
}

export default ConnectionUpdateModal;