import { Button, Input, Typography, Card } from "@material-tailwind/react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../API/API";
import { IUpdatePassword } from "../../models/Response";

interface Props {
    onUpdated: () => void
}

const ProfilePasswordUpdate = (props: Props) => {
    const [userPassword, setUserPassword] = useState({ password: "", newPassword: "", newPasswordSecond: "" });
    const [error, setError] = useState({ password: false, newPassword: false, newPasswordSecond: false, comparePassword: false });
    const UserInfoUpdate = () => {
        if (userPassword.password.trim() === "" || userPassword.newPassword.trim() === "" || userPassword.newPasswordSecond.trim() === "" || userPassword.newPassword !== userPassword.newPasswordSecond) {
            setError({
                password: userPassword.password.trim() === "",
                newPassword: userPassword.newPassword.trim() === "",
                newPasswordSecond: userPassword.newPasswordSecond.trim() === "",
                comparePassword: userPassword.newPassword !== userPassword.newPasswordSecond
            })
        }
        else {
            const object: IUpdatePassword = {
                new_password: userPassword.newPassword,
                old_password: userPassword.password,
            }
            API.getInstance().UpdatePassword(object)
                .then((response) => {
                    toast.success("Şifreniz başarıyla güncellenmiştir.")
                    props.onUpdated();
                }).catch((error) => {
                    toast.error(error.response.data.message);
                })
        }
    }
    return (
        <div className="container mx-auto px-4 py-8">
            <Card className="w-full max-w-xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {/* Header Section */}
                <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-8 text-center relative">
                    <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-sm"></div>
                    <div className="relative">
                        <div className="w-16 h-16 bg-white/10 rounded-xl mx-auto flex items-center justify-center mb-4">
                            <i className="fas fa-lock text-white text-3xl"></i>
                        </div>
                        <Typography 
                            variant="h4" 
                            color="white" 
                            className="font-bold"
                            placeholder="" 
                            onPointerEnterCapture={() => {}} 
                            onPointerLeaveCapture={() => {}}
                        >
                            Şifre Güncelleme
                        </Typography>
                    </div>
                </div>

                {/* Form Section */}
                <div className="px-8 py-8">
                    <div className="space-y-6">
                        <div>
                            <Input
                                type="password"
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                error={error.password}
                                crossOrigin="anonymous"
                                value={userPassword.password}
                                onChange={(event) => {
                                    setUserPassword({ ...userPassword, password: event.target.value })
                                    setError({ ...error, password: event.target.value.trim() === "" })
                                }}
                                label="Mevcut Şifreniz"
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-key text-blue-gray-500/80"></i>}
                            />
                        </div>
                        <div>
                            <Input
                                type="password"
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                error={error.newPassword}
                                crossOrigin="anonymous"
                                value={userPassword.newPassword}
                                onChange={(event) => {
                                    setUserPassword({ ...userPassword, newPassword: event.target.value })
                                    setError({ ...error, newPassword: event.target.value.trim() === "" })
                                }}
                                label="Yeni Şifreniz"
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-lock text-blue-gray-500/80"></i>}
                            />
                        </div>
                        <div>
                            <Input
                                type="password"
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                error={error.newPasswordSecond}
                                crossOrigin="anonymous"
                                value={userPassword.newPasswordSecond}
                                onChange={(event) => {
                                    setUserPassword({ ...userPassword, newPasswordSecond: event.target.value })
                                    setError({ ...error, newPasswordSecond: event.target.value.trim() === "" })
                                }}
                                label="Yeni Şifreniz (Tekrar)"
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-lock text-blue-gray-500/80"></i>}
                            />
                            {error.comparePassword && (
                                <Typography 
                                    variant="small" 
                                    color="red" 
                                    className="flex items-center gap-1 mt-2"
                                    placeholder="" 
                                    onPointerEnterCapture={() => {}} 
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-exclamation-circle"></i>
                                    Yeni şifreleriniz birbirinden farklıdır.
                                </Typography>
                            )}
                        </div>
                        <div className="flex justify-center pt-4">
                            <Button
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                color="green"
                                onClick={UserInfoUpdate}
                                className="flex items-center gap-3 px-6 bg-gradient-to-r from-green-500 to-green-600 shadow-lg hover:shadow-green-500/30 transition-all duration-300"
                                size="lg"
                            >
                                <i className="fas fa-check text-lg"></i>
                                Güncelle
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default ProfilePasswordUpdate;