import { Button, Input, Progress, Select, Typography, Option } from "@material-tailwind/react";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { API_URL } from "../../../common/AppSettings";
import { IDevice } from "../../../models/AdminPanel";
import { IPhotoUploadResponse } from "../../../models/Response";
import { RandomPasswordGenerate } from "../../../utils/RandomPasswordGenerate";
interface Props {
    onAdded: () => void
}
const DeviceAdd = (props: Props) => {
    const [device, setDevice] = useState<IDevice>({ gyro_esik: 0.001, veri_gonderme_suresi: 50, o_sayisi: 1, device_seri_no: "", device_name: "", device_password: "", coefficient_x_auto: true, coefficient_y_auto: true, coefficient_z_auto: true, updated_date: "", is_statik: "1" });
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [error, setError] = useState({ deviceSeriNo: false, deviceName: false, devicePassword: false, coefficient_x: false, coefficient_y: false, coefficient_z: false });
    const CreateDevice = () => {
        if (device.device_seri_no.trim() === "" ||
            device.device_name.trim() === "" ||
            device.device_password.trim() === "" ||
            (!device.coefficient_x_auto && device.coefficient_x === undefined) ||
            (!device.coefficient_y_auto && device.coefficient_y === undefined) ||
            (!device.coefficient_z_auto && device.coefficient_z === undefined)

        ) {
            setError({
                deviceSeriNo: device.device_seri_no.trim() === "",
                deviceName: device.device_name.trim() === "",
                devicePassword: device.device_password.trim() === "",
                coefficient_x: (!device.coefficient_x_auto && device.coefficient_x === undefined),
                coefficient_y: (!device.coefficient_y_auto && device.coefficient_y === undefined),
                coefficient_z: (!device.coefficient_z_auto && device.coefficient_z === undefined)
            })
        }
        else {
            setError({ deviceSeriNo: false, deviceName: false, devicePassword: false, coefficient_x: false, coefficient_y: false, coefficient_z: false })
            setButtonDisabled(true);
            API.getInstance().CreateDevice(device)
                .then((response) => {
                    toast.success(response.data.message);
                    props.onAdded();
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                })
                .finally(() => {
                    setButtonDisabled(false);
                })
        }
    }
    const fileUploadRef = useRef<any>();
    const [progressVal, setProgressVal] = useState(100);
    const [file, setFile] = useState();
    const UpdateOrCreateDeviceFile = async () => {
        const formData = new FormData();
        formData.append("pdf", file!);
        const URL = API_URL + "/CreateDeviceFileUpload.php";
        axios.post<IPhotoUploadResponse>(URL, formData, {
            headers: {
                'Accept-Language': 'tr',
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            , onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setProgressVal(percent);
            }
        }).then((response) => {
            toast.success(response.data.message)
            setProgressVal(100);
            setDevice({ ...device, device_pdf_url: response.data.url })
        }).catch((response) => {
            if (response?.response?.data?.message) {
                toast.error(response?.response?.data?.message)
            }
            else {
                toast.error("Dosya yüklenirken hata oluştu.");
            }
            setProgressVal(100);
        })
    }
    return (
        <div className="container mx-auto px-4 py-8">
            <div className="bg-white rounded-2xl shadow-lg max-w-4xl mx-auto hover:shadow-xl transition-shadow duration-300">
                {/* Header */}
                <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-8 rounded-t-2xl relative overflow-hidden">
                    <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-sm"></div>
                    <div className="relative">
                        <div className="w-16 h-16 bg-white/10 rounded-xl flex items-center justify-center mb-4">
                            <i className="fas fa-plus-circle text-white text-3xl"></i>
                        </div>
                        <Typography
                            variant="h4"
                            color="white"
                            className="font-bold"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            Yeni Cihaz Ekle
                        </Typography>
                        <Typography
                            variant="small"
                            className="text-blue-100 mt-2"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            Yeni bir cihaz eklemek için aşağıdaki formu doldurun
                        </Typography>
                    </div>
                </div>

                {/* Content */}
                <div className="p-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {/* Left Column */}
                        <div className="space-y-6 bg-gray-50/50 p-6 rounded-xl">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-4"
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                            >
                                Temel Bilgiler
                            </Typography>
                            <div>
                                <Input
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    error={error.deviceSeriNo}
                                    crossOrigin="anonymous"
                                    value={device.device_seri_no}
                                    onChange={(event) => {
                                        setDevice({ ...device, device_seri_no: event.target.value })
                                        setError({ ...error, deviceSeriNo: event.target.value.trim() === "" })
                                    }}
                                    label="Seri No"
                                    className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                    labelProps={{
                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                    }}
                                    icon={<i className="fas fa-barcode text-blue-gray-500/80"></i>}
                                />
                            </div>

                            <div>
                                <Input
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    error={error.deviceName}
                                    crossOrigin="anonymous"
                                    value={device.device_name}
                                    onChange={(event) => {
                                        setDevice({ ...device, device_name: event.target.value })
                                        setError({ ...error, deviceName: event.target.value.trim() === "" })
                                    }}
                                    label="Cihaz Adı"
                                    className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                    labelProps={{
                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                    }}
                                    icon={<i className="fas fa-microchip text-blue-gray-500/80"></i>}
                                />
                            </div>

                            <div className="flex gap-4">
                                <div className="flex-1">
                                    <Input
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        error={error.devicePassword}
                                        crossOrigin="anonymous"
                                        value={device.device_password}
                                        onChange={(event) => {
                                            setDevice({ ...device, device_password: event.target.value })
                                            setError({ ...error, devicePassword: event.target.value.trim() === "" })
                                        }}
                                        label="Cihaz Şifre"
                                        className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                        labelProps={{
                                            className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                        }}
                                        icon={<i className="fas fa-key text-blue-gray-500/80"></i>}
                                    />
                                </div>
                                <Button
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    onClick={() => {
                                        setDevice({ ...device, device_password: RandomPasswordGenerate() })
                                        setError({ ...error, devicePassword: false })
                                    }}
                                    className="flex items-center gap-2 px-4"
                                    color="blue"
                                    variant="text"
                                >
                                    <i className="fas fa-dice"></i>
                                    Üret
                                </Button>
                            </div>

                            <div>
                                <Select
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    value={device.is_statik == "1" ? "Statik" : "Dinamik"}
                                    onChange={(item) => {
                                        if (item && item.toString() === "Statik") {
                                            setDevice({ ...device, is_statik: "1" })
                                        } else {
                                            setDevice({ ...device, is_statik: "0" })
                                        }
                                    }}
                                    label="Cihaz Türü"
                                    className="!border !border-blue-gray-100 rounded-lg"
                                    labelProps={{
                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                    }}
                                    menuProps={{ className: "p-1" }}
                                >
                                    <Option key={"device_static"} value={"Statik"} className="p-2">Statik</Option>
                                    <Option key={"device_dynamic"} value={"Dinamik"} className="p-2">Dinamik</Option>
                                </Select>
                            </div>
                        </div>

                        {/* Right Column */}
                        <div className="space-y-6 bg-blue-gray-50/30 p-6 rounded-xl">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-4"
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                            >
                                Katsayı Ayarları
                            </Typography>
                            {/* Coefficient X */}
                            <div>
                                <Select
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    value={device.coefficient_x_auto ? "Otomatik" : "Manuel"}
                                    onChange={(item) => {
                                        if (item && item.toString() === "Otomatik") {
                                            setDevice({ ...device, coefficient_x_auto: true, coefficient_x: undefined })
                                        } else {
                                            setDevice({ ...device, coefficient_x_auto: false })
                                        }
                                    }}
                                    label="Roll Katsayı"
                                    className="!border !border-blue-gray-100 rounded-lg"
                                    labelProps={{
                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                    }}
                                    menuProps={{ className: "p-1" }}
                                >
                                    <Option key={"defX0"} value={"Manuel"} className="p-2">Manuel</Option>
                                    <Option key={"defX1"} value={"Otomatik"} className="p-2">Otomatik</Option>
                                </Select>
                                {!device.coefficient_x_auto && (
                                    <Input
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        error={!device.coefficient_x}
                                        type={"number"}
                                        value={device.coefficient_x}
                                        crossOrigin="anonymous"
                                        onChange={(event) => {
                                            setDevice({ ...device, coefficient_x: (event.target.value) })
                                        }}
                                        label="Roll Katsayı Giriniz"
                                        className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg mt-2"
                                        labelProps={{
                                            className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                        }}
                                    />
                                )}
                            </div>

                            {/* Coefficient Y */}
                            <div>
                                <Select
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    value={device.coefficient_y_auto ? "Otomatik" : "Manuel"}
                                    onChange={(item) => {
                                        if (item && item.toString() === "Otomatik") {
                                            setDevice({ ...device, coefficient_y_auto: true, coefficient_y: undefined })
                                        } else {
                                            setDevice({ ...device, coefficient_y_auto: false })
                                        }
                                    }}
                                    label="Pitch Katsayı"
                                    className="!border !border-blue-gray-100 rounded-lg"
                                    labelProps={{
                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                    }}
                                    menuProps={{ className: "p-1" }}
                                >
                                    <Option key={"defY0"} value={"Manuel"} className="p-2">Manuel</Option>
                                    <Option key={"defY1"} value={"Otomatik"} className="p-2">Otomatik</Option>
                                </Select>
                                {!device.coefficient_y_auto && (
                                    <Input
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        error={!device.coefficient_y}
                                        type={"number"}
                                        value={device.coefficient_y}
                                        crossOrigin="anonymous"
                                        onChange={(event) => {
                                            setDevice({ ...device, coefficient_y: (event.target.value) })
                                        }}
                                        label="Pitch Katsayı Giriniz"
                                        className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg mt-2"
                                        labelProps={{
                                            className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                        }}
                                    />
                                )}
                            </div>

                            {/* Coefficient Z */}
                            <div>
                                <Select
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    value={device.coefficient_z_auto ? "Otomatik" : "Manuel"}
                                    onChange={(item) => {
                                        if (item && item.toString() === "Otomatik") {
                                            setDevice({ ...device, coefficient_z_auto: true, coefficient_z: undefined })
                                        } else {
                                            setDevice({ ...device, coefficient_z_auto: false })
                                        }
                                    }}
                                    label="Yaw Katsayı"
                                    className="!border !border-blue-gray-100 rounded-lg"
                                    labelProps={{
                                        className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                    }}
                                    menuProps={{ className: "p-1" }}
                                >
                                    <Option key={"defZ0"} value={"Manuel"} className="p-2">Manuel</Option>
                                    <Option key={"defZ1"} value={"Otomatik"} className="p-2">Otomatik</Option>
                                </Select>
                                {!device.coefficient_z_auto && (
                                    <Input
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        error={!device.coefficient_z}
                                        type={"number"}
                                        value={device.coefficient_z}
                                        crossOrigin="anonymous"
                                        onChange={(event) => {
                                            setDevice({ ...device, coefficient_z: (event.target.value) })
                                        }}
                                        label="Yaw Katsayı Giriniz"
                                        className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg mt-2"
                                        labelProps={{
                                            className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {/* PDF Section */}
                    <div className="mt-8 p-6 bg-gradient-to-br from-blue-50 to-blue-gray-50/30 rounded-xl border border-blue-100">
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-4 flex items-center gap-2"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            <i className="fas fa-file-pdf text-blue-500"></i>
                            PDF Dökümanı
                        </Typography>

                        <div className="space-y-4">
                            <div className="flex items-center gap-4">
                                <input
                                    ref={fileUploadRef}
                                    type="file"
                                    placeholder="Pdf yükle"
                                    name="pdf"
                                    accept="application/pdf"
                                    className="flex-1 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-medium file:bg-blue-500 file:text-white hover:file:bg-blue-600 text-gray-600 file:transition-colors file:duration-200"
                                    onChange={(e: any) => {
                                        let files = e.target.files[0];
                                        setFile(files);
                                    }}
                                />
                                <Button
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                    disabled={buttonDisabled}
                                    onClick={() => UpdateOrCreateDeviceFile()}
                                    className="flex items-center gap-2 bg-gradient-to-r from-blue-500 to-blue-600 hover:shadow-lg hover:shadow-blue-500/30 transition-all duration-300"
                                >
                                    <i className="fas fa-upload"></i>
                                    Yükle
                                </Button>
                            </div>

                            {progressVal !== 100 && (
                                <div className="relative pt-1">
                                    <div className="flex mb-2 items-center justify-between">
                                        <div>
                                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                                                Yükleniyor
                                            </span>
                                        </div>
                                        <div className="text-right">
                                            <span className="text-xs font-semibold inline-block text-blue-600">
                                                {progressVal}%
                                            </span>
                                        </div>
                                    </div>
                                    <Progress
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        value={progressVal}
                                        color="blue"
                                        className="h-1"
                                    />
                                </div>
                            )}

                            <div className="flex items-center gap-2 p-3 rounded-lg bg-white">
                                <i className={`fas fa-${device.device_pdf_url ? "check-circle text-green-500" : "exclamation-circle text-red-500"} text-lg`}></i>
                                <Typography
                                    variant="small"
                                    color={device.device_pdf_url ? "green" : "red"}
                                    className="font-medium"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    {device.device_pdf_url ? "PDF dosyası eklenmiştir." : "PDF dosyası eklenmemiştir."}
                                </Typography>
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="flex justify-center mt-8">
                        <Button
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                            color="green"
                            disabled={buttonDisabled}
                            onClick={CreateDevice}
                            className="flex items-center gap-2 px-8 py-3 bg-gradient-to-r from-green-500 to-green-600 shadow-lg hover:shadow-green-500/30 hover:-translate-y-0.5 transition-all duration-300"
                            size="lg"
                        >
                            <i className="fas fa-plus-circle text-lg"></i>
                            Cihaz Oluştur
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DeviceAdd;