import React, { FC, useEffect, useState } from "react"
import { Button, Input, Textarea, Typography, Card } from "@material-tailwind/react";
import API from "../../API/API";
import toast from "react-hot-toast";
import { EmailControl } from "../../utils/EmailControl";
import { OnlyNumber } from "../../utils/OnlyNumber";

interface Props {
}

const AboutPage: FC<Props> = (props) => {
    useEffect(() => {
        API.getInstance().GetUserInfo().then((response) => {
            setEmail(response.data.data.email)
        })
    }, []);

    const [subject, setSubject] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [detail, setDetail] = useState("");
    const [error, setError] = useState({
        email: false,
        subject: false,
        detail: false,
        phone: false
    })

    const SendForm = () => {
        if (subject?.trim().length < 1 ||
            !EmailControl(email) ||
            detail?.trim().length < 1 ||
            phone?.trim().length != 11
        ) {
            setError({
                email: !EmailControl(email),
                subject: subject?.trim().length < 1,
                detail: detail?.trim().length < 1,
                phone: phone?.trim().length != 11
            })
        }
        else {
            API.getInstance().CreateContactForm({
                subject,
                email,
                detail,
                phone
            }).then((response) => {
                toast.success(response.data.message);
                setSubject("");
                setDetail("");
                setPhone("");
            }).catch((error) => {
                toast.success(error.response.data.message || "Hata oluştu");
            })
        }
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <Card className="w-full max-w-2xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {/* Header Section */}
                <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-8 text-center relative">
                    <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-sm"></div>
                    <div className="relative">
                        <div className="w-16 h-16 bg-white/10 rounded-xl mx-auto flex items-center justify-center mb-4">
                            <i className="fas fa-envelope text-white text-3xl"></i>
                        </div>
                        <Typography 
                            variant="h4" 
                            color="white" 
                            className="font-bold"
                            placeholder="" 
                            onPointerEnterCapture={() => {}} 
                            onPointerLeaveCapture={() => {}}
                        >
                            İletişim Formu
                        </Typography>
                        <Typography 
                            variant="small" 
                            className="text-blue-100 mt-2"
                            placeholder="" 
                            onPointerEnterCapture={() => {}} 
                            onPointerLeaveCapture={() => {}}
                        >
                            Bizimle iletişime geçmek için formu doldurun
                        </Typography>
                    </div>
                </div>

                {/* Form Section */}
                <div className="px-8 py-8">
                    <div className="space-y-6">
                        <div>
                            <Input
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                crossOrigin="anonymous"
                                error={error.subject}
                                value={subject}
                                label="Konu"
                                onChange={(val) => {
                                    setError({ ...error, subject: val.target.value?.trim().length < 1 })
                                    setSubject(val.target.value);
                                }}
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-heading text-blue-gray-500/80"></i>}
                            />
                            {error.subject && (
                                <Typography 
                                    variant="small" 
                                    color="red" 
                                    className="flex items-center gap-1 mt-2"
                                    placeholder="" 
                                    onPointerEnterCapture={() => {}} 
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-exclamation-circle"></i>
                                    Konu boş olamaz.
                                </Typography>
                            )}
                        </div>

                        <div>
                            <Input
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                crossOrigin="anonymous"
                                disabled
                                error={error.email}
                                value={email}
                                label="E-posta"
                                onChange={(val) => {
                                    setError({ ...error, email: !EmailControl(val.target.value) })
                                    setEmail(val.target.value);
                                }}
                                className="!border !border-blue-gray-100 bg-blue-gray-50/30 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-envelope text-blue-gray-500/80"></i>}
                            />
                            {error.email && (
                                <Typography 
                                    variant="small" 
                                    color="red" 
                                    className="flex items-center gap-1 mt-2"
                                    placeholder="" 
                                    onPointerEnterCapture={() => {}} 
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-exclamation-circle"></i>
                                    Lütfen geçerli bir e-posta adresi giriniz.
                                </Typography>
                            )}
                        </div>

                        <div>
                            <Input
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                crossOrigin="anonymous"
                                maxLength={11}
                                error={error.phone}
                                value={phone}
                                label="Telefon Numarası"
                                onChange={(val) => {
                                    setError({ ...error, phone: OnlyNumber(val.target.value?.trim()).length != 11 })
                                    setPhone(OnlyNumber(val.target.value));
                                }}
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-phone text-blue-gray-500/80"></i>}
                            />
                            {error.phone && (
                                <Typography 
                                    variant="small" 
                                    color="red" 
                                    className="flex items-center gap-1 mt-2"
                                    placeholder="" 
                                    onPointerEnterCapture={() => {}} 
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-exclamation-circle"></i>
                                    Telefon numarası formatı 0545XXXXXXX şeklinde olmalıdır.
                                </Typography>
                            )}
                        </div>

                        <div>
                            <Textarea
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                error={error.detail}
                                value={detail}
                                label="Mesajınız"
                                onChange={(val) => {
                                    setError({ ...error, detail: val.target.value?.trim().length < 1 })
                                    setDetail(val.target.value);
                                }}
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg min-h-[120px]"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                            />
                            {error.detail && (
                                <Typography 
                                    variant="small" 
                                    color="red" 
                                    className="flex items-center gap-1 mt-2"
                                    placeholder="" 
                                    onPointerEnterCapture={() => {}} 
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-exclamation-circle"></i>
                                    Mesaj alanı boş olamaz.
                                </Typography>
                            )}
                        </div>

                        <div className="flex justify-center pt-4">
                            <Button
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                onClick={SendForm}
                                className="flex items-center gap-3 px-6 bg-gradient-to-r from-blue-500 to-blue-600 shadow-lg hover:shadow-blue-500/30 transition-all duration-300"
                                size="lg"
                            >
                                <i className="fas fa-paper-plane text-lg"></i>
                                Formu Gönder
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default AboutPage;