import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { IForms } from "../../../models/AdminPanel";
import { DBToDateText } from "../../../utils/DBToDateText";
import { Button, Typography, Card, Chip } from "@material-tailwind/react";
import DeleteModal from "../../../components/DeleteModal";
import Loading from "../../../components/Loading";

const AboutPages = () => {
    const [forms, setForms] = useState<IForms[]>()
    const [loading, setLoading] = useState(true);
    const [deleteFormId, setDeleteFormId] = useState("");

    const GetContactForms = () => {
        setLoading(true);
        API.getInstance().GetContactForms()
            .then((response) => {
                setForms(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        GetContactForms();
    }, [])

    const Read = (id: string) => {
        API.getInstance().ReadContactForm(id)
            .then((response) => {
                GetContactForms()
                toast.success(response.data.message)
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const DeleteForm = (id: string) => {
        API.getInstance().DeleteContactForm(id)
            .then((response) => {
                GetContactForms()
                toast.success(response.data.message)
                setDeleteFormId("");
            }).catch((error) => {
                toast.error(error.response.data.message);
                setDeleteFormId("");
            })
    };

    return (
        <div className="container mx-auto px-4 py-8">
            {loading ? (
                <div className="flex items-center justify-center min-h-[60vh]">
                    <Loading />
                </div>
            ) : !forms || forms.length === 0 ? (
                <Card className="p-8 text-center bg-white rounded-xl shadow-lg" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                    <div className="w-16 h-16 mx-auto mb-4 bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl flex items-center justify-center">
                        <i className="fas fa-inbox text-blue-500 text-2xl"></i>
                    </div>
                    <Typography 
                        placeholder="" 
                        variant="h5" 
                        color="blue-gray"
                        className="font-medium"
                        onPointerEnterCapture={() => {}} 
                        onPointerLeaveCapture={() => {}}
                    >
                        İletişim Formu Bulunmamaktadır
                    </Typography>
                </Card>
            ) : (
                <Card className="w-full bg-white rounded-xl shadow-lg overflow-hidden" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                    {/* Modern Gradient Header */}
                    <div className="bg-gradient-to-r from-blue-600 to-blue-400 px-8 py-10 relative overflow-hidden">
                        <div className="absolute top-0 left-0 w-full h-full bg-white/5 backdrop-blur-sm"></div>
                        <div className="absolute -top-12 -right-12 w-40 h-40 bg-blue-500/20 rounded-full blur-2xl"></div>
                        <div className="absolute -bottom-12 -left-12 w-40 h-40 bg-blue-300/20 rounded-full blur-2xl"></div>
                        <div className="relative">
                            <div className="w-16 h-16 bg-white/10 rounded-2xl flex items-center justify-center mb-4 backdrop-blur-sm">
                                <i className="fas fa-envelope text-white text-3xl"></i>
                            </div>
                            <Typography
                                variant="h3"
                                color="white"
                                className="font-bold mb-2"
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                            >
                                İletişim Formları
                            </Typography>
                            <Typography
                                variant="paragraph"
                                className="text-blue-100 max-w-3xl"
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                            >
                                Kullanıcılardan gelen iletişim formlarını görüntüleyin ve yönetin
                            </Typography>
                        </div>
                    </div>

                    {/* Enhanced Table */}
                    <div className="overflow-x-auto">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr className="bg-blue-gray-50/50">
                                    {["E-posta", "Telefon", "Konu", "Detay", "Durum", "Tarih", "İşlemler"].map((head) => (
                                        <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-semibold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {forms?.map((item, index) => {
                                    const isLast = index === forms.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={index} className="hover:bg-blue-gray-50/50 transition-colors duration-200">
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-medium"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    {item.email}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-medium"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    {item.phone}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-medium"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    {item.subject}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-medium max-w-xs truncate"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    {item.detail}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Chip
                                                    variant="gradient"
                                                    color={item.is_read ? "green" : "blue-gray"}
                                                    size="sm"
                                                    value={item.is_read ? "Okundu" : "Okunmadı"}
                                                    className="font-medium"
                                                    icon={
                                                        item.is_read ? 
                                                        <CheckCircleIcon className="h-4 w-4" /> : 
                                                        <XCircleIcon className="h-4 w-4" />
                                                    }
                                                />
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-medium"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    {DBToDateText(item.created_date)}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex items-center gap-3">
                                                    <Button
                                                        size="sm"
                                                        variant="text"
                                                        color="blue"
                                                        className="flex items-center gap-2 hover:bg-blue-50/80 transition-colors"
                                                        onClick={() => Read(item.id)}
                                                        placeholder=""
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                    >
                                                        <i className="fas fa-eye text-sm"></i>
                                                        Oku
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        variant="text"
                                                        color="red"
                                                        className="flex items-center gap-2 hover:bg-red-50/80 transition-colors"
                                                        onClick={() => setDeleteFormId(item.id)}
                                                        placeholder=""
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                    >
                                                        <i className="fas fa-trash text-sm"></i>
                                                        Sil
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Card>
            )}

            {deleteFormId !== "" && (
                <DeleteModal
                    title="İletişim Formunu Sil"
                    description="Seçmiş olduğunuz iletişim formunu silmek istediğinize emin misiniz?"
                    onCancel={() => setDeleteFormId("")}
                    onOk={() => DeleteForm(deleteFormId)}
                />
            )}
        </div>
    );
}

export default AboutPages;