import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import API from '../API/API';
import Loading from '../components/Loading';
import { IRole } from '../models/User';
import logo from '../resources/images/logo.png';
import background from '../resources/images/background.png';
import { Card, Input, Button, Typography } from "@material-tailwind/react";

interface Props {
  onLogin: (role: IRole, token: string) => void;
}

const LoginScreen = (props: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);

  const Login = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoginLoading(true);
    API.getInstance()
      .Login(email, password)
      .then((response) => {
        localStorage.setItem('token', response.data.data.token);
        const role = response.data.data.role;
        if (role === 'ADMIN') {
          localStorage.setItem('page', '5');
        } else {
          localStorage.setItem('page', '1');
        }
        toast.success('Giriş başarılı.');
        props.onLogin(response.data.data.role, response.data.data.token);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };

  return (
    <>
      {loginLoading && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center">
          <Loading />
        </div>
      )}
      <div
        className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 relative"
        style={{ 
          backgroundImage: `url(${background})`, 
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        {/* Overlay with blur effect */}
        <div className="absolute inset-0 bg-black/20 backdrop-blur-[2px]"></div>

        <Card className="w-full max-w-md p-8 relative bg-white/95 backdrop-blur-xl shadow-2xl" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
          {/* Logo Section */}
          <div className="flex flex-col items-center justify-center mb-8">
            <div className="w-40 h-40 rounded-full bg-white p-4 shadow-lg mb-6">
              <img 
                className="h-full w-full object-contain" 
                src={logo} 
                alt="Logo" 
              />
            </div>
            <Typography
              variant="h4"
              color="blue-gray"
              className="text-center font-bold"
              placeholder=""
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            >
              Yapılar için Otomatik Deformasyon İzleme Merkezi
            </Typography>
          </div>

          {/* Form Section */}
          <form onSubmit={Login} className="mt-8 space-y-6">
            <div className="space-y-4">
              <div>
                <Input
                  type="email"
                  label="E-posta Adresi"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trim())}
                  className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                  labelProps={{
                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                  }}
                  required
                  crossOrigin="anonymous"
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  icon={<i className="fas fa-envelope text-blue-gray-500/80"></i>}
                />
              </div>
              <div>
                <Input
                  type="password"
                  label="Şifre"
                  size="lg"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                  labelProps={{
                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                  }}
                  required
                  crossOrigin="anonymous"
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                  icon={<i className="fas fa-lock text-blue-gray-500/80"></i>}
                />
              </div>
            </div>

            <Button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-500 to-blue-600 hover:shadow-lg hover:shadow-blue-500/30 transition-all duration-300"
              size="lg"
              placeholder=""
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            >
              <span className="flex items-center justify-center gap-2">
                <i className="fas fa-sign-in-alt"></i>
                Giriş Yap
              </span>
            </Button>
          </form>
        </Card>
      </div>
    </>
  );
};

export default LoginScreen;
