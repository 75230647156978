import { Avatar, Typography, Card } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../API/API";
import Loading from "../../components/Loading";
import { IUserInfo } from "../../models/Response";
import { DBToDateText } from "../../utils/DBToDateText";

const Profile = () => {
    const [userInfo, setUserInfo] = useState<IUserInfo>()
    useEffect(() => {
        API.getInstance().GetUserInfo()
            .then((response) => {
                setUserInfo(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }, [])
    return (
        <>
            {userInfo ? (
                <div className="container mx-auto px-4 py-8">
                    <Card className="w-full max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                        {/* Header with gradient background */}
                        <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-12 text-center relative">
                            <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-sm"></div>
                            <div className="relative">
                                <Avatar 
                                    onPointerLeaveCapture={() => {}} 
                                    onPointerEnterCapture={() => {}} 
                                    placeholder="" 
                                    src={userInfo.profile_photo_url || "https://www.material-tailwind.com/img/face-2.jpg"} 
                                    alt="avatar" 
                                    size="xxl"
                                    className="ring-4 ring-white/60 shadow-xl mx-auto mb-4 h-32 w-32 object-cover"
                                />
                                <Typography 
                                    placeholder="" 
                                    onPointerEnterCapture={() => {}} 
                                    onPointerLeaveCapture={() => {}} 
                                    variant="h3" 
                                    color="white" 
                                    className="font-bold mb-2"
                                >
                                    {`${userInfo.name} ${userInfo.surname}`}
                                </Typography>
                                <Typography 
                                    placeholder="" 
                                    onPointerEnterCapture={() => {}} 
                                    onPointerLeaveCapture={() => {}} 
                                    variant="h5" 
                                    className="text-blue-100 font-medium"
                                >
                                    {userInfo.email}
                                </Typography>
                            </div>
                        </div>

                        {/* Info Section */}
                        <div className="px-8 py-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {/* User ID Info */}
                                <div className="flex items-center gap-4 bg-gray-50/80 rounded-xl p-4 hover:bg-gray-50 transition-colors">
                                    <div className="p-3 bg-gray-700 rounded-lg">
                                        <i className="fas fa-fingerprint text-xl text-white"></i>
                                    </div>
                                    <div>
                                        <Typography 
                                            placeholder="" 
                                            onPointerEnterCapture={() => {}} 
                                            onPointerLeaveCapture={() => {}} 
                                            variant="small" 
                                            className="text-blue-gray-600 font-medium"
                                        >
                                            Kullanıcı ID
                                        </Typography>
                                        <Typography 
                                            placeholder="" 
                                            onPointerEnterCapture={() => {}} 
                                            onPointerLeaveCapture={() => {}} 
                                            variant="h6" 
                                            className="text-gray-700 font-bold"
                                        >
                                            {userInfo.user_id}
                                        </Typography>
                                    </div>
                                </div>

                                {/* Role Info */}
                                <div className="flex items-center gap-4 bg-blue-50/50 rounded-xl p-4 hover:bg-blue-50 transition-colors">
                                    <div className="p-3 bg-blue-500 rounded-lg">
                                        <i className="fas fa-user-shield text-xl text-white"></i>
                                    </div>
                                    <div>
                                        <Typography 
                                            placeholder="" 
                                            onPointerEnterCapture={() => {}} 
                                            onPointerLeaveCapture={() => {}} 
                                            variant="small" 
                                            className="text-blue-gray-600 font-medium"
                                        >
                                            Kullanıcı Rolü
                                        </Typography>
                                        <Typography 
                                            placeholder="" 
                                            onPointerEnterCapture={() => {}} 
                                            onPointerLeaveCapture={() => {}} 
                                            variant="h6" 
                                            className="text-blue-500 font-bold"
                                        >
                                            {userInfo.role}
                                        </Typography>
                                    </div>
                                </div>

                                {/* Email Info */}
                                <div className="flex items-center gap-4 bg-purple-50/50 rounded-xl p-4 hover:bg-purple-50 transition-colors">
                                    <div className="p-3 bg-purple-500 rounded-lg">
                                        <i className="fas fa-envelope text-xl text-white"></i>
                                    </div>
                                    <div>
                                        <Typography 
                                            placeholder="" 
                                            onPointerEnterCapture={() => {}} 
                                            onPointerLeaveCapture={() => {}} 
                                            variant="small" 
                                            className="text-blue-gray-600 font-medium"
                                        >
                                            E-posta Adresi
                                        </Typography>
                                        <Typography 
                                            placeholder="" 
                                            onPointerEnterCapture={() => {}} 
                                            onPointerLeaveCapture={() => {}} 
                                            variant="h6" 
                                            className="text-purple-500 font-bold"
                                        >
                                            {userInfo.email}
                                        </Typography>
                                    </div>
                                </div>

                                {/* Membership Info */}
                                {userInfo.expired_date && (
                                    <div className="flex items-center gap-4 bg-green-50/50 rounded-xl p-4 hover:bg-green-50 transition-colors">
                                        <div className="p-3 bg-green-500 rounded-lg">
                                            <i className="fas fa-calendar-check text-xl text-white"></i>
                                        </div>
                                        <div>
                                            <Typography 
                                                placeholder="" 
                                                onPointerEnterCapture={() => {}} 
                                                onPointerLeaveCapture={() => {}} 
                                                variant="small" 
                                                className="text-blue-gray-600 font-medium"
                                            >
                                                Üyelik Bitiş Tarihi
                                            </Typography>
                                            <Typography 
                                                placeholder="" 
                                                onPointerEnterCapture={() => {}} 
                                                onPointerLeaveCapture={() => {}} 
                                                variant="h6" 
                                                className="text-green-500 font-bold"
                                            >
                                                {DBToDateText(userInfo.expired_date)}
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>
                    </div>
            ) : (
                    <Loading />
            )}
        </>
    );
}

export default Profile;