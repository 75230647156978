import { LinkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import API from "../../../API/API";
import { IDevice } from "../../../models/AdminPanel";
import { Button, Card, Typography } from "@material-tailwind/react";
import DeleteModal from "../../../components/DeleteModal";
import DevicesUpdateModal from "./components/DevicesUpdateModal";
import Loading from "../../../components/Loading";
import moment from "moment";

const Devices = () => {
    const [devices, setDevices] = useState<IDevice[]>()
    const [updatedDevice, setUpdatedDevice] = useState<IDevice>()
    const [loading, setLoading] = useState(false);
    const GetDevices = () => {
        setLoading(true);
        API.getInstance().GetDevices()
            .then((response) => {
                setDevices(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        GetDevices();
    }, [])
    const [deletedId, setDeletedId] = useState("");
    const DeleteDevice = (selectedDeviceId: string) => {
        API.getInstance().DeleteDevice(selectedDeviceId)
            .then((response) => {
                toast.success(response.data.message);
                GetDevices();
                setDeletedId("")
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };

    return (
        <>
            {loading ? (
                <div className="flex items-center justify-center min-h-[60vh]">
                    <Loading />
                </div>
            ) : (
                <Card className="w-full bg-white rounded-xl shadow-lg overflow-hidden" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                    {/* Header */}
                    <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-6 py-4">
                        <Typography
                            variant="h4"
                            color="white"
                            className="font-bold flex items-center gap-3"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            <i className="fas fa-microchip"></i>
                            Cihaz Listesi
                        </Typography>
                    </div>

                    {/* Table */}
                    <div className="overflow-x-auto">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr className="bg-blue-gray-50">
                                    {["ID", "Seri No", "Cihaz Adı", "Cihaz Şifresi", "Türü", "Dökümanlar", "Güncelle", "Sil"].map((head) => (
                                        <th key={head} className="border-b border-blue-gray-100 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold leading-none opacity-70"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {devices?.map((item, index) => {
                                    const isLast = index === devices.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                    const status = moment(item.updated_date).isAfter(moment().subtract(item.veri_gonderme_suresi + 600, "second"));

                                    return (
                                        <tr key={index} className="hover:bg-blue-gray-50/50 transition-colors">
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    {item.device_id}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    {item.device_seri_no}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex items-center gap-3">
                                                    <div className={`h-3 w-3 rounded-full ${status ? "bg-green-500" : "bg-red-500"}`}></div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                        placeholder=""
                                                        onPointerEnterCapture={() => {}}
                                                        onPointerLeaveCapture={() => {}}
                                                    >
                                                        {item.device_name}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    {item.device_password}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <div className={`px-3 py-1 rounded-full text-xs font-medium inline-block ${
                                                    String(item.is_statik) === "1" || String(item.is_statik) === "true"
                                                        ? "bg-blue-100 text-blue-700" 
                                                        : "bg-purple-100 text-purple-700"
                                                }`}>
                                                    {String(item.is_statik) === "1" || String(item.is_statik) === "true" ? "Statik" : "Dinamik"}
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex items-center gap-3">
                                                    {/* PDF */}
                                                    {item.device_pdf_url && (
                                                        <Link 
                                                            to={item.device_pdf_url} 
                                                            target="_blank"
                                                            className="p-2 hover:bg-blue-50 rounded-lg inline-flex items-center gap-2 transition-colors"
                                                        >
                                                            <i className="fas fa-file-pdf text-blue-500 text-lg"></i>
                                                            <span className="text-sm text-blue-500">PDF</span>
                                                        </Link>
                                                    )}
                                                    {/* Photos */}
                                                    <div className="flex -space-x-2">
                                                        {item.device_photos?.slice(0, 3).map((photo, photoIndex) => (
                                                            <Link 
                                                                key={photoIndex}
                                                                to={photo} 
                                                                target="_blank"
                                                                className="block relative hover:z-10"
                                                            >
                                                                <img
                                                                    src={photo}
                                                                    alt={`Device ${item.device_name} photo ${photoIndex + 1}`}
                                                                    className="h-8 w-8 rounded-full object-cover border-2 border-white hover:border-blue-500 transition-colors cursor-zoom-in"
                                                                />
                                                            </Link>
                                                        ))}
                                                        {item.device_photos && item.device_photos.length > 3 && (
                                                            <div className="h-8 w-8 rounded-full bg-blue-gray-100 border-2 border-white flex items-center justify-center">
                                                                <Typography
                                                                    variant="small"
                                                                    className="text-xs font-medium text-blue-gray-600"
                                                                    placeholder=""
                                                                    onPointerEnterCapture={() => {}}
                                                                    onPointerLeaveCapture={() => {}}
                                                                >
                                                                    +{item.device_photos.length - 3}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Button
                                                    size="sm"
                                                    variant="text"
                                                    color="green"
                                                    className="flex items-center gap-2"
                                                    onClick={() => setUpdatedDevice(item)}
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    <i className="fas fa-edit text-sm"></i>
                                                    Güncelle
                                                </Button>
                                            </td>
                                            <td className={classes}>
                                                <Button
                                                    size="sm"
                                                    variant="text"
                                                    color="red"
                                                    className="flex items-center gap-2"
                                                    onClick={() => setDeletedId(item.device_id?.toString()!)}
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    <i className="fas fa-trash text-sm"></i>
                                                    Sil
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Card>
            )}

            {deletedId !== "" && (
                <DeleteModal
                    title="Cihazı Sil"
                    description="Cihazı ve cihaza ait bütün verileri silmek istiyor musunuz?"
                    onCancel={() => setDeletedId("")}
                    onOk={() => DeleteDevice(deletedId)}
                />
            )}

            {updatedDevice && (
                <DevicesUpdateModal
                    onUpdated={() => {
                        setUpdatedDevice(undefined);
                        GetDevices();
                    }}
                    onCancel={() => setUpdatedDevice(undefined)}
                    selectedDevice={updatedDevice}
                />
            )}
        </>
    );
}

export default Devices;