import { FC, useState } from "react"
import { DBToDateText } from "../../../utils/DBToDateText"
import { Button } from "@material-tailwind/react";
import Pagination from "../../../components/Pagination";
import { TablePagePerCount } from "../../../constants/Contants";
import { IAdminDeviceData } from "../../../models/Response";
interface Props {
    deviceDatas: IAdminDeviceData[]
    setDeletedDataId: (num: string) => void
}
const TableComponentAdmin: FC<Props> = (props) => {
    const { deviceDatas, setDeletedDataId } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const start = (currentPage - 1) * TablePagePerCount;
    const end = currentPage * TablePagePerCount;

    return (
        <div className="px-4 py-6">
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                {/* Table Container */}
                <div className="overflow-x-auto">
                    <table className="w-full min-w-max table-auto text-left">
                        <thead>
                            <tr className="bg-blue-gray-50">
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                    <p className="font-semibold leading-none text-blue-gray-900">İşlem</p>
                                </th>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                    <p className="font-semibold leading-none text-blue-gray-900">Tarih</p>
                                </th>
                                {deviceDatas[0].temp && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Sıcaklık (°C)</p>
                                    </th>
                                }
                                {deviceDatas[0].acc_a && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">İvme A (g)</p>
                                    </th>
                                }
                                {deviceDatas[0].acc_x && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">İvme X (g)</p>
                                    </th>
                                }
                                {deviceDatas[0].acc_y && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">İvme Y (g)</p>
                                    </th>
                                }
                                {deviceDatas[0].acc_z && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">İvme Z (g)</p>
                                    </th>
                                }
                                {deviceDatas[0].angle_x && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Roll (°)</p>
                                    </th>
                                }
                                {deviceDatas[0].angle_y && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Pitch (°)</p>
                                    </th>
                                }
                                {deviceDatas[0].angle_z && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Yaw (°)</p>
                                    </th>
                                }
                                {deviceDatas[0].deflection !== undefined && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Sapma (mm)</p>
                                    </th>
                                }
                                {deviceDatas[0].refined_angle_x && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">İnd. Roll (°)</p>
                                    </th>
                                }
                                {deviceDatas[0].refined_angle_y && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">İnd. Pitch (°)</p>
                                    </th>
                                }
                                {deviceDatas[0].refined_angle_z && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">İnd. Yaw (°)</p>
                                    </th>
                                }
                                {deviceDatas[0].refined_deflection !== undefined && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">İnd.Sapma (mm)</p>
                                    </th>
                                }
                                {deviceDatas[0].battery_voltage && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Batarya Volt (V)</p>
                                    </th>
                                }
                                {deviceDatas[0].millis && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Veri Gönderim Süresi (s)</p>
                                    </th>
                                }
                                {deviceDatas[0].gyro_x && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Açısal Hız X (°/s)</p>
                                    </th>
                                }
                                {deviceDatas[0].gyro_y && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Açısal Hız Y (°/s)</p>
                                    </th>
                                }
                                {deviceDatas[0].gyro_z && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Açısal Hız Z (°/s)</p>
                                    </th>
                                }
                                {deviceDatas[0].mag_x && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Manyetometre X (uT)</p>
                                    </th>
                                }
                                {deviceDatas[0].mag_y && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Manyetometre Y (uT)</p>
                                    </th>
                                }
                                {deviceDatas[0].mag_z && 
                                    <th className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <p className="font-semibold leading-none text-blue-gray-900">Manyetometre Z (uT)</p>
                                    </th>
                                }
                        </tr>
                        </thead>
                    <tbody>
                            {deviceDatas.slice(start, end).map((item, index) => (
                                <tr key={index} className="hover:bg-blue-gray-50/50 transition-colors duration-200">
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <Button
                                            size="sm"
                                            color="red"
                                            variant="text"
                                            className="flex items-center gap-2 px-4 py-2"
                                            onClick={() => setDeletedDataId(item.id)}
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            <i className="fas fa-trash text-sm"></i>
                                            <span>Sil</span>
                                        </Button>
                                    </td>
                                    <td className="p-4 border-b border-blue-gray-50">
                                        <p className="font-normal text-blue-gray-900">
                                            {DBToDateText(item.date)}
                                        </p>
                                    </td>
                                    {item.temp && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.temp}</p>
                                        </td>
                                    }
                                    {item.acc_a && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.acc_a}</p>
                                        </td>
                                    }
                                    {item.acc_x && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.acc_x}</p>
                                        </td>
                                    }
                                    {item.acc_y && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.acc_y}</p>
                                        </td>
                                    }
                                    {item.acc_z && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.acc_z}</p>
                                        </td>
                                    }
                                    {item.angle_x && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.angle_x}</p>
                                        </td>
                                    }
                                    {item.angle_y && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.angle_y}</p>
                                        </td>
                                    }
                                    {item.angle_z && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.angle_z}</p>
                                        </td>
                                    }
                                    {item.deflection !== undefined && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.deflection}</p>
                                        </td>
                                    }
                                    {item.refined_angle_x && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.refined_angle_x}</p>
                                        </td>
                                    }
                                    {item.refined_angle_y && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.refined_angle_y}</p>
                                        </td>
                                    }
                                    {item.refined_angle_z && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.refined_angle_z}</p>
                                        </td>
                                    }
                                    {item.refined_deflection !== undefined && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.refined_deflection}</p>
                                        </td>
                                    }
                                    {item.battery_voltage && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.battery_voltage}</p>
                                        </td>
                                    }
                                    {item.millis && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.millis}</p>
                                        </td>
                                    }
                                    {item.gyro_x && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.gyro_x}</p>
                                        </td>
                                    }
                                    {item.gyro_y && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.gyro_y}</p>
                                        </td>
                                    }
                                    {item.gyro_z && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.gyro_z}</p>
                                        </td>
                                    }
                                    {item.mag_x && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.mag_x}</p>
                                        </td>
                                    }
                                    {item.mag_y && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.mag_y}</p>
                                        </td>
                                    }
                                    {item.mag_z && 
                                        <td className="p-4 border-b border-blue-gray-50">
                                            <p className="font-normal text-blue-gray-900">{item.mag_z}</p>
                                        </td>
                                    }
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

                {/* Pagination */}
                <div className="py-4 px-6 border-t border-blue-gray-50">
            <Pagination
                dataLength={deviceDatas.length}
                onPageChange={(page) => {
                    setCurrentPage(page);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default TableComponentAdmin;
