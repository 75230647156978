import { IPageItem, IPages } from "../models/AdminPanel";
import { Button, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import SideBarItem from "./SidebarItem";

interface Props {
    item: IPages
    setCurrentPage: (item: IPageItem) => void
    currentPage: IPageItem
}

const SideBarItemCollapse = (props: Props) => {
    const { item } = props;
    const [open, setOpen] = useState(false);
    const isActive = item.index === props.currentPage.index;

    useEffect(() => {
        setTimeout(() => {
            const page = localStorage.getItem("page");
            if (page && page.length < 4) {
                const index = Number.parseInt(page);
                if (item.subNav?.findIndex((item) => item.index === index) !== -1) {
                    setOpen(true);
                }
            }
        }, 100)
    }, [props.currentPage]);

    return (
        <div className="relative">
            <Button 
                placeholder="" 
                onPointerEnterCapture={() => {}} 
                onPointerLeaveCapture={() => {}}
                onClick={() => setOpen(!open)}
                variant={isActive ? "filled" : "text"}
                color={isActive ? "blue" : "blue-gray"}
                className={`flex items-center gap-4 px-4 py-3 capitalize w-full ${
                    isActive ? 'shadow-md' : 'hover:bg-blue-gray-50'
                } transition-all duration-200`}
            >
                <div className={`${isActive ? 'text-white' : 'text-blue-gray-500'}`}>
                    {item.icon}
                </div>
                <Typography
                    placeholder=""
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                    color={isActive ? "white" : "blue-gray"}
                    className="font-medium capitalize flex-grow"
                >
                    {item.name}
                </Typography>
                <div className={`${isActive ? 'text-white' : 'text-blue-gray-500'} transition-transform duration-200 ${open ? 'rotate-180' : ''}`}>
                    <ChevronDownIcon className="w-5 h-5" />
                </div>
            </Button>
            
            {open && item.subNav && (
                <div className="pl-4 mt-2 space-y-1">
                    {item.subNav.map((subItem, index) => (
                        <div key={index} className="hover:bg-blue-gray-50/50 rounded-lg transition-colors duration-200">
                            <SideBarItem
                                subNavItem={subItem}
                                item={item}
                                setCurrentPage={() => props.setCurrentPage(subItem)}
                                currentPage={props.currentPage}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default SideBarItemCollapse;