import { Select, Option, Button, Radio, Card, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import API from "../../../API/API";
import { IConnections, IDevice, IUsers } from "../../../models/AdminPanel";
import { radioButtons } from "./Constant/ConnectionConstants";

interface Props {
    onAdded: () => void
}

const ConnectionsAdd = (props: Props) => {
    useEffect(() => {
        GetUsers();
        GetDevices();
    }, [])

    const [selectedUserId, setSelectedUserId] = useState<string>("");
    const [users, setUsers] = useState<IUsers[]>()
    const GetUsers = () => {
        API.getInstance().GetUsers()
            .then((response) => {
                setUsers(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const [devices, setDevices] = useState<IDevice[]>()
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
    const GetDevices = () => {
        API.getInstance().GetDevices()
            .then((response) => {
                setDevices(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const [radioValues, setRadioValues] = useState<IConnections>(
        {
            "temp": true,
            "acc_x": true,
            "acc_y": true,
            "acc_z": true,
            "gyro_x": true,
            "gyro_y": true,
            "gyro_z": true,
            "mag_x": true,
            "mag_y": true,
            "mag_z": true,
            "angle_x": true,
            "angle_y": true,
            "angle_z": true,
            "refined_angle_x": true,
            "refined_angle_y": true,
            "refined_angle_z": true,
            "deflection": true,
            "refined_deflection": true,
            "millis": true,
            "battery_voltage": true
        });

    const CreateConnection = () => {
        const object = Object.assign({}, radioValues, { user_id: selectedUserId, device_id: selectedDeviceId });
        API.getInstance().CreateConnectionDeviceUser(object)
            .then((response) => {
                toast.success(response.data.message);
                // GetUsers();
                setSelectedUserId("");
                setSelectedDeviceId("");
                props.onAdded();
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    };
    return (
        <div className="container mx-auto px-4 py-8">
            <Card className="w-full bg-white rounded-xl shadow-lg overflow-hidden" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {/* Header */}
                <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-8 relative overflow-hidden">
                    <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-sm"></div>
                    <div className="relative">
                        <div className="w-16 h-16 bg-white/10 rounded-xl flex items-center justify-center mb-4">
                            <i className="fas fa-link text-white text-3xl"></i>
                        </div>
                        <Typography variant="h4" color="white" className="font-bold" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                            Bağlantı Ekle
                        </Typography>
                        <Typography variant="small" className="text-blue-100 mt-2" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                            Kullanıcı ve cihaz seçerek yeni bir bağlantı oluşturun
                        </Typography>
                    </div>
                </div>

                <div className="p-8">
                    {/* User and Device Selection */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        <div className="w-full">
                            {users && (
                                <Select
                                    variant="outlined"
                                    label={users.length === 0 ? "Kullanıcı bulunamamıştır." : "Kullanıcı seçiniz"}
                                    value={selectedUserId}
                                    onChange={(value) => setSelectedUserId(value || "")}
                                    disabled={users.length === 0}
                                    className="bg-white"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    {users.map((item, index) => (
                                        <Option key={index} value={item.user_id.toString()}>
                                            {`${item.user_id} - ${item.email}`}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </div>
                        <div className="w-full">
                            {devices && (
                                <Select
                                    variant="outlined"
                                    label={devices.length === 0 ? "Cihaz bulunamamıştır." : "Cihaz seçiniz"}
                                    value={selectedDeviceId}
                                    onChange={(value) => setSelectedDeviceId(value || "")}
                                    disabled={devices.length === 0}
                                    className="bg-white"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    {devices.map((item, index) => (
                                        <Option key={index} value={item.device_id?.toString() || ""}>
                                            {`${item.device_id} - ${item.device_seri_no}`}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </div>
                    </div>

                    {/* Data Access Groups */}
                    <Typography variant="h6" color="blue-gray" className="mb-4" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                        Verilere Erişimleri Belirle
                    </Typography>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {/* İvme Grubu */}
                        <Card className="p-4 shadow-md" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                            <Typography variant="h6" color="blue-gray" className="mb-4" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                İvme Grubu
                            </Typography>
                            <div className="space-y-4">
                                {['acc_x', 'acc_y', 'acc_z'].map((type) => (
                                    <div key={type} className="flex items-center justify-between">
                                        <span className="text-sm font-medium">
                                            {type === 'acc_x' ? 'İvme X' : type === 'acc_y' ? 'İvme Y' : 'İvme Z'}
                                        </span>
                                        <div className="flex gap-2">
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="green"
                                                label="Var"
                                                defaultChecked
                                                onChange={() => setRadioValues({ ...radioValues, [type]: true })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="red"
                                                label="Yok"
                                                onChange={() => setRadioValues({ ...radioValues, [type]: false })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card>

                        {/* Eğim Grubu */}
                        <Card className="p-4 shadow-md" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                            <Typography variant="h6" color="blue-gray" className="mb-4" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                Eğim Grubu
                            </Typography>
                            <div className="space-y-4">
                                {['angle_x', 'angle_y', 'angle_z', 'deflection'].map((type) => (
                                    <div key={type} className="flex items-center justify-between">
                                        <span className="text-sm font-medium">
                                            {type === 'angle_x' ? 'Roll' : 
                                             type === 'angle_y' ? 'Pitch' : 
                                             type === 'angle_z' ? 'Yaw' : 'Sapma'}
                                        </span>
                                        <div className="flex gap-2">
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="green"
                                                label="Var"
                                                defaultChecked
                                                onChange={() => setRadioValues({ ...radioValues, [type]: true })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="red"
                                                label="Yok"
                                                onChange={() => setRadioValues({ ...radioValues, [type]: false })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card>

                        {/* İndirgenmiş Eğim Grubu */}
                        <Card className="p-4 shadow-md" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                            <Typography variant="h6" color="blue-gray" className="mb-4" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                İndirgenmiş Eğim Grubu
                            </Typography>
                            <div className="space-y-4">
                                {['refined_angle_x', 'refined_angle_y', 'refined_angle_z', 'refined_deflection'].map((type) => (
                                    <div key={type} className="flex items-center justify-between">
                                        <span className="text-sm font-medium">
                                            {type === 'refined_angle_x' ? 'İndirgenmiş Roll' : 
                                             type === 'refined_angle_y' ? 'İndirgenmiş Pitch' : 
                                             type === 'refined_angle_z' ? 'İndirgenmiş Yaw' : 'İndirgenmiş Sapma'}
                                        </span>
                                        <div className="flex gap-2">
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="green"
                                                label="Var"
                                                defaultChecked
                                                onChange={() => setRadioValues({ ...radioValues, [type]: true })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="red"
                                                label="Yok"
                                                onChange={() => setRadioValues({ ...radioValues, [type]: false })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card>

                        {/* Gyro Grubu */}
                        <Card className="p-4 shadow-md" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                            <Typography variant="h6" color="blue-gray" className="mb-4" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                Gyro Grubu
                            </Typography>
                            <div className="space-y-4">
                                {['gyro_x', 'gyro_y', 'gyro_z'].map((type) => (
                                    <div key={type} className="flex items-center justify-between">
                                        <span className="text-sm font-medium">
                                            {type === 'gyro_x' ? 'Gyro X' : 
                                             type === 'gyro_y' ? 'Gyro Y' : 'Gyro Z'}
                                        </span>
                                        <div className="flex gap-2">
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="green"
                                                label="Var"
                                                defaultChecked
                                                onChange={() => setRadioValues({ ...radioValues, [type]: true })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="red"
                                                label="Yok"
                                                onChange={() => setRadioValues({ ...radioValues, [type]: false })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card>

                        {/* Manyetik Grubu */}
                        <Card className="p-4 shadow-md" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                            <Typography variant="h6" color="blue-gray" className="mb-4" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                Manyetik Grubu
                            </Typography>
                            <div className="space-y-4">
                                {['mag_x', 'mag_y', 'mag_z'].map((type) => (
                                    <div key={type} className="flex items-center justify-between">
                                        <span className="text-sm font-medium">
                                            {type === 'mag_x' ? 'Mag X' : 
                                             type === 'mag_y' ? 'Mag Y' : 'Mag Z'}
                                        </span>
                                        <div className="flex gap-2">
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="green"
                                                label="Var"
                                                defaultChecked
                                                onChange={() => setRadioValues({ ...radioValues, [type]: true })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="red"
                                                label="Yok"
                                                onChange={() => setRadioValues({ ...radioValues, [type]: false })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card>

                        {/* Diğer Grup */}
                        <Card className="p-4 shadow-md" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                            <Typography variant="h6" color="blue-gray" className="mb-4" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                Diğer Grup
                            </Typography>
                            <div className="space-y-4">
                                {['temp', 'millis', 'battery_voltage'].map((type) => (
                                    <div key={type} className="flex items-center justify-between">
                                        <span className="text-sm font-medium">
                                            {type === 'temp' ? 'Sıcaklık' : 
                                             type === 'millis' ? 'Veri G. Süresi' : 'Batarya'}
                                        </span>
                                        <div className="flex gap-2">
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="green"
                                                label="Var"
                                                defaultChecked
                                                onChange={() => setRadioValues({ ...radioValues, [type]: true })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                            <Radio
                                                name={`Radio-${type}`}
                                                color="red"
                                                label="Yok"
                                                onChange={() => setRadioValues({ ...radioValues, [type]: false })}
                                                crossOrigin=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card>
                    </div>

                    {/* Submit Button */}
                    <div className="mt-8 flex justify-end">
                        <Button
                            size="lg"
                            className="bg-gradient-to-r from-blue-500 to-blue-600 flex items-center gap-2"
                            onClick={CreateConnection}
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            <i className="fas fa-plus text-sm"></i>
                            Bağlantı Ekle
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default ConnectionsAdd;