import { Avatar, Button, Input, Progress, Typography, Card } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../API/API";
import { IPhotoUploadResponse, IUserInfo } from "../../models/Response";
import { EmailControl } from "../../utils/EmailControl";
import { API_URL } from '../../common/AppSettings';
import axios from "axios";

interface Props {
    onUpdated: () => void
}

const ProfileEdit = (props: Props) => {
    const [progressVal, setProgressVal] = useState(100);
    const [userInfo, setUserInfo] = useState<IUserInfo>({ name: "", surname: "", email: "" });
    const GetUserInfo = () => {
        API.getInstance().GetUserInfo()
            .then((response) => {
                setUserInfo(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }
    useEffect(() => {
        GetUserInfo();
    }, [])

    const [error, setError] = useState({ name: false, surname: false, email: false });
    const UserInfoUpdate = () => {
        if (userInfo.name.trim() === "" || userInfo.surname.trim() === "" || !EmailControl(userInfo.email)) {
            setError({ name: userInfo.name.trim() === "", surname: userInfo.surname.trim() === "", email: !EmailControl(userInfo.email) })
        }
        else {
            API.getInstance().UpdateProfile(userInfo)
                .then((response) => {
                    toast.success("Profil başarıyla güncellenmiştir.")
                    props.onUpdated();
                }).catch((error) => {
                    toast.error(error.response.data.message);
                })
        }
    }

    const fileUploadRef = useRef<any>();

    const UploadOrCreateUserPhoto = async (image: any) => {
        const formData = new FormData();
        formData.append("image", image);
        const URL = API_URL + "/UpdateOrCreateUserPhoto.php";
        axios.post<IPhotoUploadResponse>(URL, formData, {
            headers: {
                'Accept-Language': 'tr',
                'Access-Control-Allow-Origin': "*",
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
            , onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setProgressVal(percent);
            }
        }).then((response) => {
            toast.success(response.data.message)
            setUserInfo({ ...userInfo, profile_photo_url: response.data.url })
            setProgressVal(100);
        }).catch((response) => {
            if (response?.response?.data?.message) {
                toast.error(response?.response?.data?.message)
            }
            else {
                toast.error("Fotoğraf yüklenirken hata oluştu.");
            }
            setProgressVal(100);
        })
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <Card className="w-full max-w-2xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {/* Header Section */}
                <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-8 py-12 text-center relative">
                    <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-sm"></div>
                    <div className="relative">
                        <div className="relative inline-block group cursor-pointer" onClick={() => fileUploadRef.current.click()}>
                            <Avatar 
                                onPointerEnterCapture={() => {}} 
                                onPointerLeaveCapture={() => {}} 
                                placeholder="" 
                                src={userInfo.profile_photo_url || "https://www.material-tailwind.com/img/face-2.jpg"} 
                                alt="avatar" 
                                size="xxl"
                                className="ring-4 ring-white/60 shadow-xl mx-auto h-32 w-32 object-cover transition-transform duration-300 group-hover:scale-105"
                            />
                            <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <i className="fas fa-camera text-white text-2xl"></i>
                            </div>
                        </div>
                        <Typography 
                            placeholder="" 
                            onPointerEnterCapture={() => {}} 
                            onPointerLeaveCapture={() => {}} 
                            variant="small"
                            className="text-white/80 mt-4 font-medium"
                        >
                            Fotoğrafı değiştirmek için tıklayın
                        </Typography>
                        {progressVal !== 100 && (
                            <div className="mt-4 max-w-xs mx-auto">
                                <Progress 
                                    placeholder="" 
                                    onPointerEnterCapture={() => {}} 
                                    onPointerLeaveCapture={() => {}} 
                                    value={progressVal}
                                    size="sm"
                                    color="blue"
                                    className="h-1 bg-white/30"
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Form Section */}
                <div className="px-8 py-8">
                    <div className="space-y-6">
                        <div>
                            <Input
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                error={error.name}
                                crossOrigin="anonymous"
                                value={userInfo.name}
                                onChange={(event) => {
                                    setUserInfo({ ...userInfo, name: event.target.value })
                                    setError({ ...error, name: event.target.value.trim() === "" })
                                }}
                                label="Ad"
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-user text-blue-gray-500/80"></i>}
                            />
                        </div>
                        <div>
                            <Input
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                error={error.surname}
                                crossOrigin="anonymous"
                                value={userInfo.surname}
                                onChange={(event) => {
                                    setUserInfo({ ...userInfo, surname: event.target.value })
                                    setError({ ...error, surname: event.target.value.trim() === "" })
                                }}
                                label="Soyad"
                                className="!border !border-blue-gray-100 focus:!border-blue-500 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-user text-blue-gray-500/80"></i>}
                            />
                        </div>
                        <div>
                            <Input
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                type="email"
                                crossOrigin="anonymous"
                                readOnly
                                value={userInfo.email}
                                label="E-posta"
                                className="!border !border-blue-gray-100 bg-blue-gray-50/30 rounded-lg"
                                labelProps={{
                                    className: "!text-blue-gray-500 font-medium before:content-none after:content-none"
                                }}
                                icon={<i className="fas fa-envelope text-blue-gray-500/80"></i>}
                            />
                        </div>
                        <div className="flex justify-center pt-4">
                            <Button
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                                placeholder=""
                                color="green"
                                onClick={UserInfoUpdate}
                                className="flex items-center gap-3 px-6 bg-gradient-to-r from-green-500 to-green-600 shadow-lg hover:shadow-green-500/30 transition-all duration-300"
                                size="lg"
                            >
                                <i className="fas fa-check text-lg"></i>
                                Güncelle
                            </Button>
                        </div>
                    </div>
                </div>

                <input
                    ref={fileUploadRef}
                    type="file"
                    className="hidden"
                    accept="image/png, image/jpeg, image/jpg, image/gif"
                    onChange={(e: any) => {
                        let files = e.target.files[0];
                        UploadOrCreateUserPhoto(files);
                    }}
                />
            </Card>
        </div>
    )
}

export default ProfileEdit;