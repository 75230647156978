import { LinkIcon } from "@heroicons/react/24/solid";
import { Button, Select, Typography, Option, Card, Dialog } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import API from "../../../API/API";
import Loading from "../../../components/Loading";
import { IDevice } from "../../../models/AdminPanel";
import { IUserDeviceData, IUserDeviceInfo } from "../../../models/Response";
import { DBToDateText } from "../../../utils/DBToDateText";
import TableComponent from "./TableComponent";
import styles from "../../../resources/styles/Th.module.css";
import DeleteModal from "../../../components/DeleteModal";
import SettingsModalUser from "../components/SettingsModalUser";
import { jsPDF } from "jspdf";
import { PDFViewer, Page, Document, Text, View } from "@react-pdf/renderer";
import PdfModal from "../../../components/PdfModal";
import ExcelExportNew from "../../AdminPanel/DeviceDatas/ExcelExportNew";
import moment from "moment";
import AccChartJS from "../chart-js/AccChartJS";
import AngleChartJS from "../chart-js/AngleChartJS";
import DefAngleChartJS from "../chart-js/DefAngleChartJS";
import GyroChartJS from "../chart-js/GyroChartJS";
import MagChartJS from "../chart-js/MagChartJS";
import MillisChartJS from "../chart-js/MillisChartJS";
import BatteryChartJS from "../chart-js/BatteryChartJS";
import CustomDate from "../../../components/CustomDate";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

interface Props {
    item: IDevice
}

let GetUserDeviceDatasAbort: AbortController = new AbortController();
let GetUserDeviceInfoAbort: AbortController = new AbortController();

const Device = (props: Props) => {
    const { item } = props;
    // Props olarak sadece device_id yollansın.
    // device_id den kullanıcıya aitse bilgileri çekelim.

    const [device, setDevice] = useState<IUserDeviceInfo>();
    const [deviceDatas, setDeviceDatas] = useState<IUserDeviceData[]>();

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const startDateFormatted = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss") : undefined;
    const endDateFormatted = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : undefined;

    const GetDeviceInfo = () => {
        if (GetUserDeviceInfoAbort) {
            GetUserDeviceInfoAbort.abort();
        }
        GetUserDeviceInfoAbort = new AbortController();
        API.getInstance().GetUserDeviceInfo(GetUserDeviceInfoAbort, item.device_id?.toString()!, timeRangeSelect.value, startDateFormatted, endDateFormatted)
            .then((response) => {
                setDevice(response.data.data);
                setDeviceHeight(response.data.data.device_height);
                setDeviceStatic(response.data.data.is_statik);
            }).catch((error) => {
                error?.response?.data?.message && toast.error(error.response.data.message);
            })
    }
    useEffect(() => {
        setDevice(undefined);
        GetDeviceInfo();
        GetUserDeviceDatas();
        setActiveTagIndex(-1);
    }, [item])

    const [deviceHeight, setDeviceHeight] = useState<number>(0);
    const [deviceStatic, setDeviceStatic] = useState<"0" | "1">("0");
    const [deletedDataId, setDeletedDataId] = useState("");
    const [loading, setLoading] = useState(false);
    const [deleteAllModal, setDeleteAllModal] = useState(false);

    const GetUserDeviceDatas = () => {
        if (GetUserDeviceDatasAbort) {
            GetUserDeviceDatasAbort.abort();
        }
        GetUserDeviceDatasAbort = new AbortController();
        setLoading(true);
        API.getInstance().GetUserDeviceDatas(GetUserDeviceDatasAbort, item.device_id?.toString()!, timeRangeSelect.value, startDateFormatted, endDateFormatted)
            .then((response) => {
                setDeviceDatas(response.data.data)
            }).catch((error) => {
                error?.response?.data?.message && toast.error(error.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
    }

    const DeleteDeviceData = () => {
        API.getInstance().DeleteUserDeviceData(deletedDataId)
            .then((response) => {
                toast.success(response.data.message);
                GetUserDeviceDatas();
            }).catch((error) => {
                error?.response?.data?.message && toast.error(error.response.data.message);
            }).finally(() => {
                setDeletedDataId("");
            })
    }
    const Refresh = () => {
        GetUserDeviceDatas();
        GetDeviceInfo();
    }
    const DeleteUserDeviceDatas = () => {
        API.getInstance().DeleteUserDeviceDatas(item.device_id?.toString()!)
            .then((response) => {
                toast.success(response.data.message);
                GetUserDeviceDatas();
                setDeleteAllModal(false);
            }).catch((error) => {
                error?.response?.data?.message && toast.error(error.response.data.message);
            }).finally(() => {
                setDeletedDataId("");
            })
    }
    const [activeTabIndex, setActiveTagIndex] = useState(-1);
    const [settingsModalShow, setSettingsModalShow] = useState(false);

    // Tablo otomatik yükseklik 
    const calculateHeight1 = () => {
        const h1 = document.getElementById("table1")?.offsetHeight
        const h2 = document.getElementById("table2")?.offsetHeight
        if (h1 && h2) {
            return Math.max(h1, h2);
        }
        return undefined;
    }
    const calculateHeight2 = () => {
        const h3 = document.getElementById("table3")?.offsetHeight
        const h4 = document.getElementById("table4")?.offsetHeight
        if (h3 && h4) {
            return Math.max(h3, h4);
        }
        return undefined;
    }

    const [tableHeight1, setTableHeight1] = useState<number | undefined>(calculateHeight1())
    const [tableHeight2, setTableHeight2] = useState<number | undefined>(calculateHeight2())

    useEffect(() => {
        setTimeout(() => {
            setTableHeight1(calculateHeight1())
            setTableHeight2(calculateHeight2())
        }, 200);
    }, [device])

    const [pdfModal, setPdfModal] = useState(false);
    const status = device?.last_date? moment(device.last_date).isAfter(moment().subtract((item.veri_gonderme_suresi * 2) + 600, "second")): false;
  
    const timeRange = [
        {
            "label": "10 Dk",
            "value": "-10 minutes"
        },
        {
            "label": "30 Dk",
            "value": "-30 minutes"
        },
        {
            "label": "1 S",
            "value": "-1 hour"
        },
        {
            "label": "12 S",
            "value": "-12 hour"
        },
        {
            "label": "1 G",
            "value": "-1 day"
        },
        {
            "label": "3 G",
            "value": "-3 day"
        },
        {
            "label": "Tarih Aralığı",
            "value": "custom"
        },
        // {
        //     "label": "Tüm Zamanlar",
        //     "value": "all"
        // },

    ]
    const [timeRangeSelect, setTimeRange] = useState<{ "label": string, "value": string }>(timeRange[0]);

    useEffect(() => {
        if (timeRangeSelect.value !== "custom" && device) {
            GetUserDeviceDatas();
            GetDeviceInfo();
        }
    }, [timeRangeSelect]);


    useEffect(() => {
        if (startDate) {
            setEndDate(null);
        }
    }, [startDate]);

    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number | null>(null);

    const handlePrevPhoto = () => {
        if (selectedPhotoIndex !== null && device?.device_photos) {
            setSelectedPhotoIndex(
                selectedPhotoIndex === 0 ? device.device_photos.length - 1 : selectedPhotoIndex - 1
            );
        }
    };

    const handleNextPhoto = () => {
        if (selectedPhotoIndex !== null && device?.device_photos) {
            setSelectedPhotoIndex(
                selectedPhotoIndex === device.device_photos.length - 1 ? 0 : selectedPhotoIndex + 1
            );
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 py-4">
            {device ? (
                <div className="container mx-auto px-4">
                    {/* Device Info Cards */}
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        {/* Basic Info Card */}
                        <Card 
                            className="bg-white rounded-xl shadow-sm"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            <div className="p-4 border-b border-blue-gray-50">
                                <Typography
                                    variant="h6"
                                    color="blue-gray"
                                    className="flex items-center gap-2 font-bold"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-info-circle text-blue-500"></i>
                                    Temel Bilgiler
                                </Typography>
                            </div>
                            <div className="p-4 space-y-3">
                                <div className="flex justify-between items-center">
                                    <span className="text-sm font-medium text-blue-gray-600">Cihaz ID</span>
                                    <span className="text-sm text-blue-gray-900">
                                        {device.device_id} ({device.is_statik == "1" ? "Statik" : "Dinamik"})
                                    </span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="text-sm font-medium text-blue-gray-600">Seri No</span>
                                    <span className="text-sm text-blue-gray-900">{device.device_seri_no}</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="text-sm font-medium text-blue-gray-600">Cihaz İsmi</span>
                                    <span className="text-sm text-blue-gray-900">{device.device_name}</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="text-sm font-medium text-blue-gray-600">Cihaz Yüksekliği</span>
                                    <span className="text-sm text-blue-gray-900">{device.device_height} mm</span>
                                </div>
                                {device.is_statik == "1" && (
                                    <div className="flex justify-between items-center">
                                        <span className="text-sm font-medium text-blue-gray-600">Veri Ortalama</span>
                                        <span className="text-sm text-blue-gray-900">
                                            {device.dynamic_type === "none" && "Seçenek Yok"}
                                            {device.dynamic_type === "hour" && "Saat"}
                                            {device.dynamic_type === "day" && "Gün"}
                                        </span>
                                    </div>
                                )}
                                {/* Veri Sayısı */}
                                {deviceDatas && deviceDatas.length > 0 && (
                                    <div className="flex justify-between items-center">
                                        <span className="text-sm font-medium text-blue-gray-600">Veri Sayısı</span>
                                        <span className="text-sm text-blue-gray-900">{deviceDatas.length}</span>
                                    </div>
                                )}
                                {device.first_date && (
                                    <div className="flex justify-between items-center">
                                        <span className="text-sm font-medium text-blue-gray-600">İlk Veri Tarihi</span>
                                        <span className="text-sm text-blue-gray-900">{DBToDateText(device.first_date)}</span>
                                    </div>
                                )}
                                {device.last_date && (
                                    <div className="flex justify-between items-center">
                                        <span className="text-sm font-medium text-blue-gray-600">Son Veri Tarihi</span>
                                        <span className="text-sm text-blue-gray-900">{DBToDateText(device.last_date)}</span>
                                    </div>
                                )}
                            </div>
                        </Card>

                        {/* Description Card */}
                        <Card 
                            className="bg-white rounded-xl shadow-sm"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            <div className="p-4 border-b border-blue-gray-50">
                                <Typography
                                    variant="h6"
                                    color="blue-gray"
                                    className="flex items-center gap-2 font-bold"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-file-alt text-blue-500"></i>
                                    Açıklama ve Fotoğraflar
                                </Typography>
                            </div>
                            <div className="p-4 space-y-4">
                                <div className="p-3 bg-blue-gray-50/30 rounded-lg">
                                    <p className="text-sm text-blue-gray-600">
                                        {device.device_description || "Açıklama girilmemiştir."}
                                    </p>
                                </div>
                                {device.device_photos && device.device_photos.length > 0 ? (
                                    <div className="flex flex-wrap gap-2">
                                        {device.device_photos.map((photo, index) => (
                                            <div 
                                                key={index}
                                                onClick={() => setSelectedPhotoIndex(index)}
                                                className="cursor-pointer hover:opacity-80 transition-opacity"
                                            >
                                                <img
                                                    src={photo}
                                                    alt={`Device photo ${index + 1}`}
                                                    className="h-16 w-16 object-cover rounded-lg border border-blue-gray-100"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="p-3 bg-blue-gray-50/30 rounded-lg">
                                        <p className="text-sm text-blue-gray-600">
                                            Cihaza ait fotoğraf bulunmamaktadır.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </Card>

                        {/* Status Card */}
                        <Card 
                            className="bg-white rounded-xl shadow-sm"
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            <div className="p-4 border-b border-blue-gray-50">
                                <Typography
                                    variant="h6"
                                    color="blue-gray"
                                    className="flex items-center gap-2 font-bold"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-chart-line text-blue-500"></i>
                                    Durum Bilgileri
                                </Typography>
                            </div>
                            <div className="p-4 space-y-3">
                                <div className={`flex items-center justify-between p-3 rounded-lg ${status ? 'bg-green-50' : 'bg-red-50'}`}>
                                    <div className="flex items-center gap-3">
                                        <div className={`w-3 h-3 rounded-full ${status ? 'bg-green-500' : 'bg-red-500'}`}></div>
                                        <span className="text-sm font-medium text-blue-gray-900">Bağlantı Durumu</span>
                                    </div>
                                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${status ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                                        {status ? 'Aktif' : 'Pasif'}
                                    </span>
                                </div>
                                {device.relay_error && (
                                    <div className="p-3 bg-yellow-50 rounded-lg flex items-center gap-3">
                                        <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                                        <div>
                                            <p className="text-sm font-medium text-blue-gray-900">Kalibrasyon Tarihi</p>
                                            <p className="text-sm text-blue-gray-600">{DBToDateText(device.relay_error_date)}</p>
                                        </div>
                                    </div>
                                )}
                                {device.sensor_error && (
                                    <div className="p-3 bg-red-50 rounded-lg flex items-center gap-3">
                                        <div className="w-3 h-3 rounded-full bg-red-500"></div>
                                        <div>
                                            <p className="text-sm font-medium text-red-700">Sensör Okuma Hatası</p>
                                            <p className="text-sm text-red-600">{DBToDateText(device.sensor_error_date)}</p>
                                        </div>
                                    </div>
                                )}
                                {(!device.relay_error && !device.sensor_error) && (
                                    <div className="p-3 bg-green-50 rounded-lg flex items-center gap-3">
                                        <div className="w-3 h-3 rounded-full bg-green-500"></div>
                                        <div>
                                            <p className="text-sm font-medium text-green-700">Cihaz Durumu</p>
                                            <p className="text-sm text-green-600">Kayıt Yok</p>
                                        </div>
                                    </div>
                                )}
                                {device.device_pdf_url && (
                                    <Link 
                                        to={device.device_pdf_url}
                                        target="_blank"
                                        className="flex items-center gap-3 p-3 bg-blue-50 rounded-lg hover:bg-blue-100 transition-all duration-200 group"
                                    >
                                        <div className="w-3 h-3 rounded-full bg-blue-500"></div>
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium text-blue-900">Kalibrasyon Belgesi</span>
                                            <span className="text-xs text-blue-600">PDF Dosyasını Görüntüle</span>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </Card>
                                </div>

                    {/* Action Buttons */}
                    <div className="flex flex-wrap gap-3 justify-center my-6">
                        <Button
                            color="blue"
                            variant="filled"
                            onClick={() => setSettingsModalShow(true)}
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                            className="flex items-center gap-2 px-6 py-3 shadow-md hover:shadow-lg transition-all"
                            size="sm"
                        >
                            <i className="fas fa-cog text-lg"></i>
                            <span className="font-medium">Ayarlar</span>
                        </Button>
                        <Button
                            color="blue-gray"
                            variant="filled"
                            onClick={() => {}}
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                            className="flex items-center gap-2 px-6 py-3 shadow-md hover:shadow-lg transition-all"
                            size="sm"
                        >
                            <i className="fas fa-file-excel text-lg"></i>
                            <span className="font-medium">Data İndir</span>
                        </Button>
                        <Button
                            color="red"
                            variant="filled"
                            onClick={() => setDeleteAllModal(true)}
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                            className="flex items-center gap-2 px-6 py-3 shadow-md hover:shadow-lg transition-all"
                            size="sm"
                        >
                            <i className="fas fa-trash text-lg"></i>
                            <span className="font-medium">Tüm Verileri Sil</span>
                        </Button>
                        <Button
                            color="green"
                            variant="filled"
                            onClick={Refresh}
                            placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                            className="flex items-center gap-2 px-6 py-3 shadow-md hover:shadow-lg transition-all"
                            size="sm"
                        >
                            <i className="fas fa-sync text-lg"></i>
                            <span className="font-medium">Verileri Yenile</span>
                        </Button>
                        </div>

                    {/* Data Controls */}
                    {device.is_statik == "0" && (
                        <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
                            <div className="flex flex-col gap-4">
                                <div>
                                    <Typography
                                        variant="h6"
                                        className="mb-4 font-semibold text-blue-gray-800 text-center flex items-center justify-center gap-2"
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        <i className="fas fa-clock text-blue-500"></i>
                                        Veri Aralığı
                                    </Typography>
                                    <div className="flex flex-wrap justify-center gap-2">
                                        {timeRange.map((item, index) => (
                                            item.value !== "custom" && (
                                                <Button
                                                    key={index}
                                                    variant={timeRangeSelect.value === item.value ? "filled" : "outlined"}
                                                    color={timeRangeSelect.value === item.value ? "blue" : "blue-gray"}
                                                    onClick={() => setTimeRange(item)}
                                                    className={`flex items-center gap-2 px-3 py-2 ${
                                                        timeRangeSelect.value === item.value 
                                                            ? 'bg-blue-500 shadow-sm hover:shadow-md hover:bg-blue-600' 
                                                            : 'hover:bg-blue-gray-50/50 border-blue-gray-200'
                                                    } transition-all duration-200`}
                                                    size="sm"
                                                    placeholder=""
                                                    onPointerEnterCapture={() => {}}
                                                    onPointerLeaveCapture={() => {}}
                                                >
                                                    <i className="fas fa-clock text-xs"></i>
                                                    <span className="text-sm font-medium">{item.label}</span>
                                                </Button>
                                            )
                                        ))}
                                        <Button
                                            variant={timeRangeSelect.value === "custom" ? "filled" : "outlined"}
                                            color={timeRangeSelect.value === "custom" ? "blue" : "blue-gray"}
                                            onClick={() => setTimeRange(timeRange.find(item => item.value === "custom")!)}
                                            className={`flex items-center gap-2 px-3 py-2 ${
                                                timeRangeSelect.value === "custom" 
                                                    ? 'bg-blue-500 shadow-sm hover:shadow-md hover:bg-blue-600' 
                                                    : 'hover:bg-blue-gray-50/50 border-blue-gray-200'
                                            } transition-all duration-200`}
                                            size="sm"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            <i className="fas fa-calendar-alt text-xs"></i>
                                            <span className="text-sm font-medium">Özel Tarih</span>
                                        </Button>
                                    </div>
                                </div>

                                {timeRangeSelect?.value === "custom" && (
                                    <div className="flex flex-wrap gap-4 items-center justify-center bg-blue-50/50 p-4 rounded-lg border border-blue-100">
                                        <div className="flex flex-wrap justify-center gap-4">
                                            <CustomDate
                                                maxDate={moment().toDate()}
                                                date={startDate}
                                                setDate={setStartDate}
                                                title="Başlangıç Tarihi"
                                            />
                                            <CustomDate
                                                minDate={startDate}
                                                date={endDate}
                                                maxDate={moment().isAfter(moment(startDate).add(3, "days")) ? moment(startDate).add(3, "days").toDate() : moment().toDate()}
                                                setDate={setEndDate}
                                                title="Bitiş Tarihi"
                                            />
                                            <Button
                                                onClick={() => {
                                                    GetUserDeviceDatas();
                                                    GetDeviceInfo();
                                                }}
                                                disabled={startDate === null || endDate === null}
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                                color="blue"
                                                className="flex items-center gap-2 px-4 py-2 shadow-sm hover:shadow-md transition-all self-end"
                                                size="sm"
                                            >
                                                <i className="fas fa-search text-sm"></i>
                                                <span className="text-sm font-medium">Getir</span>
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            </div> 
                    )}

                    {/* Data Display Section */}
                    {loading ? (
                        <Loading />
                    ) : deviceDatas && deviceDatas.length !== 0 ? (
                        <>
                            {/* Tabs */}
                            <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
                                <div className="flex flex-wrap gap-4 justify-center">
                                    {(deviceDatas[0].acc_x || deviceDatas[0].acc_y || deviceDatas[0].acc_z) && (
                                        <Button
                                            color={activeTabIndex === 0 ? "blue" : "blue-gray"}
                                            variant={activeTabIndex === 0 ? "filled" : "outlined"}
                                            onClick={() => setActiveTagIndex(0)}
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            className={`flex items-center gap-3 px-6 py-2.5 min-w-[180px] ${
                                                activeTabIndex === 0 
                                                    ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                    : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                            } transition-all duration-300`}
                                            size="sm"
                                        >
                                            <i className="fas fa-chart-line text-lg"></i>
                                            <span className="font-medium text-sm">İvme Grafiği</span>
                                        </Button>
                                    )}
                                    {(deviceDatas[0].angle_x || deviceDatas[0].angle_y || deviceDatas[0].angle_z || deviceDatas[0].deflection || (deviceDatas[0].temp)) && (
                                        <Button
                                            color={activeTabIndex === 1 ? "blue" : "blue-gray"}
                                            variant={activeTabIndex === 1 ? "filled" : "outlined"}
                                            onClick={() => setActiveTagIndex(1)}
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            className={`flex items-center gap-3 px-6 py-2.5 min-w-[180px] ${
                                                activeTabIndex === 1 
                                                    ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                    : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                            } transition-all duration-300`}
                                            size="sm"
                                        >
                                            <i className="fas fa-chart-area text-lg"></i>
                                            <span className="font-medium text-sm">Eğim Grafiği</span>
                                        </Button>
                                    )}
                                    {(deviceDatas[0].refined_angle_x || deviceDatas[0].refined_angle_y || deviceDatas[0].refined_angle_z || deviceDatas[0].refined_deflection) && (
                                        <Button
                                            color={activeTabIndex === 2 ? "blue" : "blue-gray"}
                                            variant={activeTabIndex === 2 ? "filled" : "outlined"}
                                            onClick={() => setActiveTagIndex(2)}
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            className={`flex items-center gap-3 px-6 py-2.5 min-w-[180px] ${
                                                activeTabIndex === 2 
                                                    ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                    : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                            } transition-all duration-300`}
                                            size="sm"
                                        >
                                            <i className="fas fa-chart-pie text-lg"></i>
                                            <span className="font-medium text-sm">İnd. Eğim Grafiği</span>
                                        </Button>
                                    )}
                                    {(deviceDatas[0].gyro_x || deviceDatas[0].gyro_y || deviceDatas[0].gyro_z) && (
                                        <Button
                                            color={activeTabIndex === 3 ? "blue" : "blue-gray"}
                                            variant={activeTabIndex === 3 ? "filled" : "outlined"}
                                            onClick={() => setActiveTagIndex(3)}
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            className={`flex items-center gap-3 px-6 py-2.5 min-w-[180px] ${
                                                activeTabIndex === 3 
                                                    ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                    : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                            } transition-all duration-300`}
                                            size="sm"
                                        >
                                            <i className="fas fa-tachometer-alt text-lg"></i>
                                            <span className="font-medium text-sm">A.Hız Grafiği</span>
                                        </Button>
                                    )}
                                    {(deviceDatas[0].mag_x || deviceDatas[0].mag_y || deviceDatas[0].mag_z) && (
                                        <Button
                                            color={activeTabIndex === 4 ? "blue" : "blue-gray"}
                                            variant={activeTabIndex === 4 ? "filled" : "outlined"}
                                            onClick={() => setActiveTagIndex(4)}
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            className={`flex items-center gap-3 px-6 py-2.5 min-w-[180px] ${
                                                activeTabIndex === 4 
                                                    ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                    : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                            } transition-all duration-300`}
                                            size="sm"
                                        >
                                            <i className="fas fa-magnet text-lg"></i>
                                            <span className="font-medium text-sm">Mag Grafiği</span>
                                        </Button>
                                    )}
                                    {deviceDatas[0].millis && (
                                        <Button
                                            color={activeTabIndex === 5 ? "blue" : "blue-gray"}
                                            variant={activeTabIndex === 5 ? "filled" : "outlined"}
                                            onClick={() => setActiveTagIndex(5)}
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            className={`flex items-center gap-3 px-6 py-2.5 min-w-[180px] ${
                                                activeTabIndex === 5 
                                                    ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                    : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                            } transition-all duration-300`}
                                            size="sm"
                                        >
                                            <i className="fas fa-clock text-lg"></i>
                                            <span className="font-medium text-sm">VGS Grafiği</span>
                                        </Button>
                                    )}
                                    {deviceDatas[0].battery_voltage && (
                                        <Button
                                            color={activeTabIndex === 6 ? "blue" : "blue-gray"}
                                            variant={activeTabIndex === 6 ? "filled" : "outlined"}
                                            onClick={() => setActiveTagIndex(6)}
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                            className={`flex items-center gap-3 px-6 py-2.5 min-w-[180px] ${
                                                activeTabIndex === 6 
                                                    ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                    : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                            } transition-all duration-300`}
                                            size="sm"
                                        >
                                            <i className="fas fa-battery-three-quarters text-lg"></i>
                                            <span className="font-medium text-sm">Batarya Grafiği</span>
                                        </Button>
                                    )}
                                    <Button
                                        color={activeTabIndex === 7 ? "blue" : "blue-gray"}
                                        variant={activeTabIndex === 7 ? "filled" : "outlined"}
                                        onClick={() => setActiveTagIndex(7)}
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                        className={`flex items-center gap-3 px-6 py-2.5 min-w-[180px] ${
                                            activeTabIndex === 7 
                                                ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                        } transition-all duration-300`}
                                        size="sm"
                                    >
                                        <i className="fas fa-table text-lg"></i>
                                        <span className="font-medium text-sm">Data</span>
                                    </Button>
                            </div>
                            </div>

                            {/* Tab Content */}
                            <div className="bg-white rounded-xl shadow-sm p-6">
                                {activeTabIndex === 0 ? (
                                    <AccChartJS data={deviceDatas} statik={deviceStatic} />
                                ) : activeTabIndex === 1 ? (
                                    <AngleChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                ) : activeTabIndex === 2 ? (
                                    <DefAngleChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                ) : activeTabIndex === 3 ? (
                                    <GyroChartJS data={deviceDatas} statik={deviceStatic} />
                                ) : activeTabIndex === 4 ? (
                                    <MagChartJS data={deviceDatas} statik={deviceStatic} />
                                ) : activeTabIndex === 5 ? (
                                    <MillisChartJS data={deviceDatas} statik={deviceStatic} />
                                ) : activeTabIndex === 6 ? (
                                    <BatteryChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                ) : activeTabIndex === 7 ? (
                                    <TableComponent setDeletedDataId={setDeletedDataId} deviceDatas={deviceDatas} />
                                ) : null}
                            </div>
                        </>
                    ) : (
                        <div className="bg-white rounded-xl shadow-sm p-6 text-center">
                            <Typography
                                variant="h4"
                                color="red"
                                placeholder=""
                                onPointerEnterCapture={() => {}}
                                onPointerLeaveCapture={() => {}}
                            >
                                Cihaza ait veri bulunmamaktadır.
                            </Typography>
                        </div>
                    )}

                    {/* Photo Modal */}
                    <Dialog
                        open={selectedPhotoIndex !== null}
                        handler={() => setSelectedPhotoIndex(null)}
                        size="xl"
                        placeholder=""
                        onPointerEnterCapture={() => {}}
                        onPointerLeaveCapture={() => {}}
                    >
                        {selectedPhotoIndex !== null && device?.device_photos && (
                            <div className="relative">
                                <Button
                                    color="gray"
                                    variant="text"
                                    onClick={() => setSelectedPhotoIndex(null)}
                                    className="absolute top-2 right-2 z-10 rounded-full p-2 bg-white/80 hover:bg-white"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-times"></i>
                                </Button>
                                
                                {/* Navigation Buttons */}
                                <Button
                                    color="gray"
                                    variant="text"
                                    onClick={handlePrevPhoto}
                                    className="absolute left-2 top-1/2 -translate-y-1/2 z-10 rounded-full p-3 bg-white/80 hover:bg-white"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <ChevronLeftIcon className="h-6 w-6" />
                                </Button>
                                
                                <Button
                                    color="gray"
                                    variant="text"
                                    onClick={handleNextPhoto}
                                    className="absolute right-2 top-1/2 -translate-y-1/2 z-10 rounded-full p-3 bg-white/80 hover:bg-white"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <ChevronRightIcon className="h-6 w-6" />
                                </Button>

                                <img
                                    src={device.device_photos[selectedPhotoIndex]}
                                    alt="Device photo"
                                    className="w-full h-auto max-h-[80vh] object-contain"
                                />
                                
                                {/* Photo Counter */}
                                <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-black/50 text-white px-4 py-2 rounded-full text-sm">
                                    {selectedPhotoIndex + 1} / {device.device_photos.length}
                                </div>
                            </div>
                        )}
                    </Dialog>

                    {/* Modals */}
                    {settingsModalShow && (
                        <SettingsModalUser
                            device={{
                                device_id: device.device_id,
                                device_height: device.device_height,
                                device_name: device.device_name,
                                device_seri_no: device.device_seri_no,
                                device_description: device.device_description,
                                device_photos: device.device_photos,
                                veri_gonderme_suresi: device.veri_gonderme_suresi,
                                gyro_esik: device.gyro_esik,
                                o_sayisi: device.o_sayisi,
                                is_statik: device.is_statik,
                                dynamic_type: device.dynamic_type,
                            }}
                            onCancel={() => setSettingsModalShow(false)}
                            onUpdated={() => {
                                GetDeviceInfo();
                                setSettingsModalShow(false);
                                GetUserDeviceDatas();
                            }}
                        />
                    )}

                    {deletedDataId !== "" && (
                        <DeleteModal
                            title="Veriyi Sil"
                            description="Seçmiş olduğunuz veri silinecektir. Onaylıyor musunuz?"
                            onCancel={() => setDeletedDataId("")}
                            onOk={DeleteDeviceData}
                        />
                    )}

                    {deleteAllModal && (
                        <DeleteModal
                            title="Verileri Sil"
                            description="Cihazınıza ait tüm veriler silinecektir. Onaylıyor musunuz?"
                            onCancel={() => setDeleteAllModal(false)}
                            onOk={DeleteUserDeviceDatas}
                        />
                    )}
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default Device;
