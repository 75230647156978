import { Button, Select, Option, Typography, Card } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../API/API";
import { IDevice } from "../../../models/AdminPanel";
import TableComponentAdmin from "./TableComponentAdmin";
import { IAdminDeviceData, IAdminDeviceInfo } from "../../../models/Response";
import DeleteModal from "../../../components/DeleteModal";
import { Link } from "react-router-dom";
import { LinkIcon } from "@heroicons/react/24/solid";
import { DBToDateText } from "../../../utils/DBToDateText";
import styles from "../../../resources/styles/Th.module.css";
import SettingsModal from "../components/SettingsModal";
import ExcelExportNew from "./ExcelExportNew";
import moment from "moment";
import AccChartJS from "../../UserPanel/chart-js/AccChartJS";
import AngleChartJS from "../../UserPanel/chart-js/AngleChartJS";
import DefAngleChartJS from "../../UserPanel/chart-js/DefAngleChartJS";
import GyroChartJS from "../../UserPanel/chart-js/GyroChartJS";
import MagChartJS from "../../UserPanel/chart-js/MagChartJS";
import MillisChartJS from "../../UserPanel/chart-js/MillisChartJS";
import BatteryChartJS from "../../UserPanel/chart-js/BatteryChartJS";
import CustomDate from "../../../components/CustomDate";
import Loading from "../../../components/Loading";


const DeviceDatas = () => {

    const [deviceDatas, setDeviceDatas] = useState<IAdminDeviceData[]>();
    const [devices, setDevices] = useState<IDevice[]>();
    const GetDevices = () => {
        API.getInstance().GetDevices()
            .then((response) => {
                setDevices(response.data.data);
                if (selectedDeviceId !== "") {
                    const device = response.data?.data?.find((i: any) => i?.device_id.toString() == selectedDeviceId);
                    if (device) {
                        setSelectedDevice(device);
                    }
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    useEffect(() => {
        GetDevices();
    }, [])

    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const [selectedDevice, setSelectedDevice] = useState<IDevice>();



    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const startDateFormatted = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss") : undefined;
    const endDateFormatted = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : undefined;

    const [loading, setLoading] = useState(false);
    const GetAdminDeviceDatas = () => {
        setLoading(true);
        API.getInstance().GetAdminDeviceDatas(selectedDeviceId, timeRangeSelect?.value, startDateFormatted, endDateFormatted)
            .then((response) => {
                setDeviceDatas(response.data.data);
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => setLoading(false))
    }

    const GetAdminDeviceInfo = () => {
        API.getInstance().GetAdminDeviceInfo(selectedDeviceId, timeRangeSelect?.value, startDateFormatted, endDateFormatted)
            .then((response) => {
                setDeviceInfo(response.data.data);
                setDeviceHeight(response.data.data.device_height);
                setDeviceStatic(response.data.data.is_statik);
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    const DeleteAdminDeviceData = (id: string) => {
        API.getInstance().DeleteAdminDeviceData(id)
            .then((response) => {
                toast.success(response.data.message);
                GetAdminDeviceDatas();
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => setDataItemModalId(""))
    }
    const DeleteAdminDeviceDatas = () => {
        API.getInstance().DeleteAdminDeviceDatas(selectedDeviceId)
            .then((response) => {
                toast.success(response.data.message);
                GetAdminDeviceDatas();
            }).catch((error) => {
                toast.error(error.response.data.message);
            }).finally(() => setModalShow(false))
    }

    const calculateHeight = () => {
        const h1 = document.getElementById("table1")?.offsetHeight
        const h2 = document.getElementById("table2")?.offsetHeight
        const h3 = document.getElementById("table3")?.offsetHeight
        if (h1 && h2 && h3) {
            return Math.max(h1, h2, h3);
        }
        return undefined;
    }


    const [deviceInfo, setDeviceInfo] = useState<IAdminDeviceInfo>();
    const [modalShow, setModalShow] = useState(false);
    const [settingsModalShow, setSettingsModalShow] = useState(false);
    const [dataItemModalId, setDataItemModalId] = useState("");
    const [tableHeight, setTableHeight] = useState<number | undefined>(calculateHeight())

    useEffect(() => {
        setTimeout(() => {
            setTableHeight(calculateHeight())
        }, 200);
    }, [deviceInfo])

    const [activeTabIndex, setActiveTagIndex] = useState(-1);
    const [deviceHeight, setDeviceHeight] = useState<number>(0);
    const [deviceStatic, setDeviceStatic] = useState<"0" | "1">("0");

    const Refresh = () => {
        GetAdminDeviceDatas();
        GetAdminDeviceInfo();
    }
    const status = deviceInfo?.last_date ? moment(deviceInfo.last_date).isAfter(moment().subtract((parseInt(deviceInfo.veri_gonderme_suresi) * 2) + 600, "second")) : undefined;
    const timeRange = [
        {
            "label": "Son 10 Dakika",
            "value": "-10 minutes"
        },
        {
            "label": "Son 30 Dakika",
            "value": "-30 minutes"
        },
        {
            "label": "Son 1 Saat",
            "value": "-1 hour"
        },
        {
            "label": "Son 12 Saat",
            "value": "-12 hour"
        },
        {
            "label": "Son 1 Gün",
            "value": "-1 day"
        },
        {
            "label": "Son 3 Gün",
            "value": "-3 day"
        },
        {
            "label": "Özel Tarih Aralığı",
            "value": "custom"
        },
        // {
        //     "label": "Tüm Zamanlar",
        //     "value": "all"
        // },

    ]
    const [timeRangeSelect, setTimeRange] = useState<{ "label": string, "value": string }>();

    useEffect(() => {
        if (startDate) {
            setEndDate(null);
        }
    }, [startDate]);

    // Cihaz seçildiğinde çalışacak fonksiyon
    const handleDeviceSelect = (deviceId: string) => {
        setSelectedDeviceId(deviceId);
        const device = devices?.find((i) => i.device_id?.toString() === deviceId);
        if (device) {
            setSelectedDevice(device);
            // Cihaz türü değiştiğinde tarih seçim bilgilerini sıfırla
            setTimeRange(undefined);
            setStartDate(null);
            setEndDate(null);
        }
    };

    return (
        <>
            <div className="min-h-screen bg-gray-50 py-4">
                <div className="container mx-auto px-4">
                    {/* Device Selection Section */}
                    <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
                        <Typography 
                            variant="h5" 
                            className="text-center mb-6 font-bold text-blue-gray-800 flex items-center justify-center gap-2"
                                placeholder=""
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            <i className="fas fa-microchip text-blue-500"></i>
                            Verisini görmek istediğiniz cihazı seçiniz
                        </Typography>
                        
                        <div className="flex flex-col items-center gap-4 max-w-md mx-auto">
                            {devices && (
                                <>
                                    <Select
                                        className="w-full"
                                        disabled={devices.length === 0}
                                        value={selectedDeviceId}
                                        onChange={(item) => handleDeviceSelect(item ? item.toString() : "")}
                                        label={devices.length === 0 ? "Cihaz bulunamamıştır." : "Cihaz seçiniz"}
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        {devices.map((item, index) => (
                                            <Option key={index} value={item.device_id?.toString()}>
                                                {`ID:${item.device_id} SN:${item.device_seri_no}`}
                                            </Option>
                                        ))}
                                    </Select>

                                    {selectedDevice && selectedDevice.is_statik == "0" && (
                                        <Select
                                            className="w-full"
                                        disabled={devices.length === 0}
                                        value={timeRangeSelect?.value}
                                        onChange={(item) => {
                                            setTimeRange(timeRange.find((i) => i.value === item?.toString())!);
                                        }}
                                            label="Tarih aralığı seçiniz"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            {timeRange.map((item, index) => (
                                                <Option key={index} value={item.value}>{item.label}</Option>
                                            ))}
                                    </Select>
                                    )}

                                    {selectedDevice && selectedDevice.is_statik == "0" && timeRangeSelect?.value === "custom" && (
                                        <div className="flex flex-wrap gap-4 justify-center w-full bg-blue-50/50 p-4 rounded-lg border border-blue-100">
                                    <CustomDate
                                        maxDate={moment().toDate()}
                                        date={startDate}
                                        setDate={setStartDate}
                                        title="Başlangıç Tarihi"
                                    />
                                    <CustomDate
                                        minDate={startDate}
                                        date={endDate}
                                        maxDate={moment().isAfter(moment(startDate).add(3, "days")) ? moment(startDate).add(3, "days").toDate() : moment().toDate()}
                                        setDate={setEndDate}
                                        title="Bitiş Tarihi"
                                    />
                                </div>
                                    )}

                            <Button
                                        className="w-full max-w-xs shadow-md hover:shadow-lg transition-all"
                                disabled={selectedDevice ? (selectedDevice.is_statik == "1" ? false : (timeRangeSelect && timeRangeSelect?.value == "custom" ? (startDate == null || endDate == null) : !timeRangeSelect)) : true}
                                onClick={() => {
                                    GetAdminDeviceDatas();
                                    GetAdminDeviceInfo();
                                }}
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        <span className="flex items-center gap-2">
                                            <i className="fas fa-search"></i>
                                            Bilgilerini Getir
                                        </span>
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>

                    {deviceDatas && deviceInfo && (
                        <>
                            {/* Device Info Cards */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                                {/* Basic Info Card */}
                                <Card className="bg-white rounded-xl shadow-sm" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                    <div className="p-4 border-b border-blue-gray-50">
                                        <Typography variant="h6" className="font-bold text-blue-gray-800 flex items-center gap-2" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                            <i className="fas fa-info-circle text-blue-500"></i>
                                            Temel Bilgiler
                                        </Typography>
                                    </div>
                                    <div className="p-4 space-y-3">
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm font-medium text-blue-gray-600">Cihaz ID</span>
                                            <span className="text-sm text-blue-gray-900">
                                                {deviceInfo.device_id} ({deviceInfo.is_statik == "1" ? "Statik" : "Dinamik"})
                                            </span>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm font-medium text-blue-gray-600">Seri No</span>
                                            <span className="text-sm text-blue-gray-900">{deviceInfo.device_seri_no}</span>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm font-medium text-blue-gray-600">Cihaz İsmi</span>
                                            <span className="text-sm text-blue-gray-900">{deviceInfo.device_name}</span>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm font-medium text-blue-gray-600">Cihaz Yüksekliği</span>
                                            <span className="text-sm text-blue-gray-900">{deviceInfo.device_height} mm</span>
                                        </div>
                                        {deviceDatas.length > 0 && (
                                            <div className="flex justify-between items-center">
                                                <span className="text-sm font-medium text-blue-gray-600">Veri Sayısı</span>
                                                <span className="text-sm text-blue-gray-900">{deviceDatas.length}</span>
                                            </div>
                                        )}
                                        {deviceInfo.first_date && (
                                            <div className="flex justify-between items-center">
                                                <span className="text-sm font-medium text-blue-gray-600">İlk Veri Tarihi</span>
                                                <span className="text-sm text-blue-gray-900">{DBToDateText(deviceInfo.first_date)}</span>
                                            </div>
                                        )}
                                        {deviceInfo.last_date && (
                                            <div className="flex justify-between items-center">
                                                <span className="text-sm font-medium text-blue-gray-600">Son Veri Tarihi</span>
                                                <span className="text-sm text-blue-gray-900">{DBToDateText(deviceInfo.last_date)}</span>
                </div>
                                        )}
            </div>
                                </Card>

                                {/* Description Card */}
                                <Card className="bg-white rounded-xl shadow-sm" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                    <div className="p-4 border-b border-blue-gray-50">
                                        <Typography variant="h6" className="font-bold text-blue-gray-800 flex items-center gap-2" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                            <i className="fas fa-file-alt text-blue-500"></i>
                                            Açıklama ve Fotoğraflar
                                        </Typography>
                                    </div>
                                    <div className="p-4 space-y-4">
                                        <div className="p-3 bg-blue-gray-50/30 rounded-lg">
                                            <p className="text-sm text-blue-gray-600">
                                                {deviceInfo.device_description || "Açıklama girilmemiştir."}
                                            </p>
                            </div>
                                        {deviceInfo.device_photos && deviceInfo.device_photos.length > 0 ? (
                                            <div className="flex flex-wrap gap-2">
                                                {deviceInfo.device_photos.map((photo, index) => (
                                                    <a key={index} href={photo} target="_blank" className="hover:opacity-80 transition-opacity">
                                                        <img
                                                            src={photo}
                                                            alt={`Device photo ${index + 1}`}
                                                            className="h-16 w-16 object-cover rounded-lg border border-blue-gray-100"
                                                        />
                                                    </a>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="p-3 bg-blue-gray-50/30 rounded-lg">
                                                <p className="text-sm text-blue-gray-600">
                                                    Cihaza ait fotoğraf bulunmamaktadır.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </Card>

                                {/* Status Card */}
                                <Card className="bg-white rounded-xl shadow-sm" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                    <div className="p-4 border-b border-blue-gray-50">
                                        <Typography variant="h6" className="font-bold text-blue-gray-800 flex items-center gap-2" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                            <i className="fas fa-chart-line text-blue-500"></i>
                                            Durum Bilgileri
                                        </Typography>
                                    </div>
                                    <div className="p-4 space-y-3">
                                        {status !== undefined && (
                                            <div className={`flex items-center justify-between p-3 rounded-lg ${status ? 'bg-green-50' : 'bg-red-50'}`}>
                                                <div className="flex items-center gap-3">
                                                    <div className={`w-3 h-3 rounded-full ${status ? 'bg-green-500' : 'bg-red-500'}`}></div>
                                                    <span className="text-sm font-medium text-blue-gray-900">Cihaz Statüsü</span>
                                                </div>
                                                <span className={`px-3 py-1 rounded-full text-sm font-medium ${status ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                                                    {status ? 'ONLINE' : 'OFFLINE'}
                                                </span>
                                            </div>
                                        )}
                                        
                                        {deviceInfo.relay_error && (
                                            <div className="p-3 bg-yellow-50 rounded-lg flex items-center gap-3">
                                                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                                                <div>
                                                    <p className="text-sm font-medium text-blue-gray-900">Kalibrasyon Tarihi</p>
                                                    <p className="text-sm text-blue-gray-600">{DBToDateText(deviceInfo.relay_error_date)}</p>
                                                </div>
                                            </div>
                                        )}

                                        {deviceInfo.sensor_error && (
                                            <div className="p-3 bg-red-50 rounded-lg flex items-center gap-3">
                                                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                                                <div>
                                                    <p className="text-sm font-medium text-red-700">Sensör Okuma Hatası</p>
                                                    <p className="text-sm text-red-600">{DBToDateText(deviceInfo.sensor_error_date)}</p>
                                                </div>
                                            </div>
                                        )}

                                        {(!deviceInfo.relay_error && !deviceInfo.sensor_error) && (
                                            <div className="p-3 bg-green-50 rounded-lg flex items-center gap-3">
                                                <div className="w-3 h-3 rounded-full bg-green-500"></div>
                                                <div>
                                                    <p className="text-sm font-medium text-green-700">Cihaz Durumu</p>
                                                    <p className="text-sm text-green-600">Kayıt Yok</p>
                                                    </div>
                            </div>
                                        )}

                                        {deviceInfo.is_statik == "1" && (
                                            <div className="p-3 bg-blue-gray-50 rounded-lg flex items-center gap-3">
                                                <div className="w-3 h-3 rounded-full bg-blue-500"></div>
                                                <div>
                                                    <p className="text-sm font-medium text-blue-gray-900">Veri Ortalama</p>
                                                    <p className="text-sm text-blue-gray-600">
                                                        {deviceInfo.dynamic_type === "none" && "Seçenek Yok"}
                                                        {deviceInfo.dynamic_type === "hour" && "Saat"}
                                                        {deviceInfo.dynamic_type === "day" && "Gün"}
                                                    </p>
                                                </div>
                                            </div>
                                        )}

                                        {deviceInfo.device_pdf_url && (
                                            <div className="p-3 bg-blue-50 rounded-lg flex items-center gap-3">
                                                <div className="w-3 h-3 rounded-full bg-blue-500"></div>
                                                <div className="flex justify-between items-center w-full">
                                                    <span className="text-sm font-medium text-blue-gray-900">Kalibrasyon Belgesi</span>
                                                    <Link 
                                                        to={deviceInfo.device_pdf_url}
                                                        target="_blank"
                                                        className="flex items-center gap-2 text-blue-500 hover:text-blue-700 transition-colors"
                                                    >
                                                        <i className="fas fa-file-pdf"></i>
                                                        <span className="text-sm">Görüntüle</span>
                                                        </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Card>

                                {/* Regression Card */}
                                <Card className="bg-white rounded-xl shadow-sm" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                    <div className="p-4 border-b border-blue-gray-50">
                                        <Typography variant="h6" className="font-bold text-blue-gray-800 flex items-center gap-2" placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                                            <i className="fas fa-calculator text-blue-500"></i>
                                            Regresyon Bilgileri
                                        </Typography>
                                    </div>
                                    <div className="p-4 space-y-3">
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm font-medium text-blue-gray-600">Regresyon Roll</span>
                                            <span className="text-sm text-blue-gray-900">
                                                {`${deviceInfo.coefficient_x} ${deviceInfo.coefficient_x_auto ? "Otomatik" : "Manuel"}`}
                                            </span>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm font-medium text-blue-gray-600">Regresyon Pitch</span>
                                            <span className="text-sm text-blue-gray-900">
                                                {`${deviceInfo.coefficient_y} ${deviceInfo.coefficient_y_auto ? "Otomatik" : "Manuel"}`}
                                            </span>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm font-medium text-blue-gray-600">Regresyon Yaw</span>
                                            <span className="text-sm text-blue-gray-900">
                                                {`${deviceInfo.coefficient_z} ${deviceInfo.coefficient_z_auto ? "Otomatik" : "Manuel"}`}
                                            </span>
                            </div>
                            </div>
                                </Card>
                            </div>

                            {/* Action Buttons */}
                            <div className="flex flex-wrap gap-3 justify-center mb-8">
                                <Button
                                    color="blue"
                                    variant="filled"
                                        onClick={() => setSettingsModalShow(true)}
                                    className="flex items-center gap-2 px-6 py-3 shadow-md hover:shadow-lg transition-all"
                                    size="sm"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-cog text-lg"></i>
                                    <span className="font-medium">Ayarlar</span>
                                </Button>

                                <Button
                                        color="red"
                                    variant="filled"
                                        onClick={() => setModalShow(true)}
                                    className="flex items-center gap-2 px-6 py-3 shadow-md hover:shadow-lg transition-all"
                                    size="sm"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-trash text-lg"></i>
                                    <span className="font-medium">Tüm Verileri Sil</span>
                                </Button>

                                <Button
                                        color="green"
                                    variant="filled"
                                    onClick={Refresh}
                                    className="flex items-center gap-2 px-6 py-3 shadow-md hover:shadow-lg transition-all"
                                    size="sm"
                                    placeholder=""
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                >
                                    <i className="fas fa-sync text-lg"></i>
                                    <span className="font-medium">Verileri Yenile</span>
                                </Button>

                                <ExcelExportNew 
                                    seriNo={deviceInfo?.device_seri_no} 
                                    data={deviceDatas} 
                                    dataList={undefined}
                                />
                            </div>

                            {/* Charts Section */}
                            {loading ? (
                                <Loading />
                            ) : deviceDatas && deviceDatas.length !== 0 ? (
                                <div className="bg-white rounded-xl shadow-sm p-6">
                                    <div className="flex flex-wrap gap-4 justify-center mb-6">
                                        {(deviceDatas[0].acc_x || deviceDatas[0].acc_y || deviceDatas[0].acc_z) && (
                                            <Button
                                                color={activeTabIndex === 0 ? "blue" : "blue-gray"}
                                                variant={activeTabIndex === 0 ? "filled" : "outlined"}
                                                onClick={() => setActiveTagIndex(0)}
                                                className={`flex items-center gap-2 px-4 py-2 ${
                                                    activeTabIndex === 0 
                                                        ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                        : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                                } transition-all duration-300`}
                                                size="sm"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                <i className="fas fa-chart-line"></i>
                                                <span>İvme Grafiği</span>
                                            </Button>
                                        )}
                                        {(deviceDatas[0].angle_x || deviceDatas[0].angle_y || deviceDatas[0].angle_z || deviceDatas[0].deflection || deviceDatas[0].temp) && (
                                            <Button
                                                color={activeTabIndex === 1 ? "blue" : "blue-gray"}
                                                variant={activeTabIndex === 1 ? "filled" : "outlined"}
                                                onClick={() => setActiveTagIndex(1)}
                                                className={`flex items-center gap-2 px-4 py-2 ${
                                                    activeTabIndex === 1 
                                                        ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                        : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                                } transition-all duration-300`}
                                                size="sm"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                <i className="fas fa-chart-line"></i>
                                                <span>Eğim Grafiği</span>
                                            </Button>
                                        )}
                                        {(deviceDatas[0].refined_angle_x || deviceDatas[0].refined_angle_y || deviceDatas[0].refined_angle_z || deviceDatas[0].refined_deflection) && (
                                            <Button
                                                color={activeTabIndex === 2 ? "blue" : "blue-gray"}
                                                variant={activeTabIndex === 2 ? "filled" : "outlined"}
                                                onClick={() => setActiveTagIndex(2)}
                                                className={`flex items-center gap-2 px-4 py-2 ${
                                                    activeTabIndex === 2 
                                                        ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                        : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                                } transition-all duration-300`}
                                                size="sm"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                <i className="fas fa-chart-line"></i>
                                                <span>İnd. Eğim Grafiği</span>
                                            </Button>
                                        )}
                                        {(deviceDatas[0].gyro_x || deviceDatas[0].gyro_y || deviceDatas[0].gyro_z) && (
                                            <Button
                                                color={activeTabIndex === 3 ? "blue" : "blue-gray"}
                                                variant={activeTabIndex === 3 ? "filled" : "outlined"}
                                                onClick={() => setActiveTagIndex(3)}
                                                className={`flex items-center gap-2 px-4 py-2 ${
                                                    activeTabIndex === 3 
                                                        ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                        : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                                } transition-all duration-300`}
                                                size="sm"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                <i className="fas fa-chart-line"></i>
                                                <span>Açısal Hız Grafiği</span>
                                            </Button>
                                        )}
                                        {(deviceDatas[0].mag_x || deviceDatas[0].mag_y || deviceDatas[0].mag_z) && (
                                            <Button
                                                color={activeTabIndex === 4 ? "blue" : "blue-gray"}
                                                variant={activeTabIndex === 4 ? "filled" : "outlined"}
                                                onClick={() => setActiveTagIndex(4)}
                                                className={`flex items-center gap-2 px-4 py-2 ${
                                                    activeTabIndex === 4 
                                                        ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                        : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                                } transition-all duration-300`}
                                                size="sm"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                <i className="fas fa-chart-line"></i>
                                                <span>Manyetometre Grafiği</span>
                                            </Button>
                                        )}
                                        {(deviceDatas[0].millis) && (
                                            <Button
                                                color={activeTabIndex === 5 ? "blue" : "blue-gray"}
                                                variant={activeTabIndex === 5 ? "filled" : "outlined"}
                                                onClick={() => setActiveTagIndex(5)}
                                                className={`flex items-center gap-2 px-4 py-2 ${
                                                    activeTabIndex === 5 
                                                        ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                        : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                                } transition-all duration-300`}
                                                size="sm"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                <i className="fas fa-chart-line"></i>
                                                <span>VGS Grafiği</span>
                                            </Button>
                                        )}
                                        {(deviceDatas[0].battery_voltage) && (
                                            <Button
                                                color={activeTabIndex === 6 ? "blue" : "blue-gray"}
                                                variant={activeTabIndex === 6 ? "filled" : "outlined"}
                                                onClick={() => setActiveTagIndex(6)}
                                                className={`flex items-center gap-2 px-4 py-2 ${
                                                    activeTabIndex === 6 
                                                        ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                        : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                                } transition-all duration-300`}
                                                size="sm"
                                                placeholder=""
                                                onPointerEnterCapture={() => {}}
                                                onPointerLeaveCapture={() => {}}
                                            >
                                                <i className="fas fa-chart-line"></i>
                                                <span>Batarya Grafiği</span>
                                            </Button>
                                        )}
                                        <Button
                                            color={activeTabIndex === 7 ? "blue" : "blue-gray"}
                                            variant={activeTabIndex === 7 ? "filled" : "outlined"}
                                            onClick={() => setActiveTagIndex(7)}
                                            className={`flex items-center gap-2 px-4 py-2 ${
                                                activeTabIndex === 7 
                                                    ? 'shadow-lg hover:shadow-xl scale-[1.02]' 
                                                    : 'hover:bg-blue-gray-50/50 hover:border-blue-gray-200'
                                            } transition-all duration-300`}
                                            size="sm"
                                            placeholder=""
                                            onPointerEnterCapture={() => {}}
                                            onPointerLeaveCapture={() => {}}
                                        >
                                            <i className="fas fa-chart-line"></i>
                                            <span>Data</span>
                                        </Button>
                                    </div>

                                    <div className="mt-6">
                                        {activeTabIndex === 0 ? (
                                    <AccChartJS data={deviceDatas} statik={deviceStatic} />
                                        ) : activeTabIndex === 1 ? (
                                        <AngleChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                        ) : activeTabIndex === 2 ? (
                                            <DefAngleChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                        ) : activeTabIndex === 3 ? (
                                                <GyroChartJS data={deviceDatas} statik={deviceStatic} />
                                        ) : activeTabIndex === 4 ? (
                                                    <MagChartJS data={deviceDatas} statik={deviceStatic} />
                                        ) : activeTabIndex === 5 ? (
                                                        <MillisChartJS data={deviceDatas} statik={deviceStatic} />
                                        ) : activeTabIndex === 6 ? (
                                                            <BatteryChartJS data={deviceDatas} height={deviceHeight} statik={deviceStatic} />
                                        ) : activeTabIndex === 7 ? (
                                                                <TableComponentAdmin
                                                                    deviceDatas={deviceDatas}
                                                                    setDeletedDataId={(id) => {
                                                                        setDataItemModalId(id);
                                                                    }}
                                                                />
                                        ) : null}
                                    </div>
                                </div>
                            ) : (
                                <div className="bg-white rounded-xl shadow-sm p-6 text-center">
                                    <Typography
                                        variant="h4"
                                        color="red"
                                        className="font-medium"
                                        placeholder=""
                                        onPointerEnterCapture={() => {}}
                                        onPointerLeaveCapture={() => {}}
                                    >
                                        Cihaza ait veri bulunmamaktadır.
                                    </Typography>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {(settingsModalShow && selectedDevice) && (
                <SettingsModal
                    selectedDevice={selectedDevice as IDevice}
                    onCancel={() => setSettingsModalShow(false)}
                    onUpdated={() => {
                        GetDevices();
                        GetAdminDeviceInfo();
                        setSettingsModalShow(false);
                    }}
                />
            )}

            {modalShow && (
                <DeleteModal
                    title="Verileri Sil"
                    description="Cihazınıza ait bütün verileri silmek istiyor musunz?"
                    onCancel={() => setModalShow(false)}
                    onOk={DeleteAdminDeviceDatas}
                />
            )}

            {dataItemModalId !== "" && (
                <DeleteModal
                    title="Veriyi Sil"
                    description="Seçmiş olduğunuz veri silinecektir."
                    onCancel={() => setDataItemModalId("")}
                    onOk={() => DeleteAdminDeviceData(dataItemModalId)}
                />
            )}
        </>
    );
};

export default DeviceDatas;
